import React, { useEffect } from 'react';
import { useState } from 'react';
import _ from 'lodash';
import { PickerPanel } from '..';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { aemPages, AnalyticsEvents, AnalyticsFieldNames, AnalyticsScreenEventNames, AnalyticsTargetEventsNames } from '../../_constants';
import { getAnalyticStatusBy, logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { imagesConstants } from '../../_constants';
import { CustomSlidingPanel } from '../CustomSlidingPanel';
import { PageSelectorCTA } from '../PageSelectorCTA';
import { CustomLoadingButton } from '../CustomLoadingButton';

export const BatteryRemindersOptions = (props) => {
    const {
        currentIsActiveLowBatteryAlert,
        currentDefaultPerc,
        currentPercSelected,
        currentAlertType,
        isSupportedVRR,
        onOpenFirmware
    } = props;

    const [isActiveLowBatteryAlert, setIsActiveLowBatteryAlert] = useState(false);
    const [alertType, setAlertType] = useState(2);

    const [isOpenLowBatteryPanel, setIsOpenLowBatteryPanel] = useState(false);
    const [isAlertSettingsOpen, setIsAlertSettingsOpen] = useState(false);

    const [percOptions] = useState({
        currentPerc: _.map([...Array(16)], (_, idx) => `${(idx + 3) * 5}%`)
    });
    const [defaultPerc, setDefaultPerc] = useState('20%');
    const [percSelected, setPercSelected] = useState({ currentPerc: '20%' });
    const [panelContentRef, setPanelContentRef] = useState(null);

    const [dictionary, setDictionary] = useState({});
    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.BATTERY_SETTINGS, {
                BATT_PREF_HEADER: propertyHeadingDefaultEmpty,
                BATT_PREF_FULL_BATTERY_ALERT_BODY: propertyTextDefaultEmpty,
                BATT_PREF_LOW_POWER_MODE: propertyTextDefaultEmpty,
                BATT_PREF_LOW_BATTERY_ALERT_BODY: propertyTextDefaultEmpty,
                BATT_PREF_CHECK_BATTERY_BODY: propertyTextDefaultEmpty,
                BATT_PREF_SAVE_BTN_CHOOSE: propertyCtaItemsDefaultEmpty,
                BATT_PREF_BATTERY_ALERT_SUBHEADER: propertyTextDefaultEmpty,
                BATT_PREF_CHECK_BATTERY_TITLE: propertyTextDefaultEmpty,
                BATT_PREF_RECHARGE_REMINDERS_TITLE: propertyTextDefaultEmpty,
                BATT_PREF_FULL_BATTERY_ALERT_TITLE: propertyTextDefaultEmpty,
                BATT_PREF_LOW_BATTERY_ALERT_TITLE: propertyTextDefaultEmpty,
                BATT_PREF_BATTERY_ALERT_HEADER: propertyHeadingDefaultEmpty,
                BATT_PREF_LOW_BATTERY_ALERT_SUBHEADER: propertyTextDefaultEmpty,
                BATT_PREF_RECHARGE_REMINDERS_BODY: propertyTextDefaultEmpty,
                BATT_PREF_LOW_BATTERY_ALERT_HEADER: propertyHeadingDefaultEmpty,
                BATT_PREF_ALERT_FALLS_UNDER: propertyTextDefaultEmpty,
                BATT_PREF_PREVIEW_ALERT: propertyTextDefaultEmpty,
                BATT_PREF_ALERT_FALLS_UNDER_PANEL: propertyTextDefaultEmpty,
                BATT_PREF_ALERT_SETTINGS_HEADER: propertyHeadingDefaultEmpty,
                BATT_PREF_CONFIGURE_ALERT_SELECTOR: propertyTextDefaultEmpty,
                BATT_PREF_SOUND_HAPTICS: propertyTextDefaultEmpty,
                BATT_PREF_SOUND: propertyTextDefaultEmpty,
                BATT_PREF_HAPTICS: propertyTextDefaultEmpty,
                BATT_PREF_SAVE_SETTINGS_BTN: propertyCtaItemsDefaultEmpty
            })
        );
    }, [])

    useEffect(() => { setIsActiveLowBatteryAlert(currentIsActiveLowBatteryAlert) }, [currentIsActiveLowBatteryAlert]);
    useEffect(() => { setDefaultPerc(currentDefaultPerc) }, [currentDefaultPerc]);
    useEffect(() => { setPercSelected(currentPercSelected) }, [currentPercSelected]);
    useEffect(() => {setAlertType(currentAlertType)}, [currentAlertType]);

    useEffect(() => {
        !isSupportedVRR && onOpenFirmware();
    }, [isSupportedVRR]);

    const toggleLowBatteryAlert = (...args) => {
        if (!isSupportedVRR){
            onOpenFirmware()
            return;
        }
        if (!props.disabled) {
            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.LOW_BATTERY_ALERT, field_value: getAnalyticStatusBy(!isActiveLowBatteryAlert) === 'enabled' ? true : false});
            props.toggleLowBatteryAlert(...args);
        }
    }

    const saveNewDefaultPercentage = (...args) => {
        if (!props.disabled) {
            props.saveNewDefaultPercentage(...args);
        }
    }

    const saveCurrentPercSeleted = () => {
        if (!props.disabled) {
            setIsOpenLowBatteryPanel(false);

            if (typeof saveNewDefaultPercentage === "function") {
                logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.SAVE});
                saveNewDefaultPercentage(percSelected.currentPerc);
                logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.RECHARGE_REMINDERS})
            }
        }
    }

    const saveAlertSettings = () => {
        if (!props.disabled) {
            toggleAlertSettingsPanel();
            if (typeof saveNewDefaultPercentage === "function") {
                saveNewDefaultPercentage(percSelected.currentPerc, alertType);
            }
        }
    }

    const toggleAlertSettingsPanel = () => {
        setIsAlertSettingsOpen(!isAlertSettingsOpen);
    }

    const lowBatteryAlertDemo = () => {
        props.lowBatteryAlertDemo(alertType);
    }

    return (
        <React.Fragment>
            <div className={`battery-settings-box ${props.disabled ? 'disabled' : ''}`}>
                <div className="d-flex align-items-center">
                    <div className="battery-settings-box-title">
                        {dictionary.BATT_PREF_LOW_BATTERY_ALERT_TITLE}
                    </div>
                    <div className="form-check form-switch ms-auto">
                        <input className="form-check-input" type="checkbox" id="low-battery-alert-toggle"
                            checked={!!isActiveLowBatteryAlert}
                            value={!!isActiveLowBatteryAlert}
                            disabled={props.disabled}
                            onChange={() => toggleLowBatteryAlert(!isActiveLowBatteryAlert)} />
                        <label className="form-check-label" htmlFor="low-battery-alert-toggle"></label>
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-8">
                        <div className="battery-settings-box-text">
                            {props.canDeviceSupportLowBatteryAlert()
                                ? dictionary.BATT_PREF_ALERT_FALLS_UNDER 
                                : dictionary.BATT_PREF_LOW_BATTERY_ALERT_BODY
                            }
                        </div>
                    </div>
                    <div className="col-4 text-end">
                        <button className="btn btn-outline-secondary"
                            onClick={() => {logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW,{screen_name: AnalyticsScreenEventNames.LOW_BATTERY_PICKER}); setIsOpenLowBatteryPanel(true)}}
                            disabled={!isActiveLowBatteryAlert || props.disabled}
                        >{defaultPerc}</button>
                    </div>
                    {isActiveLowBatteryAlert && props.canDeviceSupportLowBatteryAlert() &&
                        <div className="low-battery-alert-preview">
                            <div
                                className="preview-symbol"
                                onClick={lowBatteryAlertDemo}
                            ></div>
                            <span className="preview-title">{dictionary.BATT_PREF_PREVIEW_ALERT}</span>
                            <img
                                onClick={toggleAlertSettingsPanel}
                                className="preview-settings"
                                src={imagesConstants.RECHARGE_REMINDERS_SETTINGS}
                            />
                            <div style={{clear: "both"}}></div>
                        </div>
                    }
                </div>
            </div>

            <PickerPanel
                isOpenPanel={isOpenLowBatteryPanel}
                panelTitle={dictionary.BATT_PREF_LOW_BATTERY_ALERT_HEADER}
                panelDescription={
                    props.canDeviceSupportLowBatteryAlert()
                        ? dictionary.BATT_PREF_ALERT_FALLS_UNDER_PANEL 
                        : dictionary.BATT_PREF_LOW_BATTERY_ALERT_SUBHEADER}
                onPanelDismiss={() => {
                    setIsOpenLowBatteryPanel(false);
                    setPercSelected({ currentPerc: defaultPerc });
                }}
                pickerOptions={percOptions}
                pickerSelected={percSelected}
                onPickerSelection={(label, value) => {
                    setPercSelected({ [label]: value });
                    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.LOW_BATTERY_ALERT_THRESHOLD, field_value: value} );
                }}
                buttonLabel={dictionary.BATT_PREF_SAVE_BTN_CHOOSE_0_ctaLabel}
                onPanelClose={() => saveCurrentPercSeleted()}
            />

        <CustomSlidingPanel
            from="bottom"
            isOpen={isAlertSettingsOpen}
            overlayClassName={"sliding-common-wrapper priority"}
            className="onboarding-panel mid-height-panel form-panel"
            title={dictionary.BATT_PREF_ALERT_SETTINGS_HEADER}
            onClose={()=>{}}
            setContentRef={(ref) => setPanelContentRef(ref)}
            additionalStyles={{ 
                marginTop: `calc(100vh - (${panelContentRef?.clientHeight ?? 0}px))`
            }}
        >
            <div className="h-100 d-flex flex-column">

                <div className="sliding-panel-mid" style={{paddingTop: "54px", paddingBottom: "45px"}}>
                    <div className="sliding-panel-mid-description">
                        {dictionary.BATT_PREF_CONFIGURE_ALERT_SELECTOR} 
                    </div>
                </div>

                <div className="low-battery-alert-radio">
                    <input
                        type="radio"
                        value={alertType === 3}
                        checked={alertType === 3}
                        onChange={() => setAlertType(3)}
                    />
                    <label>{dictionary.BATT_PREF_SOUND_HAPTICS}</label>
                </div>

                <div className="low-battery-alert-radio">
                    <input
                        type="radio"
                        value={alertType === 1}
                        checked={alertType === 1}
                        onChange={() => setAlertType(1)}
                    />
                    <label>{dictionary.BATT_PREF_SOUND}</label>
                </div>

                <div className="low-battery-alert-radio">
                    <input
                        type="radio"
                        value={alertType === 2}
                        checked={alertType === 2}
                        onChange={() => setAlertType(2)}
                    />
                    <label>{dictionary.BATT_PREF_HAPTICS}</label>
                </div>

                <PageSelectorCTA className="mt-auto" style={{position: "relative"}}>
                    <div className="d-grid">
                        <CustomLoadingButton
                            isLoading={false}
                            validationMethod={null}
                            buttonLabel={dictionary.BATT_PREF_SAVE_SETTINGS_BTN_0_ctaLabel} 
                            onClickMethod={saveAlertSettings}
                        />
                    </div>
                </PageSelectorCTA>
            </div>

        </CustomSlidingPanel>

        </React.Fragment>
    )

}