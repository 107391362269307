import isNil from "lodash/isNil";
import { getCustomerId, getRxDb15Migrated, setRxDb15Migrated } from "../../_actions/appData.actions";

import { addRxPlugin, createRxDatabase, isRxDatabase } from "rxdb";
//import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import { RxDBMigrationPlugin } from "rxdb/plugins/migration-schema";
import { RxDBUpdatePlugin } from "rxdb/plugins/update";
import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder";
import { migrateStorage } from 'rxdb/plugins/migration-storage';
import { RxDBJsonDumpPlugin } from "rxdb/plugins/json-dump";
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";

import { createRxDatabase as createRxDatabaseOld, isRxDatabase as isRxDatabaseOld, addRxPlugin as addRxPluginOld } from "rxdb-old";
import { getRxStorageDexie as getRxStorageDexieOld } from "rxdb-old/plugins/storage-dexie";
import { RxDBMigrationPlugin as RxDBMigrationPluginOld } from "rxdb-old/plugins/migration";
//import { RxDBDevModePlugin as RxDBDevModePluginOld } from "rxdb-old/plugins/dev-mode";

addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBJsonDumpPlugin);
//addRxPlugin(RxDBDevModePlugin);

export const DailyCollectionName = "daily";
export const StatsCollectionName = "stats";
export const RawCollectionName = "rawpuffs";
export const HourlyCollectionName = "hourly";
export const PodsCollectionName = "pods";

let dbInstance = null;

const dailyCollection = {
  schema: {
    title: "daily",
    version: 1,
    description: "describes daily stats",
    primaryKey: {
      // where should the composed string be stored
      key: "id",
      // fields that will be used to create the composed key
      fields: ["day", "month", "year", "serial"],
      // separator which is used to concat the fields values.
      separator: "|",
    },
    type: "object",
    properties: {
      id: {
        type: "string",
        maxLength: 100,
      },
      day: {
        type: "number",
      },
      dayOfWeek: {
        type: "number",
      },
      month: {
        type: "number",
      },
      year: {
        type: "number",
      },
      serial: {
        type: "string",
      },
      low: {
        type: "number",
      },
      med: {
        type: "number",
      },
      high: {
        type: "number",
      },
      total: {
        type: "number",
      },
      sessions: {
        type: "number",
      },
      lastUpdate: {
        type: "number",
      },
      lastPuffTimestamp: {
        type: "number",
      },
    },
    required: ["day", "serial"],
  },
  migrationStrategies: {
    1: function (oldDoc){
      
      delete oldDoc._rev
      console.log('migrate daily', oldDoc)
      return oldDoc
    }
  }
};

const statsCollection = {
  schema: {
    title: "stats",
    version: 1,
    description: "describes stats",
    primaryKey: "serial",
    type: "object",
    properties: {
      serial: {
        type: "string",
        maxLength: 30,
      },
      dailyAvg: {
        type: "number",
      },
      dailySessAvg: {
        type: "number",
      },
      lastUpdated: {
        type: "string",
      },
    },
    required: ["serial"],
  },
  migrationStrategies: {
    1: function (oldDoc){
      delete oldDoc._rev
      return oldDoc
    }
  }
};

const rawCollection = {
  schema: {
    title: "rawpuffs",
    version: 2,
    description: "raw data",
    primaryKey: "puffId",
    type: "object",
    properties: {
      puffId: {
        type: "string",
        maxLength: 30,
      },
      userId: {
        type: "string",
      },
      deviceId: {
        type: "string",
      },
      deviceFamilyName: {
        type: "string",
      },
      firmwareVersion: {
        type: "string",
      },
      timestamp: {
        type: "string",
      },
      duration: {
        type: "string",
      },
      powerLevel: {
        type: "number",
      },
      ageRange: {
        type: "string",
      },
      gender: {
        type: "string",
      },
      billingCountry: {
        type: "string",
      },
      board: {
        type: "number",
      },

      uid: {
        type: "string"
      },
      manufactureDate: {
        type: "string"
      },
      assembly: {
        type: "string"
      },
      sku: {
        type: "string"
      },
      flavorName: {
        type: "string"
      },
      podApiVersion: {
        type: "number"
      },
      capacity: {
        type: "number"
      },
      nicotine: {
        type: "number"
      },
      preferredHeatingPower: {
        type: "number"
      },
      remainingCapacity: {
        type: "number"
      },
      puffCount: {
        type: "number"
      }
    },
    required: ["puffId", "timestamp"],
  },
  migrationStrategies: {
    1: function (oldDoc){
      delete oldDoc._rev
      return oldDoc
    },
    2: function (oldDoc){
      return oldDoc
    }
  }
};

const hourlyCollection = {
  schema: {
    "title": "hourly",
    "version": 2,
    "description": "describes hourly stats",
    "primaryKey": {
        // where should the composed string be stored
        key: 'id',
        // fields that will be used to create the composed key
        fields: [
            'hour',
            'day',
            'month',
            'year',
            'serial'
        ],
        // separator which is used to concat the fields values.
        separator: '|'
    },
    "type": "object",
    "properties": {
        "id": {
            "type": "string",
            "maxLength": 100
        },
        "hour": {
            "type": "number"
        },
        "day": {
            "type": "number"
        },
        "month": {
            "type": "number"
        },
        "year": {
            "type": "number"
        },
        "serial": {
            "type": "string"
        },
        "low": {
            "type": "number"
        },
        "med": {
            "type": "number"
        },
        "high": {
            "type": "number"
        },
        "total": {
            "type": "number"
        },
        "sessions": {
            "type": "number"
        },
        "lastUpdate": {
            "type": "number"//needed?
        },
        "lastPuffTimestamp": {
            "type": "number" //needed?
        },
        averagePowerLevel: {
            type: "number"
        }
    },
    "required": [
        "day",
        "serial",
        "hour"
    ]
  },
  migrationStrategies: {
    1: function (oldDoc){
      return oldDoc
    },
    2: function (oldDoc){
      delete oldDoc._rev
      return oldDoc
    }
  }
}

const podsCollection = {
  schema: {
    "title": "pods",
    "version": 1,
    "description": "describes pods stats",
    "primaryKey": {
        // where should the composed string be stored
        key: 'id',
        // fields that will be used to create the composed key
        fields: [
          'month',
          'year',
          'uid'
        ],
        // separator which is used to concat the fields values.
        separator: '|'
    },
    "type": "object",
    "properties": {
      "id": {
        "type": "string",
        "maxLength": 100
      },
      "month": {
        "type": "number"
      },
      "year": {
        "type": "number"
      },
      "uid": {
        "type": "string" // uid smartpod
      },
      "flavourId": {
        "type": "string" // id from aem
      },
      "sku": {
        "type": "string" // additional information
      },
      "nicotine": {
        "type": "number"
      },
      "cretedAt": {
        "type": "string" //timestamp first puffs
      },
      "low": {
        "type": "number"
      },
      "med": {
        "type": "number"
      },
      "high": {
        "type": "number"
      },
      "lowPower": {
        "type": "number"
      },
      "capacity": {
        "type": "number" // additional information
      },
      "reminingCapacity": {
        "type": "number" // additional information
      },
      "updatedAt": {
        "type": "string" //timestamp last puff
      },
    },
    "required": [
      "uid",
      "month",
      "year"
    ]
  },
  migrationStrategies: {
    1: function (oldDoc){
      delete oldDoc._rev
      return oldDoc
    }
  }
}

const collections = {
  [DailyCollectionName]: dailyCollection,
  [StatsCollectionName]: statsCollection,
  [RawCollectionName]: rawCollection,
  [HourlyCollectionName]: hourlyCollection,
  [PodsCollectionName]: podsCollection
};

export const addCollection = async (db, collectionName) => {
  try {
    console.log("Adding  collection...");
    console.log(isRxDatabase(db));
    await db.addCollections({
      [collectionName]: collections[collectionName],
    });

    console.log("Collection added!");
  } catch (err) {
    console.log("ERROR CREATING COLLECTION", err);
  }
};

const addCollections = async (db) => {
  try {
    console.log("Adding  collection...");
    console.log(isRxDatabase(db));
    await db.addCollections(collections);
    console.log("Collection added!");
  } catch (err) {
    console.log("ERROR CREATING COLLECTION", err);
  }
};

export const getDbInstance = () => {
  return new Promise((resolve, reject) => {
    if (dbInstance != null) {
      resolve(dbInstance);
    } else {
      setTimeout(() => {
        resolve(getDbInstance());
      }, 100);
    }
  });
};

export const removeDbInstance = () => {
  dbInstance = null;
};

const initialize = async () => {
  const userId = getCustomerId();
  const dbNameOld = `/myvusepuffrecords_${userId}`;
  const dbName = `/myvusepuffrecords_15_${userId}`;

  console.debug("UT_INITIALIZE_DB");
  if (!userId) {
    console.debug("UT_INITIALIZE_DB_USER_ID_NIL");
    // If userId is not available, it means that the user is not logged to the app
    // In that case we don't have to initialize the db
    return;
  }

  if (isNil(dbInstance)) {
    console.log("Initializing database...");

    const db = await createRxDatabase({
      name: dbName,
      storage: getRxStorageDexie(),
      multiInstance: false,
      eventReduce: true,
    });

    console.log("Database initialized!");

    console.log("*****************");
    console.log(db);

    if (!isNil(db)) {
      console.log("Is db an RXDB? ", isRxDatabase(db));

      if (
        !db[DailyCollectionName] &&
        !db[StatsCollectionName] &&
        !db[HourlyCollectionName] &&
        !db[PodsCollectionName]
      ) {
        console.log("Collections do not exist yet");
        await addCollections(db);

        if (!getRxDb15Migrated()) {
          console.log('OLD RXDB DETECTED')

          //init old rxdb and execute the migrations
          addRxPluginOld(RxDBMigrationPluginOld)
          //addRxPluginOld(RxDBDevModePluginOld)
          const db_old = await createRxDatabaseOld({
            name: dbNameOld,
            storage: getRxStorageDexieOld(),
            multiInstance: false,
            eventReduce: true,
          });
          if (isRxDatabaseOld(db_old)) {
            await addCollections(db_old);
          }

          //migrate all the data to the new version of rxdb
          await migrateStorage({
            database: db,
            oldDatabaseName: dbNameOld,
            oldStorage: getRxStorageDexieOld(),
            batchSize: 500,
            parallel: false,
            afterMigrateBatch: () => {
              console.log('storage migration: batch processed');
            },
            logFunction: (message) => {
              console.log('storage log:', message)
            }
          });

          db_old.remove()

          setRxDb15Migrated(true)
          console.log('OLD RXDB REMOVED')
        }
        
        dbInstance = db;
      } else {
        console.log("collections already exist");
      }
    }
  }
};

export default initialize;