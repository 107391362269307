import { deviceConstants, thingDeviceProperties, VUSE_DEVICE } from '../_constants';
import { commonsServices } from '../_services';
import { FAILURE, REQUEST, SUCCESS } from '../_reducers/action-type.util';
import { debug } from '../_helpers/debug';

export const deviceActions = {
    //FE
    setDeviceProfile,
    setDevices,
    addOrUpdateDevice,
    setSelected,
    removeSelected,
    resetDevices,
    removeDevice,
    //BE
    setDeviceLogError,
    setDeviceCloud,
    setDeviceLock,
    setDeviceFlash,
    setDeviceBattery,

    setLedBrightness,
    setScreenBrightness,
    setHaptic,
    setVolume
};

function setDeviceProfile(profile, label) {
  return {
    type: deviceConstants.SET_DEVICE_PROFILE,
    payload: {
      profile,
      label
    },
  };
}

function setDevices(devices, callBack) {
    return (dispatch, getState) => {
        dispatch({  
            type: deviceConstants.SET_DEVICES,
            payload: devices
        });

        if (typeof callBack === 'function') {
            callBack();
        }
    }
}

function resetDevices() {
  return { type: deviceConstants.RESET_DEVICES, payload: null };
}

function removeDevice(sn) {
  return { type: deviceConstants.REMOVE_DEVICE, payload: sn };
}

/**
 *
 * @param {DeviceModel} payload
 * @param {Boolean} first add on top of array
 * @param {fn} callBack
 * @returns
 */
function addOrUpdateDevice(payload, first = false, callBack) {
    debug(`[addOrUpdateDevice] updating redux: ${JSON.stringify(payload)}`);
    return (dispatch, getState) => {
        dispatch({ type: first ? deviceConstants.ADD_UPDATE_DEVICE_FIRST : deviceConstants.ADD_UPDATE_DEVICE, payload });

    if (typeof callBack === "function") {
      callBack();
    }
  };
}

function setSelected(payload, callBack) {
  return (dispatch, getState) => {
    dispatch({ type: deviceConstants.SET_SELECTED, payload });

    if (typeof callBack === "function") {
      callBack();
    }
  };
}

function removeSelected(payload, callBack) {
    return (dispatch, getState) => {
        dispatch({ type: deviceConstants.REMOVE_SELECTED, payload });

        if (typeof callBack === 'function') {
            callBack();
        }
    }
}


/************************ BE *******************************/
function setDeviceLogError(tenantUserId, data, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .setDeviceLogError(tenantUserId, data)
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(deviceConstants.SET_ERROR_LOG) };
  }
  function success(payload) {
    return { type: SUCCESS(deviceConstants.SET_ERROR_LOG), payload };
  }
  function failure(error) {
    return { type: FAILURE(deviceConstants.SET_ERROR_LOG), error };
  }
}

function setDeviceCloud(tenantUserId, uuid, data, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .setThing(
        tenantUserId,
        uuid,
        VUSE_DEVICE,
        thingDeviceProperties.SET_CLOUD,
        data
      )
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(deviceConstants.SET_CLOUD) };
  }
  function success(payload) {
    return { type: SUCCESS(deviceConstants.SET_CLOUD), payload };
  }
  function failure(error) {
    return { type: FAILURE(deviceConstants.SET_CLOUD), error };
  }
}

function setDeviceLock(tenantUserId, uuid, data, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .setThing(
        tenantUserId,
        uuid,
        VUSE_DEVICE,
        thingDeviceProperties.LOCK_DEVICE,
        data
      )
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(deviceConstants.SET_LOCK) };
  }
  function success(payload) {
    return { type: SUCCESS(deviceConstants.SET_LOCK), payload };
  }
  function failure(error) {
    return { type: FAILURE(deviceConstants.SET_LOCK), error };
  }
}

function setDeviceFlash(tenantUserId, uuid, data, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .setThing(
        tenantUserId,
        uuid,
        VUSE_DEVICE,
        thingDeviceProperties.FLASH_DEVICE,
        data
      )
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(deviceConstants.SET_FLASH) };
  }
  function success(payload) {
    return { type: SUCCESS(deviceConstants.SET_FLASH), payload };
  }
  function failure(error) {
    return { type: FAILURE(deviceConstants.SET_FLASH), error };
  }
}

function setDeviceBattery(tenantUserId, uuid, data, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .setThing(
        tenantUserId,
        uuid,
        VUSE_DEVICE,
        thingDeviceProperties.BATTERY_PERCENTAGE,
        data
      )
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(deviceConstants.SET_BATTERY) };
  }
  function success(payload) {
    return { type: SUCCESS(deviceConstants.SET_BATTERY), payload };
  }
  function failure(error) {
    return { type: FAILURE(deviceConstants.SET_BATTERY), error };
  }
}

function setLedBrightness(tenantUserId, uuid, data, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .setThing(
        tenantUserId,
        uuid,
        VUSE_DEVICE,
        thingDeviceProperties.LED_BRIGHTNESS,
        data
      )
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(deviceConstants.SET_LED) };
  }
  function success(payload) {
    return { type: SUCCESS(deviceConstants.SET_LED), payload };
  }
  function failure(error) {
    return { type: FAILURE(deviceConstants.SET_LED), error };
  }
}

function setScreenBrightness(tenantUserId, uuid, data, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .setThing(
        tenantUserId,
        uuid,
        VUSE_DEVICE,
        thingDeviceProperties.SCREEN_BRIGHTNESS,
        data
      )
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(deviceConstants.SET_SCREEN_BRIGHTNESS) };
  }
  function success(payload) {
    return { type: SUCCESS(deviceConstants.SET_SCREEN_BRIGHTNESS), payload };
  }
  function failure(error) {
    return { type: FAILURE(deviceConstants.SET_SCREEN_BRIGHTNESS), error };
  }
}

function setHaptic(tenantUserId, uuid, data, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .setThing(
        tenantUserId,
        uuid,
        VUSE_DEVICE,
        thingDeviceProperties.HAPTIC_INTENSITY,
        data
      )
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(deviceConstants.SET_HAPTIC_INTENSITY) };
  }
  function success(payload) {
    return { type: SUCCESS(deviceConstants.SET_HAPTIC_INTENSITY), payload };
  }
  function failure(error) {
    return { type: FAILURE(deviceConstants.SET_HAPTIC_INTENSITY), error };
  }
}

function setVolume(tenantUserId, uuid, data, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .setThing(
        tenantUserId,
        uuid,
        VUSE_DEVICE,
        thingDeviceProperties.SOUND_VOLUME,
        data
      )
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(deviceConstants.SET_SOUND_VOLUME) };
  }
  function success(payload) {
    return { type: SUCCESS(deviceConstants.SET_SOUND_VOLUME), payload };
  }
  function failure(error) {
    return { type: FAILURE(deviceConstants.SET_SOUND_VOLUME), error };
  }
}