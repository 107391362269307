import React, { useEffect, useState } from "react";
import { aemPages, HARD_AGE_VERIFICATION_STEPS } from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { PageSelectorCTA } from "../PageSelectorCTA";
import { Header } from "../Header";
import { Utils } from "../../_helpers/graphic/utils";
import { getLegalAge } from "../../_actions/appConfig.actions";
import { ContactPanel } from "../ContactPanel";
import { Tenants } from "../../_helpers/tenants";

export function HardAgeVerificationFaceFake({ goToStep }) {
  const [dictionary, setDictionary] = useState({});
  const [isContactPanelOpen, setIsContactPanelOpen] = useState(false);

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.HARD_AGE_VERIFICATION, {
        YOTI_SOMETHING_WRONG: propertyTextDefaultEmpty,
        YOTI_LEGAL_AGE_CONTENT: propertyTextDefaultEmpty,
        YOTI_AV_FIRST_INFO: propertyTextDefaultEmpty,
        YOTI_BTN_GET_STARTED: propertyCtaItemsDefaultEmpty,
        YOTI_AV_FIRST_ERROR_STORED_ID_INFO: propertyTextDefaultEmpty,
        YOTI_CONSUMER_CARE_TEAM: propertyTextDefaultEmptyParsed
      })
    );
  }, []);

  return (
    <div className="page w-100" style={{background: 'none'}}>
      { Tenants.isGlobal() ? <Header /> : null }
      <div className="page-wrapper d-flex flex-column align-items-center h-100 overflow-auto">
        <div className="d-flex flex-column justify-content-between flex-grow-1">
          <div>
            <div className="hard-age-verification-title-ca text-center">
              <h1>
              {dictionary.YOTI_SOMETHING_WRONG}
              </h1>
              <span className="bat-icon-alert-triangle-outline alert-icon" />
            </div>
            <div className="hard-age-verification-failure-desc">
              <div>{dictionary.YOTI_LEGAL_AGE_CONTENT}</div>
            </div>
          </div>
          {/*<div className="hard-age-verification-info w-100">
            <ul>
              <li>
                <span className="bat-icon-alert-circle alert-icon" />
                {dictionary.YOTI_AV_FIRST_INFO}
              </li>
            </ul>
          </div>*/}
          <div className="hard-age-verification-info-bottom-ca">
            <div>
              {dictionary.YOTI_AV_FIRST_ERROR_STORED_ID_INFO}
            </div>
            <div onClick={() => setIsContactPanelOpen(true)}>
              {dictionary.YOTI_CONSUMER_CARE_TEAM}
            </div>
          </div>
        </div>
      </div>
      <PageSelectorCTA>
        <div className="d-grid">
          <button
            className="btn btn-primary text-uppercase"
            onClick={() => {
              goToStep(HARD_AGE_VERIFICATION_STEPS.DOC_SCAN);
            }}
          >
            {dictionary.YOTI_BTN_GET_STARTED_0_ctaLabel}
          </button>
        </div>
      </PageSelectorCTA>

      <ContactPanel isOpen={isContactPanelOpen} onClose={() => setIsContactPanelOpen(!isContactPanelOpen)} />
    </div>
  );
}
