import cx from "classnames";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deviceActions } from "../../_actions";
import { Header, OnboardingHeader, PageSelectorCTA } from "../../_components";
import {
  aemPages,
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  imagesConstants,
  routingConstants,
  SKIN_COLOR_REFLEKTA,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import {
  logAnalyticsEvent,
  logAnalyticsEventByCustomEvent,
} from "../../_helpers/analytics/logAnalytics";
import { getDeviceName, isDeviceConnected, handleDeviceSelection } from "../../_helpers/device";
import {
  getDeviceIconImg,
  getManageDevicesItemImg,
} from "../../_helpers/device/assets";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { Tenants } from "../../_helpers/tenants";
import { Commons } from "../../_helpers/commons";
import { isNil } from "lodash";
import { getSupportedDevices } from "../../_actions/appConfig.actions";

function ManageDevices({ history, devices, goToSettings }) {
  const [dictionary, setDictionary] = useState({});
  const [availableDevices] = useState(getSupportedDevices());

  useEffect(() => {
    const aem = new AEMHelper();

    logAnalyticsEventByCustomEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.MANAGE_DEVICES,
    });

    setDictionary(
      aem.getDictionary(aemPages.MANAGE_DEVICES, {
        MANAGE_DEVICES_TITLE: propertyHeadingDefaultEmpty,
        MANAGE_DEVICES_CONNECTED_LABEL: propertyTextDefaultEmpty,
        MANAGE_DEVICES_DISCONNECTED_LABEL: propertyTextDefaultEmpty,
        MANAGE_DEVICES_BTN_PAIR_NEW_DEVICE: propertyCtaItemsDefaultEmpty,
        MANAGE_DEVICES_UPDATE_LABEL: propertyTextDefaultEmpty,
        MANAGE_DEVICES_ALERT_TITLE: propertyTextDefaultEmpty,
        MANAGE_DEVICES_ALERT_BODY: propertyTextDefaultEmpty,
        MANAGE_DEVICES_MY_DEVICES: propertyTextDefaultEmpty
      })
    );
  }, []);

  const handleBack = () => {
    const isMoreMenuOpen = history?.location?.state?.isMoreMenuOpen;

    Commons.goToDashboard({
      ...(!isNil(isMoreMenuOpen) && { isMoreMenuOpen }),
    });
  };

  const canPair = () => {
    return devices.length < Tenants.getMaxDevicesLimit();
  };

  const goToDeviceSelection = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ADD_DEVICE})

    if (availableDevices.length === 1){
      handleDeviceSelection(availableDevices[0].profile, availableDevices[0].label);
      history.push(buildURI(routingConstants.PAIR_DEVICE));
    } else {
      history.push(buildURI(routingConstants.SELECT_DEVICE), {
        type: "slide",
        backTo: routingConstants.MANAGE_DEVICES,
      });
    }
  };

  const pairDevice = () => {
    history.push({
      pathname: buildURI(routingConstants.PAIR_DEVICE),
    });
  };

  return (
    <div className="page w-100 manage-device-page">
      <Header
        rightButton={{
          icon: <span className="bat-icon-close" />,
          onClick: handleBack,
        }}
      >
        {dictionary.MANAGE_DEVICES_TITLE}
      </Header>
      <div className="page-wrapper h-100">
        <div className="manage-devices-wrapper">
          <span className="manage-devices-title">{dictionary.MANAGE_DEVICES_MY_DEVICES}</span>
          {!canPair() && (
            <div
              className="alert d-flex align-items-start alert-max-devices"
              role="alert"
            >
              <span className="bat-icon-alert-circle alert-icon" />
              <div>
                <h4 className="alert-heading mb-0">
                  {dictionary.MANAGE_DEVICES_ALERT_TITLE}
                </h4>
                <p className="mb-0">{dictionary.MANAGE_DEVICES_ALERT_BODY}</p>
              </div>
            </div>
          )}
          <ul className="list-group devices-list-group">
            {devices.map((device, i) => {
              const isConnected = isDeviceConnected(device);
              const deviceColor = device.deviceColor;
              const deviceIcon = getDeviceIconImg(device);

              const bg = [
                ...(deviceIcon ? [`url(${deviceIcon})`] : []),
                `url(${getManageDevicesItemImg(device)})`,
              ];

              return (
                <li
                  key={i}
                  className={cx(
                    "list-group-item d-flex align-items-center justify-content-between device-item",
                    {
                      "dark-market": Tenants.isCanadaDark(),
                      "light-market":
                        Tenants.isCanadaLight() || Tenants.isDemo(),
                      global: Tenants.isGlobal(),
                    }
                  )}
                  style={{
                    backgroundImage: bg.join(", ")
                  }}
                  onClick={() => {
                    localStorage.setItem('analytics_device_index', i)
                    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DEVICE})
                    goToSettings(device);
                  }}
                >
                  <div className="d-flex flex-column">
                    <div className="device-name">{getDeviceName(device)}</div>
                    <div className="d-flex align-items-center position-relative" style={{width: "min-content"}}>
                      <span
                        className={cx("device-badge device-status", {
                          "device-status-connected": isConnected,
                          "device-status-disconnected": !isConnected,
                        })}
                      >
                        {isConnected
                          ? dictionary.MANAGE_DEVICES_CONNECTED_LABEL
                          : dictionary.MANAGE_DEVICES_DISCONNECTED_LABEL}
                      </span>
                      {device.updatable && (
                        <span className="device-badge device-update">
                          {dictionary.MANAGE_DEVICES_UPDATE_LABEL}
                        </span>
                      )}
                    </div>
                  </div>
                  <span className="bat-icon-chevron-right" />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <PageSelectorCTA>
        <div className="d-grid">
          <button
            className="btn btn-primary text-uppercase"
            disabled={!canPair()}
            onClick={goToDeviceSelection}
          >
            {dictionary.MANAGE_DEVICES_BTN_PAIR_NEW_DEVICE_0_ctaLabel}
          </button>
        </div>
      </PageSelectorCTA>
      <div className="page-linear-gradient-bg" />
    </div>
  );
}

const mapStateToProps = (state) => ({
  devices: state?.deviceReducer?.devices,
});

const mapDispatchToProps = (dispatch) => ({
  goToSettings: (device) =>
    dispatch(
      deviceActions.setSelected(device, () => {
        history.push(buildURI(routingConstants.DEVICE_SETTINGS), {backTo: routingConstants.MANAGE_DEVICES});
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageDevices);
