import React from "react";
import cx from "classnames";

const HAPTIC_LOW = 25
const HAPTIC_MEDIUM = 50
const HAPTIC_HIGH = 100

export const DeviceHaptic = ({ setHaptic, haptic, title, labels = {}, disabled }) => {
    const setValue = (value) => {
        if (!disabled) {
            setHaptic(value)
        }
    };

    return (
        <div className="device-settings-cta">
            <label className="page-label">{title}</label>
            <div className="button-haptic-container">
                <div className={cx("button-haptic", {"active": haptic === HAPTIC_LOW})} onClick={() => setValue(HAPTIC_LOW)}>
                    <div className="container-bat-icon-haptic">
                        <span className="bat-icon-haptic-high bg"></span>
                        <span className="bat-icon-haptic-low"></span>
                    </div>
                    <div className="title">{labels.low}</div>
                </div>
                <div className={cx("button-haptic", {"active": haptic === HAPTIC_MEDIUM})} onClick={() => setValue(HAPTIC_MEDIUM)}>
                    <div className="container-bat-icon-haptic">
                        <span className="bat-icon-haptic-high bg"></span>
                        <span className="bat-icon-haptic-medium"></span>
                    </div>
                    <div className="title">{labels.medium}</div>
                </div>
                <div className={cx("button-haptic", {"active": haptic === HAPTIC_HIGH})} onClick={() => setValue(HAPTIC_HIGH)}>
                    <div className="container-bat-icon-haptic">
                        <span className="bat-icon-haptic-high bg"></span>
                        <span className="bat-icon-haptic-high"></span>
                    </div>
                    <div className="title">{labels.high}</div>
                </div>
            </div>
        </div>
    );

};
