import React from "react";
import { connect } from "react-redux";
import StepWizard from "react-step-wizard";
import {
  getDeviceFamilyInfo,
} from "../../_actions/appConfig.actions";
import {
  CustomSlidingPanel,
  DeviceActivation,
  DevicePairing,
  DeviceUnlock,
  PairingFailure,
  SearchingFailure,
  DeviceAsav,
  AsavFailure,
  AsavLoader,
  Header,
  PageSelectorCTA,
  SpinnerModal,
} from "../../_components";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  imagesConstants,
  routingConstants,
  aemPages
} from "../../_constants";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { PairingLoader } from "../../_components/Pairing";
import { deviceActions, userActions } from "../../_actions";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import {
  renderAllSteps,
  triggerLandingOn,
  getActiveStep,
} from "../../_helpers/multipleStepWiew";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyCtaItemsDefaultEmptyParsed,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { PAIRING_STEPS } from "../../_helpers/device/pairing";
import BrowserHelper from "../../_helpers/browser/browser.helper";
import { getTutorialsVideo } from "../../_helpers/tutorials/tutorials.helper";
import {
  isAdvertisingNameSupported
} from "../../_helpers/device";
import { FirmwareUpdate } from "../../_components/FirmwareUpdate/FirmwareUpdate";

class Pairing extends React.Component {
  constructor(props) {
    super(props);

    const activeStep = getActiveStep();

    this.state = {
      wizardInstance: null,
      stepsStack: [],
      tutorials: [],
      activeVideoIndex: 0,
      isVideoActive: true,
      needActivation: false,
      activeStep: activeStep === -1 ? 1 : activeStep,
      previousStep: activeStep === -1 ? 1 : activeStep,
      isVideoEnded: false,
      isASAVFlow: true,
      needASAV: false,
      isASAVCompleted: false,
      isASAVLoaderOpen: false,
      isAdvNameSupported: null,
      resumeFWUpdate: false,
      spinnerLoading: false,
      spinnerError: false,
      showBluetoothBanner: false
    };

    this.videoTimeout = null;
    this.goToDashboard = this._goToDashboard.bind(this);
    this.onStepChange = this._onStepChange.bind(this);
    this.goToPrevStep = this._goToPrevStep.bind(this);
    this.onVisibilityChange = this._onVisibilityChange.bind(this);
    this.getActiveVideoIndexBy = this._getActiveVideoIndexBy.bind(this);
    this.changeVideoActivation = this._changeVideoActivation.bind(this);
    this.getActiveVideoHTMLElementBy =
      this._getActiveVideoHTMLElementBy.bind(this);
    this.playVideo = this._playVideo.bind(this);
    this.resetVideo = this._resetVideo.bind(this);

    this.handleVideo = this._handleVideo.bind(this);
    this.setIsAdvNameSupported = this._setIsAdvNameSupported.bind(this);
    this.checkIsAdvertisingNameSupported =
      this._checkIsAdvertisingNameSupported.bind(this);

    this.videoRef = React.createRef();

    this.aem = new AEMHelper();
    this.dictionary = {
      ...this.aem.getDictionary(aemPages.FW_UPDATE, {
        FW_UPDATE_HEADER: propertyHeadingDefaultEmpty,
      }),
      ...this.aem.getDictionary(aemPages.CUSTOMIZE, {
        PAIRED_CUSTOMIZE_TITLE: propertyTextDefaultEmpty,
        PAIRED_CUSTOMIZE_ADV_NAME_DEVICE_SETTINGS_TITLE:
          propertyHeadingDefaultEmpty,
        PAIRED_CUSTOMIZE_ADV_NAME_DEVICE_SETTINGS_SUCCESS:
          propertyTextDefaultEmpty,
        PAIRED_CUSTOMIZE_ADV_NAME_DEVICE_SETTINGS_FAIL:
          propertyTextDefaultEmpty,
        PAIRED_CUSTOMIZE_ADV_NAME_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
        PAIRED_CUSTOMIZE_HEADER_DEVICE_SETTINGS: propertyTextDefaultEmpty
      }),
      ...this.aem.getDictionary(aemPages.UNLOCK_SCREEN, {
        UNLOCK_SLIDE_LABEL: propertyTextDefaultEmpty,
        UNLOCK_UNLOCKED_LABEL: propertyTextDefaultEmpty,
        UNLOCK_LOCKED_LABEL: propertyTextDefaultEmpty,
      }),
      ...this.aem.getDictionary(aemPages.BLUETOOTH_POPUP, {
        BLE_ALERT_HEADER: propertyHeadingDefaultEmpty,
        BLE_ALERT_NOTICE_IOS: propertyTextDefaultEmptyParsed,
        BLE_ALERT_BTN_TURN_ON_IOS: propertyCtaItemsDefaultEmpty,
        BLE_ALERT_CONTENT_IOS: propertyTextDefaultEmptyParsed
      }),
      ...this.aem.getDictionary(aemPages.PAIRING, {
        PAIRING_PAIRING_LABEL: propertyTextDefaultEmptyParsed,
        PAIRING_PAIRED_LABEL: propertyTextDefaultEmpty,
        PAIRING_TAP_CONNECT_TO_PAIR: propertyTextDefaultEmpty,
        PAIRING_TITLE: propertyTextDefaultEmpty,
        PAIRING_ADD_NEW_DEVICE: propertyTextDefaultEmpty,
        PAIRING_BTN_NOT_WORKING: propertyTextDefaultEmpty,
        PAIRING_DEVICE_PAIRED_TITLE: propertyTextDefaultEmpty,
        PAIRING_ACTIVATE_DEVICE_BODY: propertyTextDefaultEmptyParsed,
        PAIRING_BTN_INFORMATIONS: propertyTextDefaultEmpty,
        PAIRING_HEADER_INFO: propertyHeadingDefaultEmpty,
        PAIRING_BTN_NO_USED: propertyCtaItemsDefaultEmptyParsed,
        PAIRING_BTN_POD_INSERTED: propertyCtaItemsDefaultEmpty,
        PAIRING_BTN_NEED_HELP: propertyTextDefaultEmpty,
        PAIRING_BTN_BLINKING: propertyCtaItemsDefaultEmpty,
        PAIRING_FOUND_LABEL: propertyTextDefaultEmpty,
        PAIRING_COLOR_DEVICE_LABEL: propertyTextDefaultEmpty,
        PAIRING_BODY: propertyTextDefaultEmpty,
        PAIRING_ACTIVATE_CHARGER_TITLE: propertyTextDefaultEmpty,
        PAIRING_ACTIVATE_CHARGER_BODY: propertyTextDefaultEmpty,
        PAIRING_DEVICE_PAIRED: propertyTextDefaultEmpty,
        PAIRING_BTN_MYVUSE: propertyCtaItemsDefaultEmpty,
        PAIRING_BTN_USED: propertyCtaItemsDefaultEmpty,
        PAIRING_FINDING_LABEL: propertyTextDefaultEmpty,
        PAIRING_BTN_CHARGER_CONNECTED: propertyCtaItemsDefaultEmpty,
        PAIRING_INSERT_POD: propertyTextDefaultEmpty,
        PAIRING_NAME_DEVICE_LABEL: propertyTextDefaultEmpty,
        PAIRING_INFO_3: propertyTextDefaultEmptyParsed,
        PAIRING_INFO_2: propertyTextDefaultEmptyParsed,
        PAIRING_ACTIVATE_DEVICE_INFO: propertyTextDefaultEmptyParsed,
        PAIRING_INFO_5: propertyTextDefaultEmptyParsed,
        PAIRING_ACTIVATE_DEVICE_TITLE: propertyTextDefaultEmpty,
        PAIRING_INFO_4: propertyTextDefaultEmptyParsed,
        PAIRING_BTN_CHOOSE: propertyCtaItemsDefaultEmpty,
        PAIRING_SKIP_LINK: propertyTextDefaultEmptyParsed,
        PAIRING_CUSTOMISE_LINK: propertyTextDefaultEmpty,
        PAIRING_NOT_FOUND_LINK: propertyTextDefaultEmpty,
        PAIRING_INFO_1: propertyTextDefaultEmptyParsed,
        PAIRING_INFO_6: propertyTextDefaultEmptyParsed,
        PAIRING_INFO_7: propertyTextDefaultEmptyParsed,
        PAIRING_PAIR_YOUR_DEVICE: propertyTextDefaultEmpty,
        PAIRING_PRESS_BUTTON_FIVE_TIMES: propertyTextDefaultEmpty,
        PAIRING_BTN_CONNECT: propertyCtaItemsDefaultEmpty,
        PAIRING_PAIRING_MODE: propertyTextDefaultEmptyParsed,
        PAIRING_ASAV_PAIRING_TITLE: propertyTextDefaultEmpty,
        PAIRING_ASAV_PAIRING_CONTENT: propertyTextDefaultEmpty,
        PAIRING_ASAV_BTN_LED_YELLOW: propertyTextDefaultEmpty,
        PAIRING_ASAV_VERIFYING_LABEL: propertyTextDefaultEmpty,
        PAIRING_ASAV_VERIFIED_LABEL: propertyTextDefaultEmpty,
        PAIRING_ASAV_PAIRING_LED_WHITE: propertyTextDefaultEmpty,
        PAIRING_ASAV_BTN_LED_BLINKING: propertyTextDefaultEmpty,
        PAIRING_PRESS_HOLD_BUTTON: propertyTextDefaultEmpty,
        PAIRING_KEEP_DEVICE_CLOSE: propertyTextDefaultEmpty,
        PAIRING_WAITING_MSG_LABEL: propertyTextDefaultEmpty
      }),
      ...this.aem.getDictionary(aemPages.PAIRING_FAILURE, {
        PAIRING_EPOD_NOT_FOUND_TITLE: propertyTextDefaultEmpty,
        PAIRING_EPOD_NOT_FOUND_SUBTITLE: propertyTextDefaultEmpty,
        PAIRING_EPOD_NOT_FOUND_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
        PAIRING_EPOD_S4_BTN_NEED_HELP: propertyTextDefaultEmpty,
        PAIRING_EPOD_NOT_FOUND_LIST_FIFTH: propertyTextDefaultEmptyParsed,
        PAIRING_LOCATION_ON_ALERT: propertyTextDefaultEmptyParsed,
        PAIRING_NOT_FOUND_LIST_SIXTH: propertyTextDefaultEmptyParsed,
        PAIRING_NOT_FOUND_SUBTITLE: propertyTextDefaultEmpty,
        PAIRING_EPOD_NOT_FOUND_LIST_FIRST: propertyTextDefaultEmptyParsed,
        PAIRING_NOT_FOUND_TITLE: propertyTextDefaultEmptyParsed,
        PAIRING_NOT_FOUND_LIST_FIFTH: propertyTextDefaultEmptyParsed,
        PAIRING_S4_BTN_NEED_HELP: propertyTextDefaultEmpty,
        PAIRING_LOCATION_OFF_ALERT: propertyTextDefaultEmpty,
        PAIRING_NOT_FOUND_LIST_FOURTH: propertyTextDefaultEmptyParsed,
        PAIRING_NOT_FOUND_LIST_FIRST: propertyTextDefaultEmptyParsed,
        PAIRING_EPOD_NOT_FOUND_LIST_FOURTH: propertyTextDefaultEmptyParsed,
        PAIRING_NOT_FOUND_LIST_SECOND: propertyTextDefaultEmptyParsed,
        PAIRING_NOT_FOUND_LIST_THIRD: propertyTextDefaultEmptyParsed,
        PAIRING_NOT_FOUND_LIST_SEVENTH: propertyTextDefaultEmptyParsed,
        PAIRING_NOT_FOUND_LIST_EIGHTH: propertyTextDefaultEmptyParsed,
        PAIRING_EPOD_NOT_FOUND_LIST_EIGHTH: propertyTextDefaultEmptyParsed,
        PAIRING_EPOD_NOT_FOUND_LIST_SIXTH: propertyTextDefaultEmptyParsed,
        PAIRING_EPOD_NOT_FOUND_LIST_SECOND: propertyTextDefaultEmptyParsed,
        PAIRING_EPOD_NOT_FOUND_LIST_THIRD: propertyTextDefaultEmptyParsed,
        PAIRING_EPOD_NOT_FOUND_LIST_NINTH: propertyTextDefaultEmptyParsed,
        PAIRING_NOT_FOUND_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
        PAIRING_EPOD_NOT_FOUND_LIST_SEVENTH: propertyTextDefaultEmptyParsed,
        PAIRING_ASAV_NOT_VERIFIED_TITLE: propertyTextDefaultEmpty,
        PAIRING_ASAV_NOT_VERIFIED_SUBTITLE: propertyTextDefaultEmpty,
        PAIRING_ASAV_NOT_VERIFIED_LIST_FIRST: propertyTextDefaultEmpty,
        PAIRING_ASAV_NOT_VERIFIED_LIST_SECOND: propertyTextDefaultEmpty,
        PAIRING_ASAV_NOT_VERIFIED_LIST_THIRD: propertyTextDefaultEmpty,
        PAIRING_ASAV_NOT_VERIFIED_LIST_FOURTH: propertyTextDefaultEmpty,
        PAIRING_ASAV_NOT_VERIFIED_LIST_FIFTH: propertyTextDefaultEmpty,
      }),
      ...this.aem.getDictionary(aemPages.BURGER_MENU, {
        MENU_BTN_CANCEL: propertyCtaItemsDefaultEmpty
      }),
      pairingVideo: [
        "PAIRING_VIDEO_NEW_DEVICE",
        "PAIRING_VIDEO_EPOD_ACTIVATION",
        "PAIRING_VIDEO_POD_ACTIVATION",
        "PAIRING_VIDEO_POD_INSERT",
        "PAIRING_VIDEO_PAIRING_MODE",
      ],
    };
  }

  componentDidMount() {
    triggerLandingOn(this.pairingSteps, getActiveStep());

    document.body.classList.add("pairing");

    if (window.location.hash) {
      this.handleVideo(getActiveStep());
    }

    const deviceFamilyInfo = getDeviceFamilyInfo(this.props.deviceProfile);
    const needASAV = deviceFamilyInfo?.asav;

    this.setState({
      needASAV: needASAV === "true" ? true : false,
      tutorials: getTutorialsVideo(
        aemPages.PAIRING,
        this.props.deviceProfile,
        this.dictionary.pairingVideo
      ),
    });
    console.log("stories", this.stories);

    document.addEventListener("visibilitychange", this.onVisibilityChange);

    // Fix resumeUpdate FOTA
    if (this.currentDevice?.ongoingUpdate) {
      this.setState({
        resumeFWUpdate: true,
      });
    }

    this.checkBluetooth()
  }

  componentWillUnmount() {
    document.body.classList.remove("pairing");

    document.removeEventListener("visibilitychange", this.onVisibilityChange);
  }

  checkBluetooth() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.TURN_BLUETOOTH_ON});
    if (navigator.bluetooth && navigator.bluetooth.isEnabled) {
      navigator.bluetooth.isEnabled()
      .then(isEnabled => {
        //ask again for system permission if is not granted
        if (!isEnabled) {
          navigator.permissions.request({ name: "bluetooth", system: true })
        }
        this.setState({
          showBluetoothBanner: !isEnabled
        })
      })
    }
  }

  _onVisibilityChange() {
    const { activeStep } = this.state;

    const currentVideo = this.getActiveVideoHTMLElementBy(activeStep);
    if (!!currentVideo && !currentVideo.ended) {
      if (BrowserHelper.isBrowserRunningInBackground()) {
        this.resetVideo(currentVideo);
      } else {
        this.playVideo(currentVideo);
      }
    }
  }

  _onStepChange(stepChangeConf) {
    triggerLandingOn(this.pairingSteps, stepChangeConf.activeStep);

    // console.log("stepChangeConf.previousStep",stepChangeConf.previousStep);
    // console.log("stepChangeConf.activeStep",stepChangeConf.activeStep);

    this.setState({
      previousStep: stepChangeConf.previousStep,
      activeStep: stepChangeConf.activeStep,
    });

    if (stepChangeConf.previousStep < stepChangeConf.activeStep) {
      const { stepsStack } = this.state;
      this.setState({
        stepsStack: [...stepsStack, stepChangeConf.previousStep],
      });
    }
  }

  _goToDashboard() {
    history.push(buildURI(routingConstants.DASHBOARD));
  }

  _goToPrevStep() {
    const { wizardInstance, stepsStack } = this.state;

    // console.log('stepsStack', stepsStack);
    // console.log('wizardInstance.currentStep', wizardInstance.currentStep);

    if (wizardInstance.currentStep > 1) {
      const newStepsStack = [...stepsStack]; // clone array
      if (newStepsStack.length > 0) {
        wizardInstance.goToStep(newStepsStack.pop());
        this.setState({
          stepsStack: newStepsStack,
          activeStep: newStepsStack[newStepsStack.length - 1],
        });
      } else {
        this.setState({ stepsStack: [], activeStep: 0 });
        wizardInstance.goToStep(0);
      }
    } else {
      if (wizardInstance.currentStep === 1) {
        this.setState({ activeStep: 1 });
        history.push(buildURI(routingConstants.DASHBOARD));
        return;
      } else {
        this.setState({ activeStep: 0 });
        this.goToDashboard();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.devices !== prevProps.devices) {
      const device = this.props.devices.find((device) => device.selected);

      this.checkIsAdvertisingNameSupported(device);
    }

    if ((prevState.activeStep !== this.state.activeStep) && (this.state.activeStep !== undefined)) {
      this.handleVideo(this.state.activeStep);
    }

    if (
      prevState.needASAV !== this.state.needASAV ||
      prevState.isASAVFlow !== this.state.isASAVFlow
    ) {
      if (this.state.needASAV && this.state.isASAVFlow) {
        this.setState({
          tutorials: getTutorialsVideo(
            aemPages.PAIRING,
            this.props.deviceProfile,
            [
              ...this.dictionary.pairingVideo.slice(0, -1),
              "PAIRING_VIDEO_PAIRING_MODE_ASAV",
            ]
          ),
        });
      }
    }
  }

  _handleVideo(activeStep) {
    const currentVideo = this.getActiveVideoHTMLElementBy(activeStep);
    if (!!currentVideo) {
      this.resetVideo(currentVideo);
      this.playVideo(currentVideo);
    }

    this.setState(
      {
        activeVideoIndex: this.getActiveVideoIndexBy(activeStep),
        isVideoEnded: false,
      }
    )
  }

  _playVideo(videoHTML) {
    clearTimeout(this.videoTimeout);
    this.videoTimeout = setTimeout(() => {
      this.onVideoEnded(this.getActiveVideoIndexBy(this.state.activeStep));
    }, 5000);
  }

  _resetVideo(videoHTML) {
    clearTimeout(this.videoTimeout);
    videoHTML.pause();
    videoHTML.currentTime = 0;
    videoHTML.load();
  }

  _getActiveVideoIndexBy(activeStep) {
    if (activeStep === 1) {
      return 0
    } if (activeStep === 2) {
      return 4
    } else {
      return null
    }
  }

  _changeVideoActivation(activationValue){
    this.setState({isVideoActive: activationValue});
  }

  _getActiveVideoHTMLElementBy(activeStep) {
    return !!this.videoRef.current
      ? this.videoRef.current.querySelector(
          `video[data-index="${this.getActiveVideoIndexBy(activeStep)}"]`
        )
      : null;
  }

  onVideoEnded(videoIndex) {
    if (this.state.activeVideoIndex === videoIndex) {
      clearTimeout(this.videoTimeout);
      this.setState({ isVideoEnded: true });
    }
  }

  _setIsAdvNameSupported(isAdvNameSupported) {
    this.setState({
      isAdvNameSupported,
    });
  }

  _checkIsAdvertisingNameSupported(device) {
    if (device) {
      isAdvertisingNameSupported(device)
        .then((isSupported) => {
          console.debug("Pairing - isAdvertisingNameSupported", isSupported);

          this.setState({
            isAdvNameSupported: isSupported,
          });
        })
        .catch((err) => console.debug(err));
    }
  }

  render() {
    const {
      wizardInstance,
      activeVideoIndex,
      spinnerLoading,
      spinnerError,
      showBluetoothBanner
    } = this.state;

    return (
      <div className="page w-100">
        {this.state.isASAVFlow &&
        this.state.needASAV &&
        !this.state.isASAVCompleted ? (
          <AsavLoader
            dictionary={this.dictionary}
            show={this.state.isASAVLoaderOpen}
            hasClosingDelay={this.state.isASAVCompleted}
          />
        ) : (
          <PairingLoader dictionary={this.dictionary} changeVideoActivation={this.changeVideoActivation} />
        )}
        <div className="page w-100">
          <Header
            leftButton={{
              icon: <span className="bat-icon-back" />,
              onClick: () => {
                logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.BACK});
                this.goToPrevStep();
              },
            }}
          >
            {this.dictionary.PAIRING_ADD_NEW_DEVICE}
          </Header>
          <StepWizard
            onStepChange={this.onStepChange}
            instance={(wizardInstance) => this.setState({ wizardInstance })}
            transitions={{}}
            isLazyMount={true}
            className="pairing-steps"
          >
            {renderAllSteps(this.pairingSteps)}
          </StepWizard>

          <div className="pairing-bg-gradient"></div>

          <div ref={this.videoRef}>
            {this.state.isVideoActive && this.state.tutorials.map(
              (pairingVideoSrc, index) =>
                pairingVideoSrc && (
                  <div
                    key={index}
                    style={{
                      visibility:
                        activeVideoIndex === index ? "visible" : "hidden",
                    }}
                  >
                    <video
                      className={ (index===0) ? "pairing-sm-video" : "pairing-bg-video"}
                      src={`${pairingVideoSrc}#t=0.001`}
                      autoPlay={true}
                      muted
                      playsInline
                      loop={true}
                      preload="auto"
                      onEnded={() => this.onVideoEnded(index)}
                      data-index={index}
                    ></video>
                  </div>
                )
            )}
          </div>
        </div>

        {this.state.resumeFWUpdate && (
          <FirmwareUpdate show={true} resumeUpdate={true} />
        )}

        <CustomSlidingPanel
          from="bottom"
          isOpen={showBluetoothBanner && wizardInstance?.currentStep === 1}
          overlayClassName={
            "sliding-common-wrapper sliding-device-check-bluetooth-wrapper"
          }
          className="onboarding-panel mid-height-panel"
          title={
            this.dictionary.BLE_ALERT_HEADER
          }
          backdropClose={false}
        >
          <div className="h-100 d-flex flex-column">
            <div className="sliding-panel-mid" style={{ zIndex: 2 }}>
                <div className="sliding-panel-mid-icon">
                    <img src={imagesConstants.BLUETOOTH} alt="bluetooth" />
                </div>
                <div className="sliding-panel-mid-info">
                    {this.dictionary.BLE_ALERT_CONTENT_IOS}
                </div>
                <div className="sliding-panel-mid-value d-flex align-items-center justify-content-center mt-5">
                    <span>{this.dictionary.BLE_ALERT_NOTICE_IOS}</span>
                </div>
            </div>

            <PageSelectorCTA sticky={false} className="mt-auto">
                <div className="d-grid gap-2">
                    <button className="btn btn-primary text-uppercase" type="button"
                        onClick={() => this.checkBluetooth()}>
                        {this.dictionary.BLE_ALERT_BTN_TURN_ON_IOS_0_ctaLabel}
                    </button>
                    <button className="btn btn btn-outline-secondary text-uppercase" type="button"
                        onClick={() => this.goToPrevStep()}>
                        {this.dictionary.MENU_BTN_CANCEL_0_ctaLabel}
                    </button>
                </div>
            </PageSelectorCTA>
          </div>
        </CustomSlidingPanel>

        <SpinnerModal
          show={spinnerLoading}
          error={spinnerError}
          onForceClose={() => {
            this.setState({spinnerError: false})
            this.setState({spinnerLoading: false})
          }}
          goOnMoreMenu = { false }
        />
      </div>
    );
  }

  get pairingSteps() {
    // console.log("[PAIRING] currentDevice ", JSON.parse(JSON.stringify(this.currentDevice)));
    // console.log("wizardInstance?.currentStep");
    // console.log(wizardInstance);
    // console.log(wizardInstance?.currentStep);

    return [
      {
        onLanding: () => {
          logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
            screen_name: AnalyticsScreenEventNames.PAIRING_ACTIVATE_NEW,
          });
        },
        render: () => (
          <DeviceActivation
            {...PAIRING_STEPS[0]}
            isVideoEnded={this.state.isVideoEnded}
            dictionary={this.dictionary}
          />
        ),
      },
      {
        onLanding: () => {
          logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
            screen_name: AnalyticsScreenEventNames.PAIR_YOUR_DEVICE,
          });
        },
        render: () =>
          this.state.isASAVFlow &&
          this.state.needASAV &&
          !this.state.isASAVCompleted ? (
            <DeviceAsav
              {...PAIRING_STEPS[1]}
              dictionary={this.dictionary}
              isVideoEnded={this.state.isVideoEnded}
              onStart={() => this.setState({ isASAVLoaderOpen: true })}
              onSuccess={() =>
                this.setState({
                  isASAVCompleted: true,
                })
              }
              onFailure={() =>
                this.setState({
                  isASAVLoaderOpen: false,
                })
              }
            />
          ) : (
            <DevicePairing
              {...PAIRING_STEPS[1]}
              device={this.currentDevice}
              dictionary={this.dictionary}
              isVideoEnded={this.state.isVideoEnded}
              isASAVCompleted={this.state.isASAVCompleted}
              deviceProfile={this.props.deviceProfile}
            />
          ),
      },
      {
        onLanding: () => {
          logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
            screen_name: AnalyticsScreenEventNames.PAIRING_PROCESSING_ERROR,
          });
        },
        render: () =>
          this.state.isASAVFlow && this.state.needASAV ? (
            <AsavFailure {...PAIRING_STEPS[2]} dictionary={this.dictionary} />
          ) : (
            <PairingFailure
              {...PAIRING_STEPS[2]}
              dictionary={this.dictionary}
            />
          ),
      },
      {
        onLanding: () => {
          logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
            screen_name: AnalyticsScreenEventNames.SLIDE_TO_UNLOCK,
          });
        },
        render: () => (
          <DeviceUnlock
            {...PAIRING_STEPS[3]}
            device={this.currentDevice}
            dictionary={this.dictionary}
          />
        ),
      },
      {
        onLanding: () => {
          logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
            screen_name: AnalyticsScreenEventNames.PAIRING_ERROR,
          });
        },
        render: () => (
          <SearchingFailure
            {...PAIRING_STEPS[4]}
            dictionary={this.dictionary}
            goBack={() => {
              logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.TRY_AGAIN});
              this.goToPrevStep();
            }}
          />
        ),
      },
    ];
  }

  get currentDevice() {
    const { devices } = this.props;

    const device = devices && devices.length > 0 ? devices[0] : null;
    return JSON.parse(JSON.stringify(device));
  }
}

function mapStateToProps(state) {
  return {
    loading: state.deviceReducer.loading,
    error: state.deviceReducer.error,
    devices: state.deviceReducer.devices,
    deviceProfile: state?.deviceReducer?.deviceProfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addOrUpdateDevice: (devicePayload, addOnTop, callBack) => {
      dispatch(
        deviceActions.addOrUpdateDevice(
          devicePayload.toObj(),
          addOnTop,
          callBack
        )
      );
    },
    setUserSettings: (settingName, value, successCb, failureCb) => {
      dispatch(
        userActions.setUserSettings(settingName, value, successCb, failureCb)
      );
    },
  };
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Pairing);
export { connectedComponent as Pairing };
