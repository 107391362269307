export const thingVuseProperties = {
  TC: "TC",
  MANAGENOTIFICATION: "MANAGENOTIFICATION",
  //for notifications
  LOCK_TUTORIAL: "LOCK_TUTORIAL",
  FINDMYVAPE: "FINDMYVAPE",
  CLOUDCONTROL: "CLOUDCONTROL",
  ONBOARDING: "ONBOARDING", //probably not used anymore
  DEVICEPAIR: "DEVICEPAIR",
  BATTERYSAVE: "BATTERYSAVE",
  FAVOURITES: "FAVOURITES",
  //notification preferences
  FIRMWAREUPDATE: "FIRMWAREUPDATE",
  DEVICEERROR: "DEVICEERROR",
  DEVICECONNECTION: "DEVICECONNECTION",
  SUGGESTIONS: "SUGGESTIONS",
  ORDERTRACKING: "ORDERTRACKING",
  SUBSCRIPTIONALERT: "SUBSCRIPTIONALERT",
  FLAVOR4YOU: "FLAVOR4YOU",
  UNFAVOURITES: "UNFAVOURITES",
  //OPTI IN OLD
  OPT_IN_FINDMYVAPE: "OPT_IN_FINDMYVAPE",
  OPT_IN_FLAVOUR_DISC: "OPT_IN_FLAVOUR_DISCOVERY",
  OPT_IN_FAVOURITES: "OPT_IN_FAVOURITES",
  //new (from device thing)
  BATTERY_THRESHOLD_ENABLED: "BATTERY_THRESHOLD_ENABLED",
  BATTERY_FORECAST_ENABLED: "BATTERY_FORECAST_ENABLED",
  BATTERY_THRESHOLD: "BATTERY_THRESHOLD",
  SELECTED_TIME: "SELECTED_TIME",
  TIMEZONE: "TIMEZONE",
  OS: "OS",
  FULL_CHARGE_ALERT: "FULL_CHARGE_ALERT",
  LOCALE: "LOCALE",

  COMMUNICATION_EMAIL: "COMMUNICATION_EMAIL",
  COMMUNICATION_NOTIFICATIONS: "COMMUNICATION_NOTIFICATIONS",

  MKT_DEVICETYPE:             'MKT_DEVICETYPE', 
  MKT_LASTACTIVE:             'MKT_LASTACTIVE', 
  APPVERSION:                 'APPVERSION',
  VUSE_WORLD_STORIES:         'VUSE_WORLD_STORIES',

  MULTI_DEV_TUT_ADD_ANOTHER:  'MULTI_DEV_TUT_ADD_ANOTHER',
  MULTI_DEV_TUT_SWIPE_SWITCH: 'MULTI_DEV_TUT_SWIPE_SWITCH',
  MULTI_DEV_TUT_SELECT_REARRANGE: 'MULTI_DEV_TUT_SELECT_REARRANGE',
  MULTI_DEV_TUT_REARRANGE_CARD: 'MULTI_DEV_TUT_REARRANGE_CARD',

  USAGE_TRACKER:              'USAGE_TRACKER',
  OPT_IN_USAGE_TRACKER:       'OPT_IN_STATS',
  USAGE_TRACKER_SESSION_TIP:  'USAGE_TRACKER_SESSION_TIP',

  POD_TRACKER:                'POD_TRACKER',
  OPT_IN_POD_TRACKER:         'OPT_IN_POD_TRACKER'
}

export const thingDeviceProperties = {
    FIRMWARE_VERSION:           'FIRMWARE_VERSION',
    BATTERY_PERCENTAGE:         'BATTERY_PERCENTAGE',
    FLASH_DEVICE:               'FLASH_DEVICE',
    SET_CLOUD:                  'SET_CLOUD',
    LOCK_DEVICE:                'LOCK_DEVICE',

    LED_BRIGHTNESS:             'LED_BRIGHTNESS',
    SCREEN_BRIGHTNESS:          'SCREEN_BRIGHTNESS',
    HAPTIC_INTENSITY:           'HAPTIC_INTENSITY',
    SOUND_VOLUME:               'SOUND_VOLUME',

    DEVICE_NAME:                'DEVICE_NAME',
    DEVICE_COLOR:               'DEVICE_COLOR',
    SERIAL_NUMBER:              'SERIAL_NUMBER',
    LAST_LOCATION:              'LAST_LOCATION',//?
    FIRMWARE_LATEST_VERSION:    'FIRMWARE_LATEST_VERSION',
    FIRMWARE_CHANGELOG:         'FIRMWARE_CHANGELOG', //rimuovibile?
    FIRMWARE_SOURCE:            'FIRMWARE_SOURCE',
    FIRMWARE_MANDATORY:         'FIRMWARE_MANDATORY',
    DEVICE_TYPE:                'DEVICE_TYPE',
    BATTERY_SAVER_ENABLED:      'BATTERY_SAVER_ENABLED',
    BATTERY_SAVER_THRESHOLD:    'BATTERY_SAVER_THRESHOLD',
    
    BATTERY_THRESHOLD:          'BATTERY_THRESHOLD',
    BATTERY_THRESHOLD_ENABLED:  'BATTERY_THRESHOLD_ENABLED',
    BATTERY_FORECAST_ENABLED:   'BATTERY_FORECAST_ENABLED',
    FULL_CHARGE_ALERT:          'FULL_CHARGE_ALERT',
    SELECTED_TIME:              'SELECTED_TIME',

}

// export const rulePropertyData = {
//     FINDMYVAPE:     'FINDMYVAPE',
//     FLAVOUR4YOU:    'FLAVOUR4YOU',
//     CLOUDCONTROL:   'CLOUDCONTROL',
//     DEVICEPAIR:     'DEVICEPAIR',
//     BATTERYSAVE:    'BATTERYSAVE',

//     ONBOARDING:     'ONBOARDING'
// }

// the things that required the notification permission
export const NOTIFICATION_THINGS = [
  thingVuseProperties.DEVICECONNECTION,
  thingVuseProperties.DEVICEERROR,
  thingVuseProperties.FIRMWAREUPDATE,
  thingVuseProperties.SUGGESTIONS,
  thingVuseProperties.COMMUNICATION_NOTIFICATIONS,
];

export const THINGS_2_ANALYTICS_PREFERENCE_MAP = {
    [thingVuseProperties.BATTERY_THRESHOLD_ENABLED]: "optin_lowbattery",
    [thingVuseProperties.BATTERY_FORECAST_ENABLED]: "optin_dailybattery",
    [thingVuseProperties.FULL_CHARGE_ALERT]: "optin_fullbattery",
    [thingVuseProperties.OPT_IN_FAVOURITES]: "optin_favourites",
    [thingVuseProperties.OPT_IN_FLAVOUR_DISC]: "optin_ffy",
    [thingVuseProperties.OPT_IN_FINDMYVAPE]: "optin_findmyvape",
    [thingVuseProperties.BATTERY_THRESHOLD]: "optin_lowbattery_treshold",
    [thingVuseProperties.SELECTED_TIME]: "optin_dailybattery_time",
    [thingVuseProperties.DEVICECONNECTION]: "notif_deviceconnection",
    [thingVuseProperties.DEVICEERROR]: "notif_deviceerror",
    [thingVuseProperties.FIRMWAREUPDATE]: "notif_firmwareupdate",
    [thingVuseProperties.SUGGESTIONS]: "notif_suggestions",
    [thingVuseProperties.OPT_IN_USAGE_TRACKER]: "device_usage_tracker",
}

export const crmUserSettings = {
  COOKIE_NOTICE: "general_cookie_notice",
  TC: "myvuse_terms_and_conditions_accepted",
  PRIVACY_POLICY: "myvuse_privacy_policy_accepted",
  OPT_IN_EMAIL: "marketing_email_opt_in",
  OPT_IN_NOTIFICATIONS: "marketing_notifications_opt_in",
  DEVICEERROR: "notifications_preferences_device_errors_opt_in",
  DEVICECONNECTION: "notifications_preferences_device_status_opt_in",
  FIRMWAREUPDATE: "notifications_preferences_firmware_update_opt_in",
  SUGGESTIONS: "notifications_preferences_suggestions_opt_in",
  BATTERY_THRESHOLD_ENABLED: "battery_reminders_low_battery_alerts",
  BATTERY_FORECAST_ENABLED: "battery_reminders_check_battery_opt_in",
  FULL_CHARGE_ALERT: "battery_reminders_full_battery_alert_opt_in",
  OPT_IN_FINDMYVAPE: "find_my_vape_feature_opt_in",
  OPT_IN_FAVOURITES: "favorite_feature_opt_in",
  OPT_IN_ORDER_REMINDER: "order_reminder_opt_in",
  OPT_IN_FLAVOUR_DISC: "flavor_for_you_feature_opt_in",
  LAST_SESSION: "crm_enrichment_last_session",
  DEVICEPAIR: "crm_enrichment_has_at_least_1_device_paired",
  FAVOURITES: "crm_enrichment_in_app_favourite_flavours",
  APP_OS: "app_os",
  APP_VERSION: "app_version",
  PAIRED_DEVICES: "paired_devices",
  DEVICE_RENAME: "device_rename",
  CONNECTED_DEVICE: "connected_device",
  MYVUSE_LOGIN: "myvuse_login",
  FMV_PING: "find_my_vape_feature_ping",
  OPT_IN_STATS: "usage_tracker_feature_opt_in",
  USAGE_TRACKER_USED: "usage_tracker_feature_used",
  BATTERY_SAVER_USED: "batter_saver_used",
  CLOUD_CONTROL_PRESET_USED: "cloud_control_preset_used",
  IS_DELETED: "is_deleted",
  UTM_DATA: "utm_data",
  LAST_OPENEDTHEAPP: "last_openedtheapp",
  OPT_IN_POD_TRACKER: "pod_tracker_feature_opt_in",
  POD_TRACKER_USED: "pod_tracker_feature_used",
  //OPT_IN_USAGE_ALERTS: "usage_alerts_feature_opt_in",
  //USAGE_ALERTS_USED: "usage_alerts_feature_used",
  OPT_IN_AUTOTUNE: "autotune_feature_opt_in",
  DEVICE_COLOR : "device_colour_changed"
};

export const registrationConstants = {
  REGISTER_USER:                  'registration/REGISTER_USER',
  GET_TOKEN:                      'registration/GET_TOKEN',
  CHECK_EMAIL:                    'registration/CHECK_EMAIL',
  GET_ADDRESSES:                  'registration/GET_ADDRESSES',
  GET_ADDRESS_DETAILS:            'registration/GET_ADDRESS_DETAILS',
  SET_REGISTRATION_PROFILE_DATA:  'registration/SET_REGISTRATION_PROFILE_DATA',
  RESET_REGISTRATION_PROFILE_DATA:'registration/RESET_REGISTRATION_PROFILE_DATA',
  SET_ACTIVE_STEP:                'registration/SET_ACTIVE_STEP'
}

export const loginConstants = {
  LOGIN: "login/LOGIN",
  RESET_PASSWORD: "login/RESET_PASSWORD",
  GET_USER_INFO: "login/GET_USER_INFO",
  GET_CONFIRMATION_LINK: "login/GET_CONFIRMATION_LINK",
  GET_CUSTOMER_TOKEN: "login/GET_CUSTOMER_TOKEN",
  GET_CUSTOMER_DATA: "login/GET_CUSTOMER_DATA",
  RESEND_ACTIVATION_LINK: "login/RESEND_ACTIVATION_LINK",
  LOGOUT: "login/LOGOUT"
};
