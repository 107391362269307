import React, { useState } from "react";
import { CustomSlidingPanel, PageSelectorCTA } from '..';
import Picker from "../WheelPicker";
import { CustomLoadingButton } from '../CustomLoadingButton';

const digitHeight = 47;
const pickerHeight = 180;
const highlightHeight = 47;

export const PickerPanel = ({
    isOpenPanel,
    panelTitle,
    panelDescription,
    pickerOptions,
    pickerSelected,
    buttonLabel,
    onPickerSelection,
    onPanelDismiss,
    onPanelClose,
    isLoading
}) => {

    const [containerRef, setContainerRef] = useState(null);
    const [panelContentRef, setPanelContentRef] = useState(null);

    const contentRef = (node) => {
        if (node !== null) {
            setContainerRef(node);
        }
    }

    return (
        <CustomSlidingPanel from="bottom"
            isOpen={isOpenPanel}
            overlayClassName={"sliding-common-wrapper priority"}
            className="onboarding-panel mid-height-panel form-panel"
            title={panelTitle}
            onClose={onPanelDismiss}
            setContentRef={(ref) => setPanelContentRef(ref)}
            additionalStyles={{ 
                marginTop: `calc(100vh - (${panelContentRef?.clientHeight ?? 0}px))`,
            }}
        >

            <div className="h-100 d-flex flex-column" ref={contentRef}>

                <div className="sliding-panel-mid" style={{paddingTop: "44px", paddingBottom: "32px"}}>
                    <div className="sliding-panel-mid-description">
                        {panelDescription}
                    </div>
                </div>

                <div className="ios-picker battery-picker">
                    {<Picker
                        optionGroups={pickerOptions}
                        valueGroups={pickerSelected}
                        onChange={onPickerSelection}
                        itemHeight={digitHeight}
                        height={pickerHeight}
                        highlightHeight={highlightHeight}
                        panelRef={containerRef}
                        isPickerPanel={true}
                    />}
                </div>
                <PageSelectorCTA className="mt-auto" style={{position: "relative"}}>
                    <div className="d-grid">
                        <CustomLoadingButton isLoading={isLoading} validationMethod={null}
                            buttonLabel={buttonLabel} onClickMethod={onPanelClose} />
                    </div>
                </PageSelectorCTA>
            </div>
        </CustomSlidingPanel>
    );

};
