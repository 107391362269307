import React, { useEffect, useState } from "react";
import animationLoading from "../../_assets/lotties/Pairing_Icons_Loading.json";
import { AEMHelper, propertyTextDefaultEmptyParsed } from "../../_helpers/aem/aemhelper";
import { aemPages } from "../../_constants";
import CustomLottie from "../CustomLottie/CustomLottie";
import { Commons } from "../../_helpers/commons";

const SpinnerModal = ({
  show,
  error,
  onForceClose,
  goOnMoreMenu = true,
}) => {
  const [dictionary, setDictionary] = useState({});
  const [showDelay, setShowDelay] = useState(false);

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.MANAGE_FEATURES_AND_PREFERENCES, {
        MNG_SECTION_SOME_WRONG: propertyTextDefaultEmptyParsed,
        MNG_SECTION_TAKING_LONGER: propertyTextDefaultEmptyParsed
      })
    );
  }, []);

  /*useEffect(() => {
    if (error) {
      setTimeout(function() {
        Commons.goToDashboard({isMoreMenuOpen: true});
      }, 3000)
    }
  }, [error])*/

  useEffect(() => {
    if (show) {
      setShowDelay(false)

      setTimeout(function() {
        setShowDelay(true)
      }, 3000)
    }
  }, [show])

  const forceClose = () => {
    onForceClose();
    Commons.goToDashboard({isMoreMenuOpen: goOnMoreMenu});
  }

  return (
    <React.Fragment>
      {show &&
      <div className="spinner-modal-container">

        {typeof onForceClose === 'function' &&
        <header className="spinner-header container">
          <div className="row">
            <div className="col text-end">
              <button
                type="button"
                className="app-header-btn spinner-modal-close"
                onClick={forceClose}
              >
                <span className='bat-icon-close' />
              </button>
            </div>
          </div>
        </header>
        }
        
        <div className="spinner-modal">
        {error &&
          <div className="spinner-container">
            <svg viewBox="0 0 81 81" className="spinner">
              <circle r="37.5" cx="40.5" cy="40.5" className="external-circle" fill="none" strokeWidth="3" stroke="white" strokeOpacity="0.1"></circle>

              {/*
              <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">
                <stop offset="0%" stop-color="transparent"></stop>
                <stop offset="100%" stop-color="#00C7B1"></stop>
              </linearGradient>

              <path d="M40.5 3 a37.5 37.5 0 0 1 37.5 37.5" fill="none" stroke="url(#linearColors1)" stroke-width="3" />
              */}
            </svg>

            <div className="spinner-icon">
              {error &&
              <div className="bat-icon-alert-triangle-outline"></div>
              }
            </div>
          </div>
        }
        {!error &&
          <CustomLottie
            options={{
                loop: true,
                autoplay: true,
                animationData: animationLoading,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                }
            }}
            height={75}
            width={75}
            eventListeners={[{
                eventName: 'complete',
                //callback: currentStatus?.completeCallback,
            }]}
          />
          }
          
          <div className="spinner-label">
            {(show && !error && showDelay) &&
            <div>{dictionary.MNG_SECTION_TAKING_LONGER}</div>
            }
            {(show && error) &&
            <div>{dictionary.MNG_SECTION_SOME_WRONG}</div>
            }
          </div>
        </div>

      </div>
      }
    </React.Fragment>
  );
};

export { SpinnerModal };