import React from "react";
import animationLoading from "../../_assets/lotties/Pairing_Icons_Loading.json";
import animationComplete from "../../_assets/lotties/Pairing_Icons_LoadingComplete.json";
import { imagesConstants } from "../../_constants";
import CustomLottie from "../CustomLottie/CustomLottie";

export function HardAgeVerificationProcessing({ isComplete, text, subText="" }) {
  return (
    <div className="hard-age-verification-processing">
      <div className="hard-age-verification-title-ca text-center">
        <h1>{text}</h1>
      </div>
      <div className="hard-age-verification-lottie-wrapper">
        {isComplete ? (
          <>
            <CustomLottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationComplete,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={75}
              width={75}
              style={{
                position: "absolute",
                top: "30%",
                left: "40%",
                marginTop: "62px"
              }}
            />
          </>
        ) : (
          <>
            <CustomLottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationLoading,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={75}
              width={75}
              style={{
                position: "absolute",
                top: "30%",
                left: "40%",
                marginTop: "62px"
              }}
            />
            <div className="hard-age-verification-processing-subtext">{subText}</div>
          </>
        )}
      </div>
    </div>
  );
}
