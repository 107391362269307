import React from "react";

import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { EffectFade, Pagination } from 'swiper/modules';
import _, { isEmpty, isEqual } from "lodash";
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import { AppStories } from "../../_components/AppStories";
import {
  aemPages,
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  flagStatus,
  thingVuseProperties,
  routingConstants,
  imagesConstants,
  PROFILE_EPOD2,
  PROFILE_WAWE2,
  PROFILE_SMARTBOX,
  servicesConstants,
} from "../../_constants";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import BrowserHelper from "../../_helpers/browser/browser.helper";
import { DevicePanel } from "../../_components/DevicePanel/DevicePanel";
import { Utils } from "../../_helpers/graphic/utils";
import { dashboardActions, deviceActions, getUserEverLogged, getUserData } from "../../_actions";
import sdk from "../../_vendors/nodes";
import { Tenants } from "../../_helpers/tenants";
import {
  setDeviceConnectionStateChanged,
  getDeviceInstanceFromSN,
  clearAllLastSyncedLocations,
  DEVICE_PROFILE,
  getDeviceName,
  hasLastSyncedLocation,
  retrieveDeviceLocation,
  isLowPowerModeEnabledBy,
  handleDeviceSelection
} from "../../_helpers/device";
import { debug } from "../../_helpers/debug";
import {
  logAnalyticsEvent,
  logAnalyticsEventByCustomEvent,
  logAnalyticsEventForDevice,
} from "../../_helpers/analytics/logAnalytics";
import {
  renderAllSteps,
  triggerLandingOn,
} from "../../_helpers/multipleStepWiew";
import { Commons } from "../../_helpers/commons";
import { store } from "../../_store";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { Switch } from "../../_components/Switch";
import {
  PageSelectorCTA,
  ReconnectLoader,
  TutorialTip,
} from "../../_components";
import isNil from "lodash/isNil";
import { vuse } from "@nodes-projects/bat-sdk-web";
import { PermissionsHelper } from "../../_helpers/permissions/permissions.helper";
import cx from "classnames";
import { DeviceSwitcher } from "../../_components";
import { getDeviceHomeScreenImg } from "../../_helpers/device/assets";
import { FirmwareUpdate } from "../../_components/FirmwareUpdate/FirmwareUpdate";
import { isSupportedService, getDeviceSpec, getCountry, getProvince, getSupportedDevices } from "../../_actions/appConfig.actions";
import { isUsageTrackerEnabled, calcAgeRange } from "../../_helpers/usage-tracker";
import { getTruncateAppVersion } from '../../_helpers/utils';
import moment from "moment";

SwiperCore.use([Pagination, EffectFade]);

class Device extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      currentDevice: null,
      devicesLockStatus: [],
      maxSupportedDevices: 3,
      loadingConnection: false,
      isDeviceSwitcherOpen: false,
      swiper: null,
      pairTutorialAccepted: false,
      swipeTutorialAccepted: false,
      rearrangeTutorialAccepted: false,
      devices: getSupportedDevices()
    };

    this.carouselRef = React.createRef();

    this.goToDeviceSettings = this._goToDeviceSettings.bind(this);
    this.goToBatterySettings = this._goToBatterySettings.bind(this);
    this.buyDevice = this._buyDevice.bind(this);
    this.goToUsageTracker = this._goToUsageTracker.bind(this);
    this.goToCloudControl = this._goToCloudControl.bind(this);
    this.goToFindMyVape = this._goToFindMyVape.bind(this);

    this.renderNoDevicesDashboard = this._renderNoDevicesDashboard.bind(this);
    this.renderDevicesCarouselDashboard =
      this._renderDevicesCarouselDashboard.bind(this);
    this.renderFirmwareUpdate = this._renderFirmwareUpdate.bind(this);

    this.handleChangeDevice = this._handleChangeDevice.bind(this);
    this.unlockDevice = this._unlockDevice.bind(this);
    this.reconnectDevice = this._reconnectDevice.bind(this);
    this.startLoadingConnection = this._startLoadingConnection.bind(this);
    this.endLoadingConnection = this._endLoadingConnection.bind(this);
    this.changeLockStatus = _.debounce(this.changeLockStatus.bind(this), 1000);

    this.handleDeviceSwitcherPanelClose =
      this._handleDeviceSwitcherPanelClose.bind(this);

    this.aem = new AEMHelper();
    this.dictionary = this.aem.getDictionary(aemPages.HOMEPAGE, {
      BOLD: propertyTextDefaultEmpty,
      LOW_POWER: propertyTextDefaultEmpty,
      SMOOTH: propertyTextDefaultEmpty,
      BALANCED: propertyTextDefaultEmpty,
      HOME_LABEL_CONNECTED: propertyTextDefaultEmpty,
      HOME_LABEL_DISCONNECTED: propertyTextDefaultEmpty,
      HOME_ALERT_MAX_DEV_TITLE: propertyTextDefaultEmpty,
      HOME_BTN_PAIR_YOUR_DEVICE: propertyTextDefaultEmpty,
      HOME_ALERT_MAX_DEV_BODY: propertyTextDefaultEmpty,
      HOME_LABEL_MYDEVICES: propertyTextDefaultEmpty,
      HOME_LABEL_FMV: propertyTextDefaultEmpty,
      HOME_DEVICES_CONNECTED: propertyTextDefaultEmpty,
      HOME_BTN_RECONNECT: propertyCtaItemsDefaultEmpty,
      HOME_PAIR_HEADER: propertyHeadingDefaultEmpty,
      HOME_BTN_BUY_DEVICE: propertyTextDefaultEmpty,
      HOME_LABEL_DEV_SETTINGS: propertyTextDefaultEmpty,
      HOME_WELCOME_BANNER: propertyTextDefaultEmpty,
      HOME_LABEL_UNAVAILABLE: propertyTextDefaultEmpty,
      HOME_LABEL_UPDATES_AVAILABLE: propertyTextDefaultEmpty,
      HOME_TAP_TO_ADD_ANOTHER_DEVICE_LABEL: propertyTextDefaultEmpty,
      HOME_SWIPE_TO_SWITCH_DEVICE_LABEL: propertyTextDefaultEmpty,
      HOME_TAP_TO_SELECT_REARRANGE_MANAGE_DEVICES_LABEL:
        propertyTextDefaultEmpty,
      HOME_LABEL_USAGE_TRACKER: propertyTextDefaultEmpty,
    });

    let urls = this.aem?.getCarouselUrls(
      aemPages.HOMEPAGE,
      "carousel",
      "mobile"
    );
    let stories = urls.map((url) => {
      return {
        url: url,
        type: "video",
        muted: true
      };
    });

    this.stories = stories;
  }

  getCurrentDevice() {
    return this.props.devices.find((_) => _.selected) ?? this.props.devices[0];
  }

  componentDidMount() {
    if (this.isDevicesCarouselDashboardVisible()) {
      console.log("[DEVICE] this.props.devices", this.props.devices);

      console.log(
        "[CURRENT DEVICE] [DEVICE] [componentDidMount]",
        this.getCurrentDevice()
      );
      this.setState({
        currentDevice: this.getCurrentDevice(),
        devicesLockStatus: this.props.devices.map((device) => {
          return {
            serialNumber: device?.deviceInfo?.serialNumber,
            locked: device?.lockInfo?.locked,
          };
        }),
        pairTutorialAccepted:
          Commons.getCustomerProperty(
            thingVuseProperties.MULTI_DEV_TUT_ADD_ANOTHER
          ) === flagStatus.ACCEPTED,
        swipeTutorialAccepted:
          Commons.getCustomerProperty(
            thingVuseProperties.MULTI_DEV_TUT_SWIPE_SWITCH
          ) === flagStatus.ACCEPTED,
        rearrangeTutorialAccepted:
          Commons.getCustomerProperty(
            thingVuseProperties.MULTI_DEV_TUT_SELECT_REARRANGE
          ) === flagStatus.ACCEPTED,
      });
      triggerLandingOn(this.devicesCarouselDashboardViews);
    } else {
      logAnalyticsEventByCustomEvent(AnalyticsEvents.SCREEN_VIEW, {
        screen_name: AnalyticsScreenEventNames.HOME,
      });
    }

    window.document.addEventListener(
      "pairingStart",
      this.startLoadingConnection
    );
    window.document.addEventListener(
      "pairingSuccess",
      this.endLoadingConnection
    );
    window.document.addEventListener("pairingError", this.endLoadingConnection);
    window.document.addEventListener(
      "searchingError",
      this.endLoadingConnection
    );

    if(isSupportedService(servicesConstants.QUALTRICS)){
      this.manageQualtricsData();
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener(
      "pairingStart",
      this.startLoadingConnection
    );
    window.document.removeEventListener(
      "pairingSuccess",
      this.endLoadingConnection
    );
    window.document.removeEventListener(
      "pairingError",
      this.endLoadingConnection
    );
    window.document.removeEventListener(
      "searchingError",
      this.endLoadingConnection
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.isDevicesCarouselDashboardVisible() &&
      !_.isEqual(prevProps.devices, this.props.devices)
    ) {
      const { devices } = this.props;
      const { currentDevice } = this.state;

      const currentSelectedDevice = devices.find((device) => device.selected);
      const prevSelectedDevice = prevProps.devices.find(
        (device) => device.selected
      );

      if (
        !isNil(currentSelectedDevice) &&
        currentSelectedDevice?.serialNumber !== prevSelectedDevice?.serialNumber
      ) {
        console.log("[CURRENT DEVICE] [DASHBOARD]", currentSelectedDevice);
        this.setState({ currentDevice: { ...currentSelectedDevice } });
        // store.dispatch(deviceActions.removeSelected(currentSelectedDevice));
      } else {
        if (currentDevice) {
          // this.setState({
          //   currentDevice:
          //     devices.find(
          //       (_) =>
          //         _.deviceInfo.serialNumber ===
          //         currentDevice.deviceInfo.serialNumber
          //     ) ?? currentDevice.deviceInfo.serialNumber,
          // });
          this.setState({
            currentDevice:
              devices.find(
                (_) =>
                  _.deviceInfo.serialNumber ===
                  currentDevice.deviceInfo.serialNumber
              ) ?? currentDevice,
          });
        }
      }

      this.changeLockStatus();
    }

    /**
     * On change current device (current device on the attention of user)
     * we force the devices carousel to update to device's index
     */
    if (prevState.currentDevice !== this.state.currentDevice) {
      if (!isNil(this.state.currentDevice)) {
        const index = this.props.devices.findIndex(
          (_) =>
            _.deviceInfo.serialNumber ===
            this.state.currentDevice.deviceInfo.serialNumber
        );

        if (this.carouselRef?.current?.swiper) {
          this.carouselRef.current.swiper.slideTo(index);
        }
      }
    }
  }

  manageQualtricsData() {
    
    const userData = getUserData();
    const currentDate = moment(); 
      
    console.log("[Qualtrics] gender", userData?.gender === "0" ? "na" : userData?.gender);
    console.log("[Qualtrics] age_range", calcAgeRange(userData?.dateOfBirth));
    console.log("[Qualtrics] country",  getCountry());
    console.log("[Qualtrics] province", getProvince());
    console.log("[Qualtrics] os", 'ios');
    console.log("[Qualtrics] app_version", getTruncateAppVersion());
    console.log("[Qualtrics] createdOn",  getUserEverLogged());  
    if(!isNil(getUserEverLogged())){
      const daysDifference = currentDate.diff(moment(getUserEverLogged()), 'days');
      console.log("[Qualtrics] daysSinceRegistration",  daysDifference);      
    }

    if (window?.dataLayer?.length > 0) {
      window.dataLayer.push({ 'gender': userData?.gender === "0" ? "na" : userData?.gender });
      window.dataLayer.push({ 'age_range': calcAgeRange(userData?.dateOfBirth)});
      window.dataLayer.push({'country': getCountry()});
      window.dataLayer.push({'province': getProvince()});
      window.dataLayer.push({'os': 'ios'});
      window.dataLayer.push({'app_version': getTruncateAppVersion()});
      window.dataLayer.push({'timestamp': Date.now()});
      window.dataLayer.push({'createdOn': getUserEverLogged()});
      if(!isNil(getUserEverLogged())){
        const daysDifference = currentDate.diff(moment(getUserEverLogged()), 'days');
        window.dataLayer.push({'daysSinceRegistration': daysDifference});     
      }
    }
}

  changeLockStatus() {
    this.setState({
      devicesLockStatus: this.props.devices.map((device) => {
        return {
          serialNumber: device?.deviceInfo?.serialNumber,
          locked: device?.lockInfo?.locked,
        };
      }),
    });
  }

  isDeviceConnected(device) {
    const currentDevice = device || this.state.currentDevice;

    return (
      currentDevice &&
      (sdk[currentDevice.deviceType].ConnectionState.Synchronized ===
        currentDevice.connectionState ||
        sdk[currentDevice.deviceType].ConnectionState.Connected ===
          currentDevice.connectionState)
    );
  }

  isDevicesCarouselDashboardVisible() {
    return this.props.devices && this.props.devices.length > 0;
  }

  onClickPairANewDevice() {
    this.goToPairing();
  }

  onClickPairYourDevice() {
    logAnalyticsEvent(AnalyticsEvents.PAIR_YOUR_DEVICE);

    this.goToDeviceSelection();
  }

  goToPairing() {
    history.push(buildURI(routingConstants.PAIR_DEVICE));
  }
  
  goToDeviceSelection() {
    if (this.state.devices.length === 1){
      handleDeviceSelection(this.state.devices[0].profile, this.state.devices[0].label);
      history.push(buildURI(routingConstants.PAIR_DEVICE), {type: 'pop'});
    } else {
      history.push(buildURI(routingConstants.SELECT_DEVICE));
    }
  }

  onClickBuyDevice() {
    logAnalyticsEvent(AnalyticsEvents.BUY_A_DEVICE);

    this.buyDevice();
  }

  manageQualtricsFeature(feature) {
    if(isSupportedService(servicesConstants.QUALTRICS)){
        console.log("[Qualtrics] feature: ", feature);
        if (window?.dataLayer?.length > 0) {
          const obj = {};
          obj[feature]='true';
          window.dataLayer.push(obj);
        }
    }
}

  acceptTutorial(tutorialToAccept, thingVuseProperty) {
    !this.state[tutorialToAccept] &&
      Commons.acceptOptIn(thingVuseProperty).then(() => {
        this.setState({ [tutorialToAccept]: true });
      });
  }

  _buyDevice() {
    if (typeof this.props.navigateToDevices() === "function") {
      this.props.navigateToDevices();
    }
  }

  _goToFindMyVape() {
    const { currentDevice } = this.state;

    store.dispatch(
      deviceActions.setSelected(currentDevice, () => {
        const optedIn = Commons.getCustomerProperty(
          thingVuseProperties.OPT_IN_FINDMYVAPE
        );

        if (optedIn !== flagStatus.ACCEPTED) {
          history.push(buildURI(routingConstants.FIND_MY_VAPE_TUTORIAL));
        } else {
          //if user already opted in
          logAnalyticsEvent(AnalyticsEvents.FIND_MY_VAPE);

          this.manageQualtricsFeature("FIND_MY_VAPE");

          BrowserHelper.getLocationPermission().then(async (permission) => {
            if (PermissionsHelper.hasDeniedStatus(permission)) {
              await clearAllLastSyncedLocations();
              history.push({
                pathname: buildURI(routingConstants.FIND_MY_VAPE),
                state: {
                  isPermissionDenied: true,
                },
              });
            } else {
              history.push(buildURI(routingConstants.FIND_MY_VAPE));
            }
          });
        }
      })
    );
  }

  _goToDeviceSettings() {
    /**
     * Here we not call goToPageWithSelectedDevice method because user should be able
     * to access this app feature also if device is not connected
     */
    store.dispatch(
      deviceActions.setSelected(this.state.currentDevice, () => {
        history.push(buildURI(routingConstants.DEVICE_SETTINGS), {backTo: routingConstants.DASHBOARD});
      })
    );
  }

  _goToBatterySettings(device) {
    const optedIn = Commons.getCustomerProperty(
      thingVuseProperties.BATTERYSAVE
    );

    let destination = routingConstants.BATTERY_SETTINGS_TUTORIAL;

    if (optedIn === flagStatus.ACCEPTED) {
      // logAnalyticsEvent(AnalyticsEvents.BATTERY);
      logAnalyticsEventForDevice(device, AnalyticsEvents.BATTERY);
      destination = routingConstants.BATTERY_SETTINGS;
    }

    this.manageQualtricsFeature("BATTERY_SAVER");

    Commons.goToPageWithSelectedDevice(device, destination);
  }

  _goToUsageTracker() {
    if (isUsageTrackerEnabled()) {
      this. manageQualtricsFeature("USAGE_TRACKER");
      history.push(buildURI(routingConstants.USAGE_TRACKER));
    } else {
      history.push(buildURI(routingConstants.USAGE_TRACKER_TUTORIAL));
    }
  }

  _goToCloudControl(device) {
    const optedIn = Commons.getCustomerProperty(
      thingVuseProperties.CLOUDCONTROL
    );

    let destination = routingConstants.CLOUD_CONTROL_TUTORIAL;

    if (optedIn === flagStatus.ACCEPTED) {
      // logAnalyticsEvent(AnalyticsEvents.CLOUD_CONTROL);
      //logAnalyticsEventForDevice(device, AnalyticsEvents.CLOUD_CONTROL);
      destination = routingConstants.CLOUD_CONTROL;
    }

    this.manageQualtricsFeature("CLOUD_CONTROL");

    Commons.goToPageWithSelectedDevice(device, destination);
  }

  _handleChangeDevice(index) {
    const { devices } = this.props;
    console.log(
      "[CURRENT DEVICE] [DEVICE] [_handleChangeDevice]",
      devices[index]
    );

    this.setState({ currentDevice: devices[index] });
    store.dispatch(deviceActions.setSelected(devices[index]));
    triggerLandingOn(this.devicesCarouselDashboardViews, index + 1);
  }

  _unlockDevice(e) {
    const { currentDevice, devicesLockStatus } = this.state;
    // e.stopPropagation();

    getDeviceInstanceFromSN(currentDevice?.serialNumber).then(
      (deviceInstance) => {
        let deviceLockStatus = devicesLockStatus.find(
          (deviceInt) =>
            deviceInt.serialNumber === currentDevice.deviceInfo?.serialNumber
        );
        deviceLockStatus.locked = !deviceLockStatus.locked;

        const filteredDevicesLockStatus = devicesLockStatus.filter(
          (lockStatus) =>
            lockStatus.serialNumber !== deviceLockStatus.serialNumber
        );

        this.setState(
          {
            devicesLockStatus: [...filteredDevicesLockStatus, deviceLockStatus],
          },
          () => {
            if (
              null !== deviceInstance &&
              deviceInstance !== undefined &&
              currentDevice?.lockInfo?.locked != null &&
              currentDevice?.lockInfo?.locked !== undefined
            ) {
              let lockedObj = {
                locked: currentDevice?.lockInfo?.locked ? 0 : 1
              };

              if(deviceInstance.type === PROFILE_SMARTBOX){
                lockedObj = {
                  locked: currentDevice?.lockInfo?.locked ? 0 : 1,
                  lockMode: 0,
                  bleTimeout: 0,
                  autoLockOn: 0
                };
              }

              deviceInstance
                .setLockConfig(
                  lockedObj,
                  false
                )
                .catch(function (e) {
                  deviceLockStatus.locked = !deviceLockStatus.locked;
                  this.setState({
                    devicesLockStatus: [
                      ...filteredDevicesLockStatus,
                      deviceLockStatus,
                    ],
                  });
                });

              logAnalyticsEventForDevice(
                currentDevice,
                currentDevice?.lockInfo?.locked
                  ? AnalyticsEvents.DEVICE_UNLOCK
                  : AnalyticsEvents.DEVICE_LOCK,
                {
                  device_lock_status: `${
                    isNil(currentDevice?.lockInfo?.locked)
                      ? null
                      : currentDevice?.lockInfo?.locked
                      ? "unlocked"
                      : "locked"
                  }`,
                }
              );
            }
          }
        );
      }
    );
  }

  async _reconnectDevice(device) {
    let deviceInstance = await getDeviceInstanceFromSN(device.serialNumber);

    if (isNil(deviceInstance)) {
      deviceInstance = vuse.setDevice(device.deviceType);
    }

    deviceInstance.setConnectionStateChanged((connectionState) =>
      setDeviceConnectionStateChanged(connectionState, deviceInstance)
    );

    // await deviceInstance
    //   .connect(this.state.currentDevice?.deviceInfo?.deviceName)
    //   .catch((e) => {
    //     debug(`[Pairing] Connect Home error `, e);
    //   });

    await deviceInstance.connect().catch((e) => {
      debug(`[Pairing] Connect Home error `, e);
    });

    logAnalyticsEventForDevice(device, AnalyticsEvents.RECONNECT_MY_VAPE, {
      device_connected_status: "true",
    });
  }

  _startLoadingConnection() {
    this.setState({ loadingConnection: true });
  }

  _endLoadingConnection() {
    this.setState({ loadingConnection: false });
  }

  _handleDeviceSwitcherPanelClose() {
    const { devices } = this.props;
    const { currentDevice } = this.state;

    console.log("[_handleDeviceSwitcherPanelClose] [devices]", devices);
    console.log(
      "[_handleDeviceSwitcherPanelClose] [currentDevice]",
      currentDevice
    );

    let currentSelectedDevice = devices.find((device) => device.selected);
    if (isNil(currentSelectedDevice)) {
      currentSelectedDevice = devices[0];
    }

    console.log(
      "[_handleDeviceSwitcherPanelClose] [new currentDevice]",
      currentSelectedDevice
    );
    this.setState({ currentDevice: { ...currentSelectedDevice } }, () => {
      this.setState({ isDeviceSwitcherOpen: false }, () => {
        let index = devices.findIndex(
          (device) => device.serialNumber === currentDevice?.serialNumber
        );
        if (this.carouselRef?.current?.swiper) {
          if (index === -1) {
            this.carouselRef.current.swiper.slideTo(0);
          } else {
            this.carouselRef.current.swiper.slideTo(index);
          }
        }
      });
    });

    if (isNil(currentSelectedDevice)) {
      store.dispatch(deviceActions.removeSelected(currentDevice));
    } else {
      store.dispatch(deviceActions.setSelected(currentSelectedDevice));
    }
  }

  _renderNoDevicesDashboard() {
    const isDarkMarket = Tenants.isCanadaDark();

    return isDarkMarket ? (
      <div className={`dashboard-page-content d-flex h-100 flex-column mx-0`}>
        <PageSelectorCTA sticky={false} ctaUrl={true} className="mt-auto">
          <div className="dashboard-page-title">
            <h1>{this.dictionary.HOME_WELCOME_BANNER}</h1>
          </div>
          <div className="d-grid">
            <button
              className="btn btn-primary text-uppercase"
              onClick={this.onClickPairYourDevice.bind(this)}
            >
              {this.dictionary.HOME_BTN_PAIR_YOUR_DEVICE}
            </button>
          </div>
        </PageSelectorCTA>
      </div>
    ) : (
      <div
        className={`dashboard-page-content dashboard-video d-flex h-100 flex-column mx-0`}
      >
        <AppStories stories={this.stories} storiesHeight="100vh" />
        <PageSelectorCTA
          sticky={false}
          className="p-2"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: "82px",
            zIndex: "1000",
          }}
        >
          <div className="d-grid gap-2">
            <button
              className="btn btn-primary text-uppercase"
              onClick={this.onClickPairYourDevice.bind(this)}
            >
              {this.dictionary.HOME_BTN_PAIR_YOUR_DEVICE}
            </button>
          </div>
        </PageSelectorCTA>
      </div>
    );
  }

  _renderDevicesCarouseCTA() {
    const { devices } = this.props;

    const {
      pairTutorialAccepted,
      swipeTutorialAccepted,
      rearrangeTutorialAccepted,
    } = this.state;

    const customStyle = {
      width: "100%",
      background:
        "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 43.75%)",
      height: "13.938rem",
      justifyContent: "end",
      paddingBottom: "1.25rem",
      bottom: 0,
    };

    if (devices.length === 1) {
      return (
        <div
          className="dashboard-carousel-cta"
          {...(!pairTutorialAccepted && { style: customStyle })}
        >
          <TutorialTip
            show={!pairTutorialAccepted}
            content={this.dictionary.HOME_TAP_TO_ADD_ANOTHER_DEVICE_LABEL}
            onTimeout={() =>
              this.acceptTutorial(
                "pairTutorialAccepted",
                thingVuseProperties.MULTI_DEV_TUT_ADD_ANOTHER
              )
            }
          />
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => {
              logAnalyticsEvent(AnalyticsEvents.TAP_HERE_TO_ADD_ANOTHER_DEVICE);
              this.acceptTutorial(
                "pairTutorialAccepted",
                thingVuseProperties.MULTI_DEV_TUT_ADD_ANOTHER
              );
              this.goToDeviceSelection();
            }}
          >
            <i className="bat-icon-plus" />
          </button>
        </div>
      );
    }

    return (
      <div
        className="dashboard-carousel-cta"
        {...((!swipeTutorialAccepted || !rearrangeTutorialAccepted) && {
          style: customStyle,
        })}
      >
        <TutorialTip
          show={!swipeTutorialAccepted}
          content={this.dictionary.HOME_SWIPE_TO_SWITCH_DEVICE_LABEL}
          onTimeout={() =>
            this.acceptTutorial(
              "swipeTutorialAccepted",
              thingVuseProperties.MULTI_DEV_TUT_SWIPE_SWITCH
            )
          }
        />
        <TutorialTip
          show={swipeTutorialAccepted && !rearrangeTutorialAccepted}
          content={
            this.dictionary.HOME_TAP_TO_SELECT_REARRANGE_MANAGE_DEVICES_LABEL
          }
          onTimeout={() =>
            this.acceptTutorial(
              "rearrangeTutorialAccepted",
              thingVuseProperties.MULTI_DEV_TUT_SELECT_REARRANGE
            )
          }
        />
        <div
          className={cx("swiper-pagination", {
            "swiper-pagination-border": swipeTutorialAccepted,
          })}
          {...(swipeTutorialAccepted && {
            onClick: () => {
              logAnalyticsEvent(AnalyticsEvents.TAP_TO_REARRANGE_DEVICES);
              this.acceptTutorial(
                "rearrangeTutorialAccepted",
                thingVuseProperties.MULTI_DEV_TUT_SELECT_REARRANGE
              );
              this.setState({
                rearrangeTutorialAccepted: true,
                isDeviceSwitcherOpen: true,
              });
            },
          })}
        />
      </div>
    );
  }

  _renderDevicesCarouselDashboard() {
    const {
      isOpen,
      currentDevice,
      isDeviceSwitcherOpen,
      swipeTutorialAccepted,
    } = this.state;
    const isUsageTrackerSupported = isSupportedService(
      servicesConstants.USAGE_TRACKER
    );

    let panelClassName = Tenants.isCanadaDark()
      ? "market-dark"
      : Tenants.isCanadaLight() || Tenants.isDemo()
      ? "market-light"
      : Tenants.isGlobal()
      ? "market-global"
      : "";

    panelClassName += isUsageTrackerSupported ? "" : " is-fixed";

    panelClassName += isDeviceSwitcherOpen ? "" : " homepage-panel";
    //console.log("[CURRENT DEVICE] [DEVICE] [_renderDevicesCarouselDashboard]", currentDevice);

    // const panelBackgroundImage = currentDevice
    //   ? `HOMEPAGE_PANEL_${currentDevice?.deviceColor}_${
    //       Tenants.isCanadaDark() ? "DARK" : "GLOBAL"
    //     }` //custom background for device color
    //   : `HOMEPAGE_PAIRING_BACKGROUND`; //last slide background

    const panelBackgroundImage =
      getDeviceHomeScreenImg(currentDevice) ||
      imagesConstants.HOMEPAGE_PAIRING_BACKGROUND;

    // const additionalStyles = {
    //   backgroundPosition: Tenants.isCanadaDark()
    //     ? currentDevice
    //       ? "left top"
    //       : "left bottom"
    //     : currentDevice
    //     ? "right bottom"
    //     : "left bottom",
    //   zIndex: 1053,
    // };

    const additionalStyles = {
      backgroundPosition: Tenants.isCanadaDark()
        ? "left top"
        : "right -50px top",
      zIndex: 1053,
    };

    return !isDeviceSwitcherOpen ? (
      <DevicePanel
        // isOpen={isOpen}
        title={this.dictionary.HOME_LABEL_MYDEVICES}
        // onClose={() => this.setState({ isOpen: !isOpen })}
        className={panelClassName}
        additionalStyles={additionalStyles}
        backgroundImg={panelBackgroundImage}
      >
        <Swiper
          ref={this.carouselRef}
          loop={false}
          effect={"fade"}
          pagination={{ clickable: false, el: ".swiper-pagination" }}
          centeredSlides={true}
          initialSlide={0}
          speed={300}
          runCallbacksOnInit={false} //not fire onSlideChange when initialSlide <> 0
          onSlideChange={(e) => {
            logAnalyticsEvent(AnalyticsEvents.SWIPE_TO_SWITCH_DEVICE);
            this.acceptTutorial(
              "swipeTutorialAccepted",
              thingVuseProperties.MULTI_DEV_TUT_SWIPE_SWITCH
            );
            this.handleChangeDevice(e.realIndex);
          }}
        >
          {this._renderDevicesCarouseCTA()}
          {renderAllSteps(this.devicesCarouselDashboardViews)}
        </Swiper>
      </DevicePanel>
    ) : (
      <DeviceSwitcher
        customClass={panelClassName}
        title={this.dictionary.HOME_LABEL_MYDEVICES}
        currentDevice={this.state.currentDevice}
        onClose={this.handleDeviceSwitcherPanelClose}
      />
    );
  }

  get devicesCarouselDashboardViews() {
    const { devicesLockStatus } = this.state;
    const { devices } = this.props;

    const views = devices.map((device, idx) => {
      if (device) {
        const deviceProfile =
          device?.deviceType === PROFILE_EPOD2
            ? PROFILE_WAWE2
            : device?.deviceType;
        const deviceSpec = getDeviceSpec(deviceProfile);

        let isConnected = this.isDeviceConnected();
        let batteryPerc = device?.batteryInfo?.chargeLevel ?? 0;
        let cloudPower = isLowPowerModeEnabledBy(device, deviceSpec)
          ? parseInt(deviceSpec?.lowPower?.value, 10)
          : device?.cloudInfo?.powerLevel ?? 10;
        let cloudWatt = cloudPower / 10;

        let isLocked =
          devicesLockStatus.find(
            (deviceInt) =>
              deviceInt.serialNumber === device.deviceInfo?.serialNumber
          )?.locked ?? device?.lockInfo?.locked;

        // console.log("[DEVICE] isConnected", isConnected);
        // console.log("[DEVICE] isLocked", isLocked);
        // console.log("[DEVICE] batteryPerc", batteryPerc);
        // console.log("[DEVICE] cloudPower", cloudPower);
        // console.log("[DEVICE] cloudWatt", cloudWatt);

        let batteryColor = Utils.getBatteryCircleColorFromPercentage(
          device?.batteryInfo,
          cloudPower
        );
        let batteryIcon = Utils.getBatteryIconFromPercentage(
          device?.batteryInfo,
          cloudPower
        );

        return {
          onLanding: () => {
            logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
              screen_name: AnalyticsScreenEventNames.MY_DEVICE,
            });
          },
          render: () => (
            <SwiperSlide className="swiper-slide" key={idx}>
              <div className="dashboard-carousel-epod h-100 d-flex flex-column justify-content-between">
                <div className="align-self-start">
                  <h2>{device.deviceCustomName}</h2>
                  <div
                    className={`dashboard-carousel-epod-status ${
                      isConnected ? `connected` : `disconnected`
                    } me-auto`}
                  >
                    <span></span>
                    {isConnected
                      ? this.dictionary.HOME_LABEL_CONNECTED
                      : this.dictionary.HOME_LABEL_DISCONNECTED}
                  </div>
                  {!this.isDeviceConnected() && (
                    <button
                      type="button"
                      className="dashboard-carousel-epod-reconnect btn btn-primary text-dark text-uppercase"
                      onClick={() =>
                        this.reconnectDevice(this.state.currentDevice)
                      }
                    >
                      {this.dictionary.HOME_BTN_RECONNECT_0_ctaLabel}
                    </button>
                  )}
                </div>
                <div
                  className={`dashboard-carousel-epod-values h-100 d-flex flex-column justify-content-around`}
                >
                  <div
                    className={`dashboard-carousel-epod-values-item d-flex align-items-center ${
                      !isConnected ? `disabled` : ``
                    }`}
                    onClick={() => this.goToBatterySettings(device)}
                  >
                    <div className="progress-circle-dashboard">
                      <span className={`${batteryIcon}`}></span>
                      <div className="percent">
                        <svg>
                          <circle
                            cx="21"
                            cy="21"
                            r="21"
                            className="circle-first"
                          ></circle>
                          <circle
                            cx="21"
                            cy="21"
                            r="21"
                            className="circle-second"
                            style={{
                              strokeDashoffset: `${
                                132 - (132 * batteryPerc) / 100
                              }`,
                              stroke: batteryColor,
                            }}
                          ></circle>
                        </svg>
                      </div>
                    </div>
                    <div className="dashboard-carousel-epod-values-item-label">
                      {isConnected ? batteryPerc : "--"}%
                    </div>
                  </div>

                  {isSupportedService(servicesConstants.USAGE_TRACKER) && (
                    <div
                      className="dashboard-carousel-epod-values-item d-flex align-items-center"
                      onClick={this.goToUsageTracker}
                    >
                      <div className="dashboard-carousel-epod-values-item-icon">
                        <span className="bat-icon-usage" />
                      </div>
                      <div className="dashboard-carousel-epod-values-item-label">
                        <span>
                          {this.dictionary.HOME_LABEL_USAGE_TRACKER ||
                            "USAGE TRACKER"}
                        </span>
                      </div>
                    </div>
                  )}

                  <div
                    className={`dashboard-carousel-epod-values-item d-flex align-items-center  ${
                      !isConnected ? `disabled` : ``
                    }`}
                    onClick={() => this.goToCloudControl(device)}
                  >
                    <div className="dashboard-carousel-epod-values-item-icon">
                      <span className="bat-icon-puff"></span>
                    </div>
                    <div className="dashboard-carousel-epod-values-item-label">
                      <span>{isConnected && `${cloudWatt}W`}</span>
                      {isConnected ? (
                        this.dictionary[
                          Utils.getLabelsFromCloudControlWattage(
                            cloudWatt,
                            device
                          ).labelHome
                        ]
                      ) : (
                        <span>{this.dictionary.HOME_LABEL_UNAVAILABLE}</span>
                      )}
                    </div>
                  </div>
                  <div
                    className={`dashboard-carousel-epod-values-item d-flex align-items-center`}
                    onClick={this.goToFindMyVape}
                  >
                    <div className="dashboard-carousel-epod-values-item-icon">
                      <span className="bat-icon-find-my-vape"></span>
                    </div>
                    <div className="dashboard-carousel-epod-values-item-label">
                      <span>{this.dictionary.HOME_LABEL_FMV}</span>
                    </div>
                  </div>
                  <div
                    className={`dashboard-carousel-epod-values-item d-flex align-items-center  ${
                      !isConnected ? `disabled` : ``
                    }`}
                  >
                    <Switch
                      onChange={this.unlockDevice}
                      checked={isLocked}
                      className={`react-switch home ${
                        !isLocked ? "checked" : ""
                      }`}
                      checkedIcon={
                        <span
                          style={{
                            marginLeft: "-24px",
                            color: "#ffffff40",
                          }}
                          className="bat-icon-unlock-outline"
                        ></span>
                      }
                      uncheckedHandleIcon={
                        <span
                          style={{ opacity: 1 }}
                          className="bat-icon-unlock-outline"
                        ></span>
                      }
                      checkedHandleIcon={
                        <span
                          style={{ opacity: 1 }}
                          className="bat-icon-lock-outline"
                        ></span>
                      }
                      uncheckedIcon={
                        <span
                          style={{
                            marginRight: "-24px",
                            color: "#ffffff40",
                          }}
                          className="bat-icon-lock-outline"
                        ></span>
                      }
                      handleDiameter={45}
                      disabled={!isConnected}
                      height={45}
                      width={115}
                    />
                    {/*<div className="unlock-switch-outline unlock-to-lock">
                                            <input type="checkbox" name="unlockDevice"
                                                checked={isLocked} disabled={!isConnected}
                                                onChange={this.unlockDevice} />
                                            <label htmlFor="unlockDevice"><i></i></label>
                                        </div>*/}
                  </div>
                </div>

                {/* {!isConnected && (
                  <div className="dashboard-carousel-epod-reconnect">
                    <div className="d-grid">
                      <button
                        className="btn btn-primary text-dark text-uppercase"
                        type="button"
                        onClick={() => this.reconnectDevice(device)}
                      >
                        {this.dictionary.HOME_BTN_RECONNECT_0_ctaLabel}
                      </button>
                    </div>
                  </div>
                )} */}
              </div>
            </SwiperSlide>
          ),
        };
      } else {
        return <></>;
      }
    });

    return views;
  }

  _renderFirmwareUpdate() {
    const { currentDevice } = this.state;

    if (currentDevice?.ongoingUpdate) {
      return <FirmwareUpdate show={true} resumeUpdate={true} />;
    }
  }

  render() {
    const { areShownTabs } = this.props;
    const { loadingConnection } = this.state;

    let dashboardClassName = Tenants.isCanadaDark()
      ? "dashboard-dark"
      : "dashboard-light";
    dashboardClassName += areShownTabs ? " with-tabs" : " no-tabs";

    return (
      <div className={`dashboard-page ${dashboardClassName}`}>
        {/* {loadingConnection && (
          <div className="connecting-overlay">
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )} */}
        <ReconnectLoader />
        {this.isDevicesCarouselDashboardVisible() ? (
          <React.Fragment>
            {this.renderDevicesCarouselDashboard()}
            {this.renderFirmwareUpdate()}
          </React.Fragment>
        ) : (
          this.renderNoDevicesDashboard()
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    deviceReducer: { devices, loading, error },
  } = state;

  return {
    devices,
    loading,
    error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addOrUpdateDevice: (devicePayload, addOnTop, callBack) => {
      dispatch(
        deviceActions.addOrUpdateDevice(
          devicePayload.toObj(),
          addOnTop,
          callBack
        )
      );
    },
  };
}

const connnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Device);
export { connnectedComponent as Device };
