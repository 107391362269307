import React, { useEffect, useState } from "react";
import { FeatureTutorial } from "../../_components";
import { useSelector } from "react-redux";
import {
    aemPages,
    AnalyticsEvents,
    AnalyticsTutorialNames,
    thingVuseProperties,
    routingConstants,
    PROFILE_SMARTBOX,
} from '../../_constants';
import {
    AEMHelper,
    propertyHeadingDefaultEmpty
} from "../../_helpers/aem/aemhelper";
import { isPodTrackerEnabled } from "../../_helpers/pod-tracker";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { dashboardActions } from "../../_actions";

export const PodTrackerTutorial = () => {

    const [dictionary, setDictionary] = useState({});
    const [stories, setStories] = useState([]);

    const podTrackerTutorial = useSelector(state => state.dashboardReducer.tutorials.podTracker);

    useEffect(() => {

        logAnalyticsEvent(AnalyticsEvents.TUTORIAL, {tutorial_name: AnalyticsTutorialNames.POD_TRACKER});

        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.POD_TRACKER_TUTORIAL_SMARTBOX, {
              POD_TRACKER_TUTORIAL_HEADER: propertyHeadingDefaultEmpty,
            })
        );

        setStories(
            aem.getCarouselUrlsFor(PROFILE_SMARTBOX, "POD_TRACKER_TUTORIAL")
            .map((url) => ({
                url,
                type: "video",
                muted: true
            }))
        );

    }, []);

    return(
        <FeatureTutorial
            title = {dictionary.POD_TRACKER_TUTORIAL_HEADER}
            featureIcon = {""}
            stories = {stories}
            iconAction = {null}
            backAction = {
                isPodTrackerEnabled()
                ? routingConstants.POD_TRACKER
                : routingConstants.POD_TRACKER_OPTIN
            }
            doneTutorialMethod={dashboardActions.setDoneTutorialPodTracker}
            doneTutorialValue={podTrackerTutorial}
            thing={thingVuseProperties.POD_TRACKER}
            tutorialName={AnalyticsTutorialNames.POD_TRACKER}
        />
    )

};