import cx from "classnames";
import { isString } from "lodash";
import { bool, func, node, object, oneOfType, string } from "prop-types";
import React from "react";
import { imagesConstants } from "../../_constants";

const FeatureWidget = ({
  className,
  style,
  disabled,
  isSupported = true,
  full = false,
  icon,
  label,
  onClick,
}) => {
  if (!isSupported) {
    return null;
  }

  return (
    <div
      style={style}
      className={cx(
        `d-flex align-items-center justify-content-center text-center feature-widget`,
        { full, disabled },
        className
      )}
      onClick={onClick}
    >
      <div className="d-flex flex-column align-items-center w-100">
        {isString(icon) ? (
          <img className="feature-widget-icon" src={imagesConstants[icon]} />
        ) : (
          <span
            className={cx("feature-widget-icon", {
              "mb-0": !label,
            })}
          >
            {icon}
          </span>
        )}
        {label && <span className="feature-widget-label">{label}</span>}
      </div>
    </div>
  );
};

FeatureWidget.propTypes = {
  className: string,
  style: object,
  disabled: bool,
  isSupported: bool,
  full: bool,
  icon: oneOfType([string, node]),
  label: string,
  onClick: func,
};

export { FeatureWidget };
