import { vuse } from "@nodes-projects/bat-sdk-web";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  FW_UPDATE_ERRORS,
  FW_UPDATE_ERRORS_CODES,
  FW_UPDATE_STEPS,
  VUSE_DEVICE,
  aemPages,
  imagesConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent, logAnalyticsEventForDevice } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import {
  getDeviceInstanceFromSN,
  getDeviceThingFromSN,
  setDeviceConnectionStateChanged,
  updateDeviceInReducer,
} from "../../_helpers/device";
import { dashboardServices } from "../../_services";
import { DataProcessPanel } from "../DataProcessPanel";
import { FirmwareUpdateProvider } from "./FirmwareUpdateContext";
import { FirmwareUpdateFailure } from "./FirmwareUpdateFailure";
import { FirmwareUpdateIntro } from "./FirmwareUpdateIntro";
import { FirmwareUpdateStart } from "./FirmwareUpdateStart";
import { FirmwareUpdateSuccess } from "./FirmwareUpdateSuccess";
import cx from "classnames";

function FirmwareUpdate({ show, onClose, device, userPin, resumeUpdate, fromCTA = false }) {
  const dictionary = useRef({});
  const [isOpen, setIsOpen] = useState(show);
  const [wizardInstance, setWizardInstance] = useState(null);
  const [title, setTitle] = useState("");
  const [error, setError] = useState(null);
  const [firmwareData, setFirmwareData] = useState(null);
  const [deviceInstance, setDeviceInstance] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const canRetry = useRef(true);
  const [updateCompleted, setUpdateCompleted] = useState(false)

  const reconnectDevice = async () => {
    const deviceInstance = vuse.setDevice(device?.deviceType);

    setDeviceInstance(deviceInstance);

    deviceInstance.setConnectionStateChanged((connectionState) =>
      setDeviceConnectionStateChanged(connectionState, deviceInstance)
    );

    try {
      await deviceInstance.connect();
      setError(null);
      setRetryCount(0);
      wizardInstance.goToNamedStep(FW_UPDATE_STEPS.START);
    } catch (err) {
      console.debug(`[FW_UPDATE] - BLE LINK LOSS reconnect device error`, err);
    }
  };

  const handleClose = (isComplete) => {
    setRetryCount(0);
    setIsOpen(false);
    updateDeviceInReducer(device?.serialNumber, { ongoingUpdate: false, updatable: false });
    setUpdateCompleted(isComplete)
  };

  const doRetry = () => {
    logAnalyticsEventForDevice(device, AnalyticsEvents.RETRY_FIRMWARE_UPDATE, {
      Latest_version: firmwareData?.latestFirmware,
    });
    setError(null);
    setRetryCount(retryCount + 1);
    retryCount + 1 > 2 && (canRetry.current = false);
    wizardInstance && wizardInstance.goToNamedStep(FW_UPDATE_STEPS.START);
  };

  const handleRetry = () => {
    if (error.errorCode === FW_UPDATE_ERRORS_CODES.BLE_LINK_LOSS_B) {
      console.debug("[FW_UPDATE] - RECONNECT", error.errorCode);
      reconnectDevice();
    } else {
      doRetry();
    }
  };

  const handleBinaryNotFoundError = () => {
    console.debug("[FW_UPDATE] - BINARY_NOT_FOUND_ERROR");
    setTitle(dictionary.current.FW_UPDATE_FAIL_HEADER);
    setError({
      content: dictionary.current.FW_UPDATE_FOTA_MSG_ELEVEN_TWELVE_CONTENT,
      alert: dictionary.current.FW_UPDATE_FOTA_MSG_ELEVEN_TWELVE_INFO,
      errorCode: FW_UPDATE_ERRORS_CODES.S11,
      exit: true,
    });
  };

  const handleLowBatteryError = () => {
    console.debug("[FW_UPDATE] - LOW_BATTERY_ERROR");
    setTitle(dictionary.current.FW_UPDATE_FOTA_ATTENTION_HEADER);
    setError({
      icon: imagesConstants.FW_UPDATE_LOW_BATTERY_ERROR,
      content: dictionary.current.FW_UPDATE_FOTA_MSG_ONE_CONTENT,
      alert: dictionary.current.FW_UPDATE_FOTA_MSG_ONE_INFO,
      errorCode: FW_UPDATE_ERRORS_CODES.LOW_BATTERY,
      exit: true,
    });
  };

  const handleFWUpdateError = (event) => {
    console.debug("[FW_UPDATE]", event.detail);
    setTitle(dictionary.current.FW_UPDATE_FOTA_ATTENTION_HEADER);

    switch (event.detail) {
      case FW_UPDATE_ERRORS.BLE_LINK_LOSS:
        console.debug("[FW_UPDATE] - BLE_LINK_LOSS_ERROR");
        setError({
          icon: imagesConstants.FW_UPDATE_LINK_LOSS_ERROR,
          content: dictionary.current.FW_UPDATE_FOTA_MSG_FIVE_CONTENT,
          alert: dictionary.current.FW_UPDATE_FOTA_MSG_FIVE_INFO,
          errorCode: FW_UPDATE_ERRORS_CODES.BLE_LINK_LOSS,
        });
        break;
      case FW_UPDATE_ERRORS.NOT_ENOUGH_MEMORY:
        console.debug("[FW_UPDATE] - NOT_ENOUGH_MEMORY_ERROR", dictionary);
        setError({
          content: dictionary.current.FW_UPDATE_FOTA_MSG_TWO_THREE_CONTENT,
          alert: dictionary.current.FW_UPDATE_FOTA_MSG_TWO_THREE_INFO,
          errorCode: FW_UPDATE_ERRORS_CODES.NOT_ENOUGH_MEMORY,
          exit: true,
        });
        break;
      case FW_UPDATE_ERRORS.PRE_VALIDATION:
        console.debug("[FW_UPDATE] - PRE_VALIDATION_ERROR");
        setError({
          content: dictionary.current.FW_UPDATE_FOTA_MSG_TWO_THREE_CONTENT,
          alert: dictionary.current.FW_UPDATE_FOTA_MSG_SIX_EIGHT_INFO,
          errorCode: FW_UPDATE_ERRORS_CODES.PRE_VALIDATION,
        });
        break;
      case FW_UPDATE_ERRORS.POST_VALIDATION:
        console.debug("[FW_UPDATE] - POST_VALIDATION_ERROR");
        setError({
          content: dictionary.current.FW_UPDATE_FOTA_MSG_FOUR_ERROR_CONTENT,
          alert: dictionary.current.FW_UPDATE_FOTA_MSG_TWO_THREE_INFO,
          errorCode: FW_UPDATE_ERRORS_CODES.POST_VALIDATION,
          contactSupport: true,
        });
        break;
      case FW_UPDATE_ERRORS.DEVICE_DISCONNECTED:
        console.debug("[FW_UPDATE] - DEVICE_DISCONNECTED");
        setError({
          content: dictionary.current.FW_UPDATE_FOTA_MSG_ELEVEN_TWELVE_CONTENT,
          alert: dictionary.current.FW_UPDATE_FOTA_MSG_ELEVEN_TWELVE_INFO,
          errorCode: FW_UPDATE_ERRORS_CODES.BLE_LINK_LOSS_B,
        });
        break;
      default:
        console.debug("[FW_UPDATE] - GENERIC_ERROR");
        console.debug("[FW_UPDATE] - CAN RETRY", canRetry.current);
        if (device.dfuModeOn) {
          setError({
            content: !canRetry.current
              ? dictionary.current.FW_UPDATE_FOTA_MSG_TWO_THREE_CONTENT
              : dictionary.current.FW_UPDATE_FOTA_MSG_ELEVEN_TWELVE_CONTENT,
            alert: !canRetry.current
              ? dictionary.current.FW_UPDATE_FOTA_MSG_TWO_THREE_INFO
              : dictionary.current.FW_UPDATE_FOTA_MSG_SIX_EIGHT_INFO,
          });
        } else {
          setError({
            content:
              dictionary.current.FW_UPDATE_FOTA_MSG_ELEVEN_TWELVE_CONTENT,
            alert: !canRetry.current
              ? dictionary.current.FW_UPDATE_FOTA_MSG_ELEVEN_TWELVE_INFO
              : dictionary.current.FW_UPDATE_FOTA_MSG_SIX_EIGHT_INFO,
          });
        }
    }
  };

  useEffect(() => {
    console.debug("[FW_UPDATE] - Mounted", device);

    const getFirmwareData = async () => {
      const tenantUserId = Commons.generateTenantUserId(userPin);
      const uuid = getDeviceThingFromSN(device?.serialNumber)?.uuid;

      try {
        const res = await dashboardServices.getFirmwareProperties(
          tenantUserId,
          uuid,
          VUSE_DEVICE
        );

        setFirmwareData(res?.data?.data[0] || null);
      } catch (err) {
        console.debug(err);
      }
    };

    const getDeviceInstance = async () => {
      let instance = await getDeviceInstanceFromSN(device?.serialNumber);

      if (!instance && device) {
        instance = vuse.setDevice(device.deviceType);
      }

      setDeviceInstance(instance);
    };

    getFirmwareData();
    getDeviceInstance();

    const aem = new AEMHelper();

    dictionary.current = aem.getDictionary(aemPages.FW_UPDATE, {
      // INTRO
      FW_UPDATE_HEADER: propertyHeadingDefaultEmpty,
      FW_UPDATE_FOTA_UPDATE_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_UPDATE_INFO: propertyTextDefaultEmpty,
      FW_UPDATE_VERS: propertyTextDefaultEmptyParsed,
      FW_UPDATE_FOTA_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
      FW_UPDATE_SKIP_LINK: propertyTextDefaultEmpty,
      // START
      FW_UPDATE_FOTA_ATTENTION_HEADER: propertyHeadingDefaultEmpty,
      FW_UPDATE_FOTA_START_UPDATE_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_START_UPDATE_INFO: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_BTN_START_UPDATE: propertyCtaItemsDefaultEmpty,
      FW_UPDATE_FOTA_STEP_TITLE: propertyTextDefaultEmptyParsed,
      FW_UPDATE_FOTA_STEP_ONE: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_STEP_TWO: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_STEP_THREE: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_STEP_END: propertyTextDefaultEmpty,
      // SUCCESS
      FW_UPDATE_COMPLETE_HEADER: propertyHeadingDefaultEmpty,
      FW_UPDATE_COMPLETE_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_BTN_FINISH: propertyCtaItemsDefaultEmpty,
      // FAILURE
      FW_UPDATE_FAIL_HEADER: propertyHeadingDefaultEmpty,
      FW_UPDATE_FAIL_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_ONE_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_ONE_INFO: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_TWO_THREE_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_TWO_THREE_INFO: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_FOUR_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_FOUR_MAIL_INFO: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_FOUR_PHONE_INFO: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_FIVE_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_FIVE_INFO: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_SIX_EIGHT_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_SIX_EIGHT_INFO: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_SEVEN_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_SEVEN_INFO: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_ELEVEN_TWELVE_CONTENT: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_MSG_ELEVEN_TWELVE_INFO: propertyTextDefaultEmpty,
      FW_UPDATE_FOTA_DFU_MSG: propertyTextDefaultEmpty,
      FW_UPDATE_BTN_RETRY: propertyCtaItemsDefaultEmpty,
      FW_UPDATE_FOTA_BTN_CLOSE: propertyCtaItemsDefaultEmpty,
    });

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.FIRMWARE_UPDATE,
    });

    window.addEventListener("fwerror", handleFWUpdateError);

    return () => {
      console.debug("[FW_UPDATE] - Unmount");
      window.removeEventListener("fwerror", handleFWUpdateError);
    };
  }, []);

  useEffect(() => {
    if (resumeUpdate) {
      setTitle(dictionary.current.FW_UPDATE_FOTA_ATTENTION_HEADER);
      setError({
        content: dictionary.current.FW_UPDATE_FOTA_MSG_SIX_EIGHT_CONTENT,
        alert: dictionary.current.FW_UPDATE_FOTA_MSG_SIX_EIGHT_INFO,
      });
    }
  }, [resumeUpdate]);

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  useEffect(() => {
    if (!isOpen) {
      onClose && onClose(updateCompleted);
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    if (!wizardInstance) {
      return;
    }

    if (error?.errorCode === FW_UPDATE_ERRORS_CODES.BLE_LINK_LOSS_B) {
      const deviceInstanceIndex = window.deviceList?.findIndex(
        (instance) => instance.serialNumber === device.serialNumber
      );

      if (deviceInstanceIndex !== -1) {
        window.deviceList.splice(deviceInstanceIndex, 1);
      }
    }

    error && wizardInstance.goToNamedStep(FW_UPDATE_STEPS.FAILURE);
  }, [error, wizardInstance]);

  return (
    <FirmwareUpdateProvider
      value={{
        dictionary: dictionary.current,
        device,
        deviceInstance,
        firmwareData,
        retryCount,
        setTitle,
        setDeviceInstance,
        handleDoThisLater: () => {
          logAnalyticsEventForDevice(device, AnalyticsEvents.DOWNLOAD_LATER_FIRMWARE_UPDATE, {
            Latest_version: firmwareData?.latestFirmware,
          });
          updateDeviceInReducer(device?.serialNumber, { ongoingUpdate: false });
          setIsOpen(false);
        },
        updateDevice: (props) =>
          updateDeviceInReducer(device?.serialNumber, props),
        handleLowBatteryError,
        handleBinaryNotFoundError,
        close: (isComplete) => handleClose(isComplete),
        retry: () => handleRetry(),
      }}
    >
      <DataProcessPanel
        className={cx(
          "full-height fw-update-panel",
          {'from-cta': fromCTA}
        )}
        overlayClassName="sliding-common-wrapper"
        isOpen={isOpen}
        title={title || dictionary.current.FW_UPDATE_HEADER}
        onWizardLoaded={(wizardInstance) => setWizardInstance(wizardInstance)}
        steps={[
          <FirmwareUpdateIntro
            key="fw-update-step-intro"
            stepName={FW_UPDATE_STEPS.INTRO}
          />,
          <FirmwareUpdateStart
            key="fw-update-step-download"
            stepName={FW_UPDATE_STEPS.START}
          />,
          <FirmwareUpdateSuccess
            key="fw-update-step-success"
            stepName={FW_UPDATE_STEPS.SUCCESS}
            fromDashboard={fromCTA}
          />,
          <FirmwareUpdateFailure
            key="fw-update-step-failure"
            stepName={FW_UPDATE_STEPS.FAILURE}
            {...error}
          />,
        ]}
      />
    </FirmwareUpdateProvider>
  );
}

const mapStateToProps = (state) => ({
  device: state?.deviceReducer?.devices.find(
    (device) => device.selected || device.ongoingUpdate
  ),
  userPin: state?.onboardingReducer?.userPin,
});

const connectedComponent = connect(mapStateToProps)(FirmwareUpdate);
export { connectedComponent as FirmwareUpdate };
