import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationOptions, OnboardingHeader, PageSelectorCTA } from '../../_components';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { AEMHelper } from '../../_helpers/aem/aemhelper';
import _ from 'lodash';
import { Commons } from '../../_helpers/commons';
import { commonsActions, getAccessToken, getVuseUuid, setDoneNotificationPreferences, userActions } from '../../_actions';
import { routingConstants, aemPages, flagStatus, CUSTOMER, thingVuseProperties, AnalyticsEvents } from '../../_constants';
import { store } from '../../_store';
import { logThingsInAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { CustomLoadingButton } from '../../_components/CustomLoadingButton';
import { Notifications } from '../../_helpers/notifications';
import { ThingsHelper } from '../../_helpers/things';
import { NotificationPermissionHandler } from '../../_components/NotificationPermissionPanels/NotificationPermissionHandler';

export const NotificationPreferencesOptions = (props) => {

    const [needToAskUserForNotificationPermission, setNeedToAskUserForNotificationPermission] = useState(false);
    const [options, setOptions] = useState({});
    const [preferences, setPreferences] = useState([]);
    const [loading, setLoading] = useState(false);

    const aem = new AEMHelper();
    const page = aem.getPage(aemPages.ONBOARD_NOTIF_DETAIL);
    const notificationPrefPage = aem.getPage(aemPages.ONBOARD_NOTIF_PREF);

    const userPin = useSelector(state => state.onboardingReducer.userPin);
    const loadingReducer = useSelector(state => state.commonsReducer.loading);
    const dispatch = useDispatch();

    let NOTIF_PREF_HEADER = notificationPrefPage?.getComponent(aemPages.ONBOARD_NOTIF_PREF, 'NOTIF_PREF_HEADER');
    let NOTIF_SAVE_BTN_CHOOSE = page?.getComponent(aemPages.ONBOARD_NOTIF_DETAIL, 'NOTIF_SAVE_BTN_CHOOSE');

    // component did mount
    useEffect(() => {
        const availableOptions = Notifications.getAvailableNotificationsPrefsForCurrentOS();
        setOptions(_.groupBy(availableOptions, "type") ?? {});
        setPreferences(availableOptions.map(option => { return { type: option.command, data: flagStatus.REFUSED }; }));
    }, []);

    const updatePreference = (type, accepted) => {
        //remove from preferences the preference with current type
        //then insert it with new value
        const filteredPreferences = preferences.filter((preference) => preference.type !== type);
        setPreferences([
            ...filteredPreferences,
            { type, data: accepted ? flagStatus.ACCEPTED : flagStatus.REFUSED }
        ]);
    };

    const handleSavePreferences = () => {
        setLoading(true);
        if (ThingsHelper.areAcceptedAnyPushNotificationThings(preferences)) {
            askUserTheNotificationPermission();
        } else {
            updateAndSavePreferences();
        }
    };

    const askUserTheNotificationPermission = () => {
        setNeedToAskUserForNotificationPermission(true);
    };

    const afterAskingTheUserForNotificationPermissionCallback = () => {
        setNeedToAskUserForNotificationPermission(false);
    };

    const updateAndSavePreferences = () => {
        return savePreferences(preferences);
    };

    const resetAndSavePreferences = () => {
        const resetPreferences = preferences.map(thing => {
            if (ThingsHelper.isAPushNotificationThing(thing)) {
                return {
                    ...thing,
                    data: flagStatus.REFUSED
                };
            } else {
                return thing;
            }
        });

        dispatch(userActions.updateUserInfo({
            userOptedInForPushNotifications: false
        }));

        return savePreferences(resetPreferences);
    };

    const savePreferences = (preferences) => {

        const things = ThingsHelper.distinctPush(preferences, { type: thingVuseProperties.MANAGENOTIFICATION, data: flagStatus.ACCEPTED });
        const tenantUserId = Commons.generateTenantUserId(userPin);
        
        return new Promise((resolve, reject) => {
            dispatch(commonsActions.setThings(
                things,
                tenantUserId,
                getVuseUuid(),
                CUSTOMER,
                resolve,
                reject,
                true
            ))
        }).then((response) => {
                if (response?.result?.code === 0) {
                    //set flag for navigation purpose
                    setDoneNotificationPreferences(true);

                    setPreferences(preferences);

                    //analytics 
                    logThingsInAnalyticsEvent(
                        AnalyticsEvents.SAVE_NOTIFICATION_PREFERENCES,
                        getAvailablePreferences(things)
                    )

                    //hanlde SUGGESTION notifications
                    const suggestionsThing = things.find(thing => thing.type === thingVuseProperties.SUGGESTIONS);
                    if (suggestionsThing?.data === flagStatus.ACCEPTED) {
                        Notifications.handleSuggestionNotifications();
                    }

                    //handle navitaion vs homepage
                    history.push(buildURI(routingConstants.DASHBOARD));
                } else {
                    store.dispatch(commonsActions.showErrorAlert(true));
                }
        }).catch(() => {
            store.dispatch(commonsActions.showErrorAlert(true));
        });
    }

    const getAvailablePreferences = (preferences) => {

        const optionValues = Object.values(options)
            .flat()
            .filter(option => !!option && !!getAEMComponent(option.label));

        return preferences.filter(preference => optionValues.findIndex(option => option.command === preference.type) > -1);
    };

    const getAEMComponent = (key) => {
        return page?.getComponent(aemPages.ONBOARD_NOTIF_DETAIL, key);
    };

    return (
        <div className="page w-100">
            <OnboardingHeader title={NOTIF_PREF_HEADER?.getRaw('heading', null) ?? ''} />

            <div className="page-wrapper">
                <div className="notification-preferences-wrapper">
                    <NotificationOptions options={options}
                        getAEMComponent={getAEMComponent}
                        updatePreference={updatePreference}
                        preferences={preferences} />
                </div>
                <PageSelectorCTA sticky={false} className="mt-auto">
                    <div className="d-grid">
                        <CustomLoadingButton isLoading={loadingReducer || loading}
                            validationMethod={null}
                            buttonLabel={NOTIF_SAVE_BTN_CHOOSE?.getRaw('ctaItems')?.[0]?.ctaLabel ?? ''}
                            onClickMethod={handleSavePreferences} />
                    </div>
                </PageSelectorCTA>
            </div>
            <NotificationPermissionHandler
                needToAskUserForNotificationPermission={needToAskUserForNotificationPermission}
                afterAskingTheUserForNotificationPermissionCallback={afterAskingTheUserForNotificationPermissionCallback}
                askAfterUserInteraction={true}
                updateAndSavePreferences={updateAndSavePreferences}
                resetAndSavePreferences={resetAndSavePreferences}
            />
            <div className="page-linear-gradient-bg"></div>
        </div>
    );
    
}