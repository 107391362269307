export const SIGNUP_FORM_STEPS_NAMES = {
  EMAIL: "email",
  PASSWORD: "password",
  NAME: "name",
  BIRTHDAY: "birthday",
  CONTACTS: "contacts",
  CONSENTS: "consents",
  HARD_AGE: "hard-age",
  CONTACTSZA: "contacts-za",
  POLICYCA: "policy-ca"
};
