import React from "react";
import { Route, Redirect } from "react-router-dom";
import { buildURI } from "./../../_helpers/navigation";
import {
  getCountry,
  getSupportedServices,
} from "../../_actions/appConfig.actions";
import {
  getAgeVerified,
  getAcceptedTermsAndConditions,
  getDoneVault,
  getUserData,
  getDoneNotificationPreferences,
  getDoneCountrySelection,
  getDoneOnboardingBenefits,
} from "../../_actions/appData.actions";
import { routingConstants, servicesConstants } from "../../_constants";
import environmentConstants from "../../_constants/environment/environment.constants";
import { Commons } from "../../_helpers/commons";
import { debug } from "../../_helpers/debug";
import BrowserHelper from "../../_helpers/browser/browser.helper";
import { Tenants } from "../../_helpers/tenants";

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const supportedServices = getSupportedServices();

      let doneCountrySelection = getDoneCountrySelection();
      let ageVerified = getAgeVerified();
      let doneOnboardingBenefits = getDoneOnboardingBenefits();
      let acceptedTermsAndConditions = getAcceptedTermsAndConditions();
      let userData = getUserData();
      let doneNotificationPreferencesStep = getDoneNotificationPreferences();
      let doneVault = getDoneVault();

      // console.log("[ProtectedRoute] doneCountrySelection: " + doneCountrySelection);
      // console.log("[ProtectedRoute] ageVerified: " + ageVerified);
      // console.log("[ProtectedRoute] doneOnboardingBenefits: " + doneOnboardingBenefits);
      // console.log("[ProtectedRoute] acceptedTermsAndConditions: " + acceptedTermsAndConditions);
      // console.log("[ProtectedRoute] doneNotificationPreferencesStep: " + doneNotificationPreferencesStep);
      // console.log("[ProtectedRoute] doneVault: " + doneVault);
      // console.log("[ProtectedRoute] getCountry: " + getCountry());
      // console.log("[ProtectedRoute] supportedServices.includes(servicesConstants.ONBOARDING_SOFT_AGE_VERIFICATION): " + supportedServices.includes(servicesConstants.ONBOARDING_SOFT_AGE_VERIFICATION));
      // console.log("[ProtectedRoute] userData: " + JSON.stringify(userData));

      let redirectPathName = "";
      let doRedirect = true;

      if (BrowserHelper.isAccessDenied()) {
        return (
          <Redirect
            to={{
              pathname: buildURI(routingConstants.ACCESS_DENIED),
              state: { from: props.location },
            }}
          />
        );
      }

      if (
        (!doneCountrySelection ||
          getCountry() == null ||
          getCountry() === undefined) &&
        doRedirect
      ) {
        if (
          rest.path === routingConstants.COUNTRY_SELECTOR ||
          rest.path === routingConstants.ROOT_VIEW
        ) {
          doRedirect = false;
        } else {
          redirectPathName = routingConstants.COUNTRY_SELECTOR;
        }
      } else if (
        !ageVerified &&
        doRedirect &&
        supportedServices.includes(
          servicesConstants.ONBOARDING_SOFT_AGE_VERIFICATION
        )
      ) {
        if (
          rest.path === routingConstants.SOFT_AGE_VERIFICATION ||
          rest.path === routingConstants.UNDERAGE
        ) {
          doRedirect = false;
        } else {
          redirectPathName = routingConstants.SOFT_AGE_VERIFICATION;
        }
      } else if (!doneOnboardingBenefits && doRedirect) {
        if (
          rest.path === routingConstants.BENEFITS ||
          rest.path === routingConstants.PRIVACY_POLICY
        ) {
          doRedirect = false;
        } else {
          redirectPathName = routingConstants.BENEFITS;
        }
      } else if (userData === null && doRedirect) {
        if (
          rest.path === routingConstants.LOGIN ||
          rest.path === routingConstants.SIGN_UP ||
          rest.path === routingConstants.BENEFITS ||
          rest.path === routingConstants.SIGNUP_FAILED ||
          rest.path === routingConstants.RESET_PASSWORD ||
          rest.path === routingConstants.PRIVACY_POLICY ||
          rest.path === routingConstants.CONDITIONS_OF_SALE
        ) {
          doRedirect = false;
        } else {
          redirectPathName = routingConstants.BENEFITS;
        }
      } else if (
        Tenants.isGlobal() &&
        supportedServices.includes(servicesConstants.ONBOARDING_AV_YOTI) &&
        !userData?.isUserAgeVerified &&
        doRedirect
      ) {
        if (
          rest.path === routingConstants.HARD_AGE_VERIFICATION ||
          rest.path === routingConstants.PRIVACY_POLICY
        ) {
          doRedirect = false;
        } else {
          redirectPathName = routingConstants.HARD_AGE_VERIFICATION;
        }
      } else if (!acceptedTermsAndConditions && doRedirect) {
        if (rest.path === routingConstants.TERMS_AND_CONDITIONS || rest.path === routingConstants.PRIVACY_POLICY) {
          doRedirect = false;
        } else {
          redirectPathName = routingConstants.TERMS_AND_CONDITIONS;
        }
      } else if (userData != null && doneVault === false && doRedirect) {
        if (
          rest.path === routingConstants.VAULT ||
          rest.path === routingConstants.TERMS_AND_CONDITIONS
        ) {
          doRedirect = false;
        } else {
          redirectPathName = routingConstants.VAULT;
        }
      } else if (userData != null && doneVault === true && doRedirect) {
        if (rest.path === routingConstants.VAULT_RESTORE) {
          doRedirect = false;
        } else {
          redirectPathName = routingConstants.VAULT_RESTORE;
        }
      } else if (
        supportedServices.includes(
          servicesConstants.ONBOARDING_NOTIFICATION_PREFERENCES
        ) &&
        userData != null &&
        !doneNotificationPreferencesStep &&
        doRedirect
      ) {
        if (
          rest.path === routingConstants.NOTIFICATION_INTRO ||
          rest.path === routingConstants.NOTIFICATION_PREFERENCES
        ) {
          doRedirect = false;
        } else {
          redirectPathName = routingConstants.NOTIFICATION_INTRO;
        }
      } else if (userData !== null && doRedirect) {
        if (
          rest.path === routingConstants.DASHBOARD ||
          rest.path === routingConstants.MORE_MENU ||
          rest.path === routingConstants.PAIR_DEVICE ||
          rest.path === routingConstants.POD_TRACKER ||
          rest.path === routingConstants.POD_TRACKER_TUTORIAL ||
          rest.path === routingConstants.POD_TRACKER_OPTIN ||
          rest.path === routingConstants.POD_TRACKER_OPTIN_INSTR ||
          rest.path === routingConstants.SELECT_DEVICE ||
          rest.path === routingConstants.LOCK_SWITCH_TUTORIAL ||
          rest.path === routingConstants.CLOUD_CONTROL ||
          rest.path === routingConstants.CLOUD_CONTROL_TUTORIAL ||
          rest.path === routingConstants.FIND_MY_VAPE ||
          rest.path === routingConstants.FIND_MY_VAPE_TUTORIAL ||
          rest.path === routingConstants.FIND_MY_VAPE_OPTIN ||
          rest.path === routingConstants.FIND_MY_VAPE_OPTIN_INSTR ||
          rest.path === routingConstants.USAGE_TRACKER ||
          rest.path === routingConstants.USAGE_TRACKER_TUTORIAL ||
          rest.path === routingConstants.USAGE_TRACKER_OPTIN ||
          rest.path === routingConstants.USAGE_TRACKER_OPTIN_INSTR ||
          rest.path === routingConstants.BATTERY_SETTINGS ||
          rest.path === routingConstants.BATTERY_SETTINGS_TUTORIAL ||
          rest.path === routingConstants.DEVICE_SETTINGS ||
          rest.path === routingConstants.DEVICE_NAME ||
          rest.path === routingConstants.ADVANCED_SETTINGS ||
          rest.path === routingConstants.LOYALTY_OPTIN ||
          rest.path === routingConstants.LOYALTY_DASHBOARD ||
          rest.path === routingConstants.MANAGE_NOTIFICATIONS ||
          rest.path === routingConstants.MANAGE_DEVICES ||
          rest.path === routingConstants.YOUR_PREFERENCES ||
          rest.path === routingConstants.MANAGE_ACCOUNT ||
          rest.path === routingConstants.TUTORIALS ||
          rest.path === routingConstants.EDIT_ACCOUNT ||
          rest.path === routingConstants.CONTACT ||
          rest.path === routingConstants.BLOG_ARTICLES ||
          rest.path === routingConstants.EXT_PAGE ||
          rest.path === routingConstants.UNLOCK_TUTORIAL ||
          rest.path === routingConstants.DEVICE_FAQ ||
          rest.path === routingConstants.STORE_LOCATOR ||
          rest.path === routingConstants.PREVIOUS_ORDERS ||
          rest.path === routingConstants.TERMS_AND_CONDITIONS_LIST ||
          rest.path ===
            routingConstants.TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY ||
          rest.path === routingConstants.PRIVACY_POLICY ||
          rest.path === routingConstants.COOKIE_POLICY ||
          rest.path === routingConstants.CONDITIONS_OF_SALE ||
          rest.path === routingConstants.SUBSCRIPTION_TERMS_AND_CONDITIONS ||
          rest.path === routingConstants.LOCATION_SERVICES ||
          rest.path === routingConstants.HARD_AGE_VERIFICATION
        ) {
          /*
                                if(rest.path === routingConstants.FIND_MY_VAPE){
                                    const optedIn = Commons.getCustomerProperty(thingVuseProperties.OPT_IN_FINDMYVAPE);
                                    if (optedIn !== flagStatus.ACCEPTED) {
                                        redirectPathName = routingConstants.FIND_MY_VAPE_OPTIN;
                                    } else {
                                        doRedirect = false;
                                    }
                                } else {*/
          doRedirect = false;
          /*}*/
        } else {
          redirectPathName = routingConstants.DASHBOARD;
        }
      }

      // console.log("doRedirect: " + doRedirect);
      // console.log("redirectPathName: " + redirectPathName);
      // console.log("rest.path: " + rest.path);
      // console.log("-------------------------");

      if (!doRedirect) {
        return <Component {...props} />;
      } else if (redirectPathName !== "") {
        if (
          redirectPathName === window.location.pathname ||
          (redirectPathName === routingConstants.ROOT_VIEW &&
            routingConstants.ONBOARDING_START === window.location.pathname)
        ) {
          return null;
        }

        debug(
          "[ProtectedRoute] redirection to ",
          redirectPathName,
          " from ",
          rest.path,
          ", is user logged in? ",
          userData !== null,
          ", is the user finished the onboarding? ",
          doneOnboardingBenefits
        );
        return (
          <Redirect
            to={{
              pathname: buildURI(redirectPathName),
              state: { from: props.location },
            }}
          />
        );
      } else {
        return <Component {...props} />;
      }
    }}
  />
);
