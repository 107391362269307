import React, { useState } from 'react';
import {
    CustomSlidingPanel,
    PageSelectorCTA
} from "../../_components";
import cx from "classnames";

export const DataSyncing = ({
    titleLabel,
    descriptionLabel,
    ctaLabel,
    classNames
}) => {

    const [isDataSyncingPanelOpen, setIsDataSyncingPanelOpen] = useState(false);

    return (
        <>
        <div
            className={cx("data-syncing", classNames)}
            onClick={() => setIsDataSyncingPanelOpen(true)}
        >
            <span>{titleLabel}</span>
            <div className="data-syncing-loader"></div>
        </div>

        <CustomSlidingPanel
            from="bottom"
            isOpen={isDataSyncingPanelOpen}
            title={titleLabel}
            className="onboarding-panel mid-height-panel notification-check data-syncing-panel"
            overlayClassName="sliding-common-wrapper homepage-priority"
            backdropClose={false}
        >
            <div className="sliding-panel-mid data-syncing-panel-info">
                <div className="data-syncing-info-loader"></div>
                <div className="sliding-panel-mid-info">
                    {descriptionLabel}
                </div>
            </div>

            <PageSelectorCTA sticky={false} className="mt-auto">
            <div className="d-grid gap-2">
                <button
                className="btn btn-primary text-uppercase"
                onClick={() => setIsDataSyncingPanelOpen(false)}
                >
                    {ctaLabel}
                </button>
            </div>
            </PageSelectorCTA>
        </CustomSlidingPanel>
        </>
    );

};