import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import {
  AEMHelper,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
  propertyCtaItemsDefaultEmpty
} from "../../_helpers/aem/aemhelper";
import { Header, DataSyncing } from "../../_components";
import {
  imagesConstants,
  aemPages,
  routingConstants,
  flagStatus,
  thingVuseProperties,
  PROFILE_SMARTBOX,
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  crmUserSettings
} from "../../_constants";
import { isEmpty } from "lodash";
import { Doughnut } from "react-chartjs-2";
import cx from "classnames";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js/auto";
import { Utils } from "../../_helpers/graphic/utils";
import {
  getPodData,
  getUsedFlavourGroups,
  getPodUsageInfo,
  getGraphData,
  getFlavoursHistory
} from "../../_helpers/pod-tracker";
import moment from "moment";
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { Commons } from '../../_helpers/commons';
import { store } from '../../_store';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { updateDeviceInReducer, isDeviceConnected } from "../../_helpers/device";
import { setPodRecords, clearPodData } from '../../_helpers/pod-tracker'; // TEST
import { userActions } from '../../_actions';

Chart.defaults.font.family = "Montserrat";

Chart.register(ChartDataLabels);

export const PodTracker = ({
  header = true
}) => {

    const [dictionary, setDictionary] = useState({});
    
    const [activeTab, setActiveTab] = useState(0);
    const [tabs, setTabs] = useState([]);
    const [mostUsedFlavours, setMostUsedFlavours] = useState([]);
    const [formattedMonthRange, setFormattedMonthRange] = useState("");
    const [avgPodUsage, setAvgPodUsage] = useState(null);
    const [podsCount, setPodsCount] = useState(null);
    const [graphData, setGraphData] = useState({
      data: [],
      backgroundColor: []
    });
    const [flavoursHistory, setFlavoursHistory] = useState([]);
    const [flavourGroupsBoxCount, setFlavourGroupsBoxCount] = useState([5, 5, 5]);
    const [flavoursBoxCount, setFlavoursBoxCount] = useState([3, 3, 3]);
    const [showMissingAlert, setShowMissingAlert] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [currDevice, setCurrDevice] = useState(null);
    const [clearDeviceSyncing, setClearDeviceSyncing] = useState(true);

    const devices = useSelector(state => state?.deviceReducer?.devices);

    useEffect(() => {
        //check for optin
        const optedIn = Commons.getCustomerProperty(
          thingVuseProperties.OPT_IN_POD_TRACKER
        );
    
        if (optedIn !== flagStatus.ACCEPTED) {
          setActiveTab("pod-tracker-optin")
          history.push({
            pathname: buildURI(routingConstants.DASHBOARD),
            hash: 'pod-tracker-optin'
          })
        } else {
          const data = {
            "timestamp": (new Date()).toISOString()
          }

          store.dispatch(userActions.setUserSettings(
            crmUserSettings.POD_TRACKER_USED,
            JSON.stringify(data)
          ));
        }

        //check if there is a ultra device or we have at least one record about the pod tracker
        const foundSmartbox = store.getState()?.deviceReducer?.devices.find(device => device.deviceType === PROFILE_SMARTBOX)
        if (!foundSmartbox) {
          setMissingSmartPod();
        } else {
          //check if there are no records in the last 6 months
          getPodData(6, false, queryResult => {
            if (queryResult.length === 0) {
              setMissingSmartPod();
            } else {
              setShowMissingAlert(false)
              setShowPage(true);
            }
          })
        }

        const index = devices.findIndex((device) => device?.selected);
        if (index === -1) setCurrDevice(devices[0]);
        else setCurrDevice(devices[index])

        const aem = new AEMHelper();

        setDictionary({
          ...aem.getDictionary(aemPages.POD_TRACKER, {
            POD_TRACKER_MONTH: propertyTextDefaultEmpty,
            POD_TRACKER_MONTHS: propertyTextDefaultEmpty,
            POD_TRACKER_ULTRA_PODS_USED: propertyTextDefaultEmpty,
            POD_TRACKER_USED_FLAVOURS: propertyTextDefaultEmpty,
            POD_TRACKER_AVG_USAGE_TIME: propertyTextDefaultEmpty,
            POD_TRACKER_BREAKDOWN: propertyTextDefaultEmpty,
            POD_TRACKER_MOST_USED: propertyTextDefaultEmpty,
            POD_TRACKER_ULTRA_PODS: propertyTextDefaultEmptyParsed,
            POD_TRACKER_VIEW_MORE: propertyTextDefaultEmpty,
            POD_TRACKER_HISTORY: propertyTextDefaultEmpty,
            POD_TRACKER_FIRST_USED: propertyTextDefaultEmpty,
            POD_TRACKER_NICOTINE: propertyTextDefaultEmpty,
            POD_TRACKER_USAGE_TIME: propertyTextDefaultEmpty,
            POD_TRACKER_MG: propertyTextDefaultEmpty,              
            POD_TRACKER_MIN: propertyTextDefaultEmpty,
            POD_TRACKER_MINS: propertyTextDefaultEmpty,
            POD_TRACKER_HOUR: propertyTextDefaultEmpty,
            POD_TRACKER_HOURS: propertyTextDefaultEmpty,
            POD_TRACKER_DAY: propertyTextDefaultEmpty,
            POD_TRACKER_DAYS: propertyTextDefaultEmpty,
            POD_TRACKER_DATA_SYNCING_HEADER: propertyHeadingDefaultEmpty,
            POD_TRACKER_PUFF_RECORDS_DATA: propertyTextDefaultEmpty,
            POD_TRACKER_BTN_DISMISS: propertyCtaItemsDefaultEmpty
          }),
          ...aem.getDictionary(aemPages.POD_TRACKER_OPTIN, {
            PD_OPTIN_HEADER_CONNECT_DEVICE: propertyHeadingDefaultEmpty,
            PD_OPTIN_VUSE_ULTRA_DEVICE: propertyTextDefaultEmptyParsed
          })
        });

    }, []);

    useEffect(() => {
      if (!isEmpty(dictionary)) {
        setTabs([
          Utils.stringReplacePlaceholders(
            dictionary.POD_TRACKER_MONTH, ["1"]
          ),
          Utils.stringReplacePlaceholders(
            dictionary.POD_TRACKER_MONTHS, ["3"]
          ),
          Utils.stringReplacePlaceholders(
            dictionary.POD_TRACKER_MONTHS, ["6"]
          )
        ]);
      }
    }, [dictionary]);

    useEffect(() => {

      if (!showPage) return;

      let monthRange;

      (activeTab === 0) ? monthRange = 1 :
      (activeTab === 1) ? monthRange = 3 :
      monthRange = 6;

      // query records
      getPodData(monthRange, false, queryResult => {

        if (queryResult.length){
          getUsedFlavourGroups(queryResult).then(res => {
            setMostUsedFlavours(res);
            getGraphData(res).then(res => setGraphData(res));
          });
          getPodUsageInfo(queryResult, monthRange).then(res => {
            setAvgPodUsage(res.avg);
            setPodsCount(res.count);
          });
          getFlavoursHistory(queryResult, monthRange).then(res => setFlavoursHistory(res));
        }

        else{
          setMostUsedFlavours([]);
          setGraphData({
            data: [100],
            backgroundColor: ["#727272"]
          });
          setAvgPodUsage(0);
          setPodsCount(0);
          setFlavoursHistory([]);
        }

      });

      setFormattedMonthRange(formatMonthRange(monthRange));

      logAnalyticsEvent(
        AnalyticsEvents.SCREEN_VIEW,
        {screen_name: AnalyticsScreenEventNames[`POD_TRACKER_${monthRange}_MONTH`]}
      );

    }, [activeTab, showPage]);

    useEffect(() => {
      if (currDevice && !isDeviceConnected(currDevice) && clearDeviceSyncing){
        updateDeviceInReducer(
          currDevice.serialNumber,
          {isDataSyncing: null},
          false,
          () => {
            const index = devices.findIndex((device) => device?.selected);
            setCurrDevice(
              store?.getState().deviceReducer?.devices[index]
            )
          }
        );
        setClearDeviceSyncing(false);
      }
    }, [currDevice]);

    const setMissingSmartPod = () => {
      setShowMissingAlert(true);
      logAnalyticsEvent(
        AnalyticsEvents.SCREEN_VIEW,
        {screen_name: AnalyticsScreenEventNames.POD_TRACKER_NO_SMART_PODS}
      );
    }

    const goToTutorial = () => {
      logAnalyticsEvent(
          AnalyticsEvents.CLICK,
          {target: AnalyticsTargetEventsNames.TUTORIAL}
      );
      history.push(buildURI(routingConstants.POD_TRACKER_TUTORIAL), {type: 'slide'});
    };

    const chartOptions = (labelsVisiblity = true) => {

      let cutout;
      labelsVisiblity ? cutout = "83%" : cutout = "86.5%";

      return{
        responsive: true,
        cutout,
        plugins: {
          tooltip:  { enabled: false }, // no numbers on hover
          legend:   { display: false }, // no legend
          datalabels: {
            display: (context) => {
              return labelsVisiblity && (context.dataIndex < 5); // display only the label for the top 5 items
            },
            anchor: 'center',
            align: context => {
              return context.chart.data.datasets[0].data.length === 1 ? 'start' : 'center'
            },
            offset: context => {
              return context.chart.data.datasets[0].data.length === 1 ? 197 : 0;
            },
            borderRadius: 4,
            padding: 6,
            labels: {

              title: {
                display: (context) => {
                  return labelsVisiblity && (context.dataIndex < 5); // display only the label for the top 5 items
                },
                color: '#FFF',
                formatter: (value, context) => {

                  const percentage = Math.floor(
                    (value / context.chart.data.datasets[0].data.reduce(
                      (a, b) => a + b, 0
                    ))* 100
                  );
                  return `   ${percentage}%`;
                },
                backgroundColor: '#313131',
                font: {
                  size: 14,
                  weight: 'bold',
                },
              },

              value: {
                color: context => { return context.dataset.backgroundColor; },
                formatter: (value, context) => {
                  const percentageLen = Math.floor(
                    (value / context.chart.data.datasets[0].data.reduce(
                      (a, b) => a + b, 0
                    )) * 100
                  ).toString().length;
                  let margin = "";
                  if (percentageLen === 2) margin = " ";
                  else if (percentageLen === 3) margin = "   ";
                  return `●        ${margin}`;
                },
                backgroundColor: '#31313100',
                font: {
                  size: 14,
                  weight: 'bold',
                },
              },

            }
          },
        },
        layout: { padding: 20 }
      };

    }

    const viewMoreFlavourGroups = () => {
      let flavoursCount = [...flavourGroupsBoxCount];
      flavoursCount[activeTab] += 10;

      setFlavourGroupsBoxCount(flavoursCount);

      logAnalyticsEvent(
        AnalyticsEvents.CLICK,
        {target: AnalyticsTargetEventsNames.USAGE_BREAKDOWN_VIEW_MORE}
      );
    }

    const viewMoreFlavours = () => {
      let flavoursCount = [...flavoursBoxCount];
      flavoursCount[activeTab] += 10;

      setFlavoursBoxCount(flavoursCount);

      logAnalyticsEvent(
        AnalyticsEvents.CLICK,
        {target: AnalyticsTargetEventsNames.HISTORY_VIEW_MORE}
      );
    }

    // monthRange in {1,3,6}
    const formatMonthRange = (monthRange) => {

      let formattedMonthRange = moment().format('MMM YYYY');

      if (monthRange > 1)
          formattedMonthRange = moment().subtract(
              monthRange - 1, 'months'
          ).format('MMM YYYY') + " - "
          + formattedMonthRange;

      return formattedMonthRange;

    }

    const formatUsageTime = (
      hours,
      minuteTranslation,
      minutesTranslation,
      hourTranslation,
      hoursTranslation,
      dayTranslation,
      daysTranslation
    ) => {

      let value;

      if (hours === 0)
        return `1  ${minuteTranslation}`;

      if (hours < 1){
        
        value = Math.ceil(hours * 60);
        if (value === 1) return `${value} ${minuteTranslation}`;
        else return `${value} ${minutesTranslation}`;

      }

      if (hours < 48){

        value = Math.ceil(hours);
        if (value === 1) return `${value} ${hourTranslation}`;
        else return `${value} ${hoursTranslation}`;
      
      }

      value = Math.ceil(hours / 24);
        if (value === 1) return `${value} ${dayTranslation}`;
        else return `${value} ${daysTranslation}`;

    }

    const formatAvgPodUsageTime = (
      hours,
      minuteTranslation,
      minutesTranslation,
      hourTranslation,
      hoursTranslation,
      dayTranslation,
      daysTranslation
    ) => {

      let value;
      let measure;

      if (hours === 0)
        return "--";

      if (hours < 1){
      
        value = Math.ceil(hours * 60);
        measure = (value === 1)
          ? minuteTranslation
          : minutesTranslation

        return (
          <>
            {value} <span>{measure}</span>
          </>
        );
      
      }

      if (hours < 48){
      
        value = Math.ceil(hours);
        measure = (value === 1)
          ? hourTranslation
          : hoursTranslation

        return (
          <>
            {value} <span>{measure}</span>
          </>
        );
      
      }

      value = (Math.ceil(hours / 24 * 10)) / 10;
      measure = (value === 1)
        ? dayTranslation
        : daysTranslation

      return (
        <>
          {value} <span>{measure}</span>
        </>
      );
      
    }

    return(
      <div className="pod-tracker h-100 w-100">
        {header &&
        <Header
          leftButton={{
          className: "play-tutorial",
          icon: <img src={imagesConstants.CLOUD_CONTROL_PLAY_TUTORIAL} />,
          onClick: goToTutorial,
          }}
          //forceTransparent={true}
        />
        }

        {!showMissingAlert &&
        <>
          <div className="time-tabs pod-tracker-tabs">
            {tabs.map((tabName, index) => (
              <span
                  key={index}
                  className={cx({ active: activeTab === index })}
                  onClick={() => setActiveTab(index)}
              >
                  {tabName}
              </span>
            ))}
              <div
              className="cursor"
              style={{ transform: `translateX(${100 * activeTab}%)` }}
              />
          </div>

          <div className={cx("time-tabs-content-box", podsCount ? "pod-tracker-content-box" : "pod-tracker-content-box-empty")}>
            <div
              className={podsCount ? "pod-tracker-graph" : "pod-tracker-graph-empty"}
              style={currDevice?.isDataSyncing ? {opacity: "0.1"} : {}}
            >
              <Doughnut
                options={chartOptions(podsCount)}
                data={{
                  datasets: [
                    {
                      data: graphData.data,
                      backgroundColor: graphData.backgroundColor,
                      borderColor: 'rgba(29, 29, 27, 1)',
                      borderWidth: podsCount ? 4 : 0
                    },
                  ],
                }}
              />
            </div>

            <div
              className="pod-tracker-usage-count"
              style={currDevice?.isDataSyncing ? {opacity: "0.1"} : {}}
            >
                <div className="pods-used-count">{podsCount ? podsCount : "--"}</div>
                <div className="pods-used-label">{dictionary.POD_TRACKER_ULTRA_PODS_USED}</div>
            </div>

            {!currDevice?.isDataSyncing &&
              <div className="pod-tracker-most-used-label">
                {dictionary.POD_TRACKER_USED_FLAVOURS}<br/><span>{formattedMonthRange}</span>
              </div>
            }

            <div className="pod-tracker-avg-usage-time">
              <div className="avg-usage-label">{dictionary.POD_TRACKER_AVG_USAGE_TIME}</div>
              <div className="avg-usage-value">
                {formatAvgPodUsageTime(
                    avgPodUsage,
                    dictionary.POD_TRACKER_MIN,
                    dictionary.POD_TRACKER_MINS,
                    dictionary.POD_TRACKER_HOUR,
                    dictionary.POD_TRACKER_HOURS,
                    dictionary.POD_TRACKER_DAY,
                    dictionary.POD_TRACKER_DAYS
                  )}
              </div>
            </div>

            { podsCount ? (
              <div className="pod-tracker-usage-breakdown">
                <div className="breakdown-label pod-tracker-title">{dictionary.POD_TRACKER_BREAKDOWN}</div>

                { mostUsedFlavours
                    .slice(0, flavourGroupsBoxCount[activeTab])
                    .map((flavour, idx) => {
                  return(

                    <div  className="flavour-group-box"
                          style={idx === 0
                            ? {boxShadow: "0px 4px 40px 0px " + Utils.getShadowFromColor(flavour?.color)}
                            : {}
                          }>
                      <div className="box-header"
                        style={{backgroundColor: flavour?.color}}>
                      </div>
                      <div className="box-content">
                        <div
                          className="box-flavour-group-icon"
                          style={idx === 0 ? {marginTop: "15px"} : {}}
                        >
                          {flavour?.icon &&
                          <img src={flavour && flavour.icon} />
                          }
                        </div>
                        <div
                          className="box-flavour-group-info"
                          style={idx === 0 ? {marginTop: "21px"} : {}}
                        >
                          <div className="box-flavour-group-info-name">
                            {flavour?.name}
                          </div>
                          <div className="box-flavour-group-info-usage">
                            { Utils.stringReplacePlaceholders(
                                dictionary.POD_TRACKER_ULTRA_PODS, flavour?.ultraPodsCount
                            ) }
                          </div>
                          { idx === 0 ? (
                            <div className="box-flavour-group-info-most-used-badge">
                              {dictionary.POD_TRACKER_MOST_USED}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                }) }

                {(flavourGroupsBoxCount[activeTab] < mostUsedFlavours.length) ? (
                  <button
                    className="pod-tracker-view-more view-more-breakdown"
                    onClick={viewMoreFlavourGroups}
                  >
                    {dictionary.POD_TRACKER_VIEW_MORE}
                  </button>
                ): null}

              </div>
            ): null}
          
          </div>

          {podsCount ? (
            <div className="pod-tracker-history">

              <div className="history-label pod-tracker-title">{dictionary.POD_TRACKER_HISTORY}</div>

              { flavoursHistory
                .slice(0, flavoursBoxCount[activeTab])
                .map((flavour, idx, flavours) => {
                return (
                  <>
                    { (flavour?.month !== flavours[idx-1]?.month) ? (
                        <div className="month-subtitle">
                          {flavour?.month} {flavour?.year}
                        </div>
                    ) : null}

                    <div className="flavour-box">
                      <div  className="box-bar"
                            style={{backgroundColor: flavour?.color}}>
                      </div>
                      <div className="box-content">
                        <div className="box-flavour-header">
                          <div className="box-flavour-header-icon">
                            <img src={flavour && flavour.icon} />
                          </div>
                          <div className="box-flavour-header-name">{flavour?.name}</div>
                        </div>
                        <div className="box-flavour-info">
                          <div className="box-flavour-info-labels">
                            <div>{dictionary.POD_TRACKER_FIRST_USED}</div>
                            <div>{dictionary.POD_TRACKER_NICOTINE}</div>
                            <div>{dictionary.POD_TRACKER_USAGE_TIME}</div>
                          </div>
                          <div className="box-flavour-info-values">
                            <div>{flavour?.createdAt}</div>
                            <div>
                              { Utils.stringReplacePlaceholders(
                                dictionary.POD_TRACKER_MG, flavour?.nicotine
                              )}
                            </div>
                            <div>
                              {formatUsageTime(
                                flavour?.usageTime,
                                dictionary.POD_TRACKER_MIN,
                                dictionary.POD_TRACKER_MINS,
                                dictionary.POD_TRACKER_HOUR,
                                dictionary.POD_TRACKER_HOURS,
                                dictionary.POD_TRACKER_DAY,
                                dictionary.POD_TRACKER_DAYS
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}

              {(flavoursBoxCount[activeTab] < flavoursHistory.length) ? (
                <button
                  className="pod-tracker-view-more view-more-history"
                  onClick={viewMoreFlavours}
                >
                  {dictionary.POD_TRACKER_VIEW_MORE}
                </button>
              ) : null}

            </div>
          ) : null}

          {currDevice?.isDataSyncing &&
            <DataSyncing
              titleLabel={dictionary.POD_TRACKER_DATA_SYNCING_HEADER}
              descriptionLabel={dictionary.POD_TRACKER_PUFF_RECORDS_DATA}
              ctaLabel={dictionary.POD_TRACKER_BTN_DISMISS_0_ctaLabel}
              classNames="pt-data-syncing"
            />
          }
          
        </>
        }

        {showMissingAlert &&
        <div className='pod-tracker-missing-wrapper'>
          <div className='pod-tracker-missing-title'>
            {dictionary.PD_OPTIN_HEADER_CONNECT_DEVICE}
          </div>
          <div className='pod-tracker-missing-description'>
            {dictionary.PD_OPTIN_VUSE_ULTRA_DEVICE}
          </div>

          <div className='pod-tracker-missing-image'>
            <img src={imagesConstants.POD_TRACKER_MISSING_BG} />
          </div>
        </div>
        }

        <div className="page-linear-gradient-bg" />

      </div>
    )


}