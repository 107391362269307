import React from 'react';
import { connect } from 'react-redux';
import { OnboardingHeader } from '../../_components/OnboardingHeader';
import { aemPages, routingConstants } from '../../_constants';
import { AEMHelper, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { Header } from '../../_components';
import { buildURI } from '../../_helpers/navigation';

class FindMyVapeOptinInstructions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.FIND_MY_VAPE_OPTIN, {
            FMV_OPTIN_MD_CONTENT: propertyTextDefaultEmptyParsed,
            FMV_OPTIN_MD_STEP3: propertyTextDefaultEmpty,
            FMV_OPTIN_MD_STEP1: propertyTextDefaultEmpty,
            FMV_OPTIN_TUT_HEADER: propertyTextDefaultEmpty,
            FMV_OPTIN_MD_STEP2: propertyTextDefaultEmpty
        });
    }


    componentDidMount() {
        //document.body.classList.add("");
    }

    componentWillUnmount() {
        //document.body.classList.remove("");
    }

    render() {

        return (
            <div className="page w-100">
                <Header
                    leftButton={{
                    icon: <span className="bat-icon-back" />,
                    onClick: () => this.props.history.push(buildURI(routingConstants.FIND_MY_VAPE_OPTIN)),
                    }}
                >
                    {this.dictionary.FMV_OPTIN_TUT_HEADER}
                </Header>
                <div className="page-wrapper optin-instructions-wrapper">
                    <div className="optin-instructions-descr">
                        {this.dictionary.FMV_OPTIN_MD_CONTENT}
                    </div>

                    <div className="optin-instructions-content">
                        <ol>
                            <li><span className="bat-icon-hamburger"></span>{this.dictionary.FMV_OPTIN_MD_STEP1}</li>
                            <li><span className="bat-icon-chevron-right"></span>{this.dictionary.FMV_OPTIN_MD_STEP2}</li>
                            <li><span className="bat-icon-toggle"></span>{this.dictionary.FMV_OPTIN_MD_STEP3}</li>
                        </ol>
                    </div>
                </div>

                <div className="page-linear-gradient-bg"></div>
            </div>

        );

    }
}

function mapStateToProps(state) {

    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)((FindMyVapeOptinInstructions));
export { connectedComponent as FindMyVapeOptinInstructions };