import React, { useEffect, useState } from "react";
import {
  AnalyticsEvents,
  HARD_AGE_VERIFICATION_STEPS,
  aemPages,
  imagesConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Header } from "../Header";

export function HardAgeVerificationNegateConsent({ goToStep }) {
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    const aem = new AEMHelper();

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: "onboarding/yotidonotconsent",
    });

    setDictionary(
      aem.getDictionary(aemPages.HARD_AGE_VERIFICATION, {
        YOTI_AV_SECOND_ERROR_TITLE: propertyTextDefaultEmpty,
        YOTI_AV_SECOND_ERROR_SUPPORT_CONTENT: propertyTextDefaultEmpty,
        YOTI_AV_SUPPORT_NUMBER: propertyTextDefaultEmpty,
        YOTI_AV_SUPPORT_MAIL: propertyTextDefaultEmpty,
      })
    );
  }, []);

  return (
    <div className="page w-100 overflow-hidden" style={{background: 'none'}}>
      {/*<Header
        leftButton={{
          icon: <span className="bat-icon-back" />,
          onClick: () => {
            logAnalyticsEvent(AnalyticsEvents.YOTI_GO_BACK_FACE_SCAN);
            goToStep(HARD_AGE_VERIFICATION_STEPS.CONSENT);
          },
        }}
      />*/}
      <div className="page-wrapper d-flex flex-column align-items-center h-100">
        <div className="hard-age-verification-title text-center">
          <h1>{dictionary.YOTI_AV_SECOND_ERROR_TITLE}</h1>
          <span className="bat-icon-alert-triangle-outline alert-icon" />
        </div>
        <div className="hard-age-verification-support">
          {dictionary.YOTI_AV_SECOND_ERROR_SUPPORT_CONTENT}
          <ul>
            <li>
              <span className="bat-icon-phone me-3" />
              {dictionary.YOTI_AV_SUPPORT_NUMBER}
            </li>
            <li>
              <img
                src={imagesConstants.NEW_EMAIL}
                alt="email icon"
                className="me-3"
                style={{ height: "14px" }}
              />
              {dictionary.YOTI_AV_SUPPORT_MAIL}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
