import cx from "classnames";
import { bool, element, exact, func, oneOfType, string } from "prop-types";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { imagesConstants } from "../../_constants";

const Header = forwardRef(
  (
    { className, transparent = false, leftButton, rightButton, subHeader, children, isAbsolute = false, forceTransparent, high = false },
    ref
  ) => {
    const [isTransparent, setIsTransparent] = useState(transparent);

    const handleHeaderBackground = useCallback(() => {
      if(ref){
        const headerRect = ref.current.getBoundingClientRect();

        if (headerRect.y > 0) {
          setIsTransparent(true);
          return;
        }
      }

      setIsTransparent(window.scrollY <= 1);
    }, []);

    useEffect(() => {
      if (transparent) {
        window.addEventListener("scroll", handleHeaderBackground);

        return () => {
          window.removeEventListener("scroll", handleHeaderBackground);
        };
      }
    }, [transparent, handleHeaderBackground]);

    return (
      <header
        ref={ref}
        className={cx("app-header container", className, {
          transparent: isTransparent || forceTransparent,
          'position-absolute': isAbsolute
        })}
      >
        <div className={cx("row", {"high-row": high})}>
          {(leftButton || rightButton) &&
          <div className="col text-start">
            {leftButton && (
              <button
                type="button"
                className={cx("app-header-btn", leftButton.className)}
                onClick={leftButton?.onClick}
              >
                {leftButton.icon}
              </button>
            )}
          </div>
          }
          <div className="col center">
            <div className="app-header-title">
              {children || (
                <img
                  className="app-header-logo"
                  src={imagesConstants.MYVUSE_lOGO}
                  alt="MyVuse Logo"
                />
              )}
            </div>
          </div>
          {(leftButton || rightButton) &&
          <div className="col text-end">
            {rightButton && (
              <button
                type="button"
                className={cx("app-header-btn", rightButton.className)}
                onClick={rightButton?.onClick}
              >
                {rightButton.icon}
              </button>
            )}
          </div>
          }
        </div>
        {subHeader && (
          <div className="row secondary">
            <div className="col">
              <div className="app-header-title">{subHeader}</div>
            </div>
          </div>
        )}
      </header>
    );
  }
);

Header.propTypes = {
  transparent: bool,
  className: string,
  leftButton: exact({
    className: string,
    icon: element.isRequired,
    onClick: func,
  }),
  rightButton: exact({
    className: string,
    icon: element.isRequired,
    onClick: func,
  }),
  subHeader: oneOfType([string.isRequired, element]),
};

export { Header };
