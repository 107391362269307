import React from "react";
import { getCountry } from "../../../../_actions/appConfig.actions";
import { ConsentsStepCA } from "./ConsentsStepCA";
import { ConsentsStepDE } from "./ConsentsStepDE";
import { ConsentsStepZA } from "./ConsentsStepZA";

export function ConsentsStep(props) {
  switch (getCountry()) {
    case "DE":
      return <ConsentsStepDE {...props} />;
    case "ZA":
      return <ConsentsStepZA {...props} />;
    default:
      return <ConsentsStepCA {...props} />;
  }
}
