import isNil from "lodash/isNil";
import { commonsActions, getUserData, softResetAppData } from "../../_actions";
import { store } from "../../_store";
import { debug } from "../debug";
import { removeDb, removeDbInstance, sendCalculatedData } from "../utils";
import { history } from "../history";
import { buildURI } from "../navigation";
import { routingConstants } from "../../_constants";

export class UserHelper {
  static isUserLoggedInByStoreState(storeState) {
    const {
      onboardingReducer: { userPin },
    } = storeState;

    const userData = getUserData();
    const isUserLoggedIn = !(isNil(userData) || isNil(userPin));
    debug(
      "isUserLoggedIn ",
      isUserLoggedIn,
      ", isNil(userData) ",
      isNil(userData),
      ", isNil(onboardingReducer.userPin) ",
      isNil(userPin)
    );
    return isUserLoggedIn;
  }

  static isUserLoggedIn() {
    const storeState = store.getState();
    return this.isUserLoggedInByStoreState(storeState);
  }

  static logout() {
    //wait to send calculated data before logout to avoid a forbidden http error
    sendCalculatedData(true).then(() => {
      removeDb()
      removeDbInstance();
      store.dispatch(commonsActions.logout()).then(() => {
        softResetAppData();
        history.push(buildURI(routingConstants.BENEFITS));
      });
    })
  }
}
