class DeviceModel {

    deviceInfo = null;
    batteryInfo = null;
    lockInfo = null;
    cloudInfo = null;
    connected = false;
    connectionTimedOut =false;
    selected = false;
    deviceType = null;
    deviceTypeName = null;
    deviceCustomName = null;
    serialNumber = null;
    deviceColor = null;
    connectionState = null;
    lastSyncedLocation = null;
    marketSelectorCompleted = null;
    isBlinking = false;
    ledInfo = null;
    lastConnection = null;
    uuid = null;
    dfuModeOn = false;
    updatable = false;
    ongoingUpdate = false;
    prevPowerLevel = null;
    isSmartPod = null;
    liquidInfo = null;
    liquidRemaining = null;
    skuNumber = null;
    isDataSyncing = null;

    constructor(data) {
        this.deviceInfo = data.deviceInfo;
        this.batteryInfo = data.batteryInfo;
        this.lockInfo = data.lockInfo;
        this.cloudInfo = data.cloudInfo;
        this.connected = data.connected;
        this.connectionTimedOut =data.connectionTimedOut;
        this.deviceType = data.deviceType;
        this.deviceTypeName = data.deviceTypeName;
        this.deviceCustomName = data.deviceCustomName;
        this.serialNumber = data.serialNumber;
        this.deviceColor = data.deviceColor;
        this.connectionState = data.connectionState;
        this.selected = data.selected;
        this.lastSyncedLocation = data.lastSyncedLocation;
        this.marketSelectorCompleted = data.marketSelectorCompleted;
        this.isBlinking = data.isBlinking;
        this.ledInfo = data.ledInfo;
        this.lastConnection = data.lastConnection;
        this.uuid = data.uuid;
        this.dfuModeOn = data.dfuModeOn;
        this.updatable = data.updatable;
        this.ongoingUpdate = data.ongoingUpdate;
        this.prevPowerLevel = data.prevPowerLevel;
        this.isSmartPod = data.isSmartPod;
        this.liquidInfo = data.liquidInfo;
        this.liquidRemaining = data.liquidRemaining;
        this.skuNumber = data.skuNumber;
        this.isDataSyncing = data.isDataSyncing;
    }        

    toObj = () => {
        return {
            "deviceInfo": this.deviceInfo,
            "batteryInfo": this.batteryInfo,
            "lockInfo": this.lockInfo,
            "cloudInfo": this.cloudInfo,
            "connected": this.connected,
            "connectionTimedOut": this.connectionTimedOut,
            "deviceType": this.deviceType,
            "deviceTypeName": this.deviceTypeName,
            "deviceCustomName": this.deviceCustomName,
            "serialNumber": this.serialNumber,
            "deviceColor": this.deviceColor,
            "connectionState": this.connectionState,
            "selected": this.selected,
            "marketSelectorCompleted": this.marketSelectorCompleted,
            lastSyncedLocation: this.lastSyncedLocation,
            isBlinking: this.isBlinking,
            ledInfo: this.ledInfo,
            lastConnection: this.lastConnection,
            uuid: this.uuid,
            dfuModeOn: this.dfuModeOn,
            updatable: this.updatable,
            ongoingUpdate: this.ongoingUpdate,
            prevPowerLevel: this.prevPowerLevel,
            isSmartPod: this.isSmartPod,
            liquidInfo: this.liquidInfo,
            liquidRemaining: this.liquidRemaining,
            skuNumber: this.skuNumber,
            isDataSyncing: this.isDataSyncing
        }        
    }

}

export { DeviceModel };