import React, { useEffect, useState } from 'react';
import { AppStories } from '../../_components/AppStories';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { Commons } from '../../_helpers/commons';
import { aemPages, routingConstants } from '../../_constants';
import { AEMHelper, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { Header } from '../Header';

export const FeatureTutorial = (props) => {

    const [dictionary, setDictionary] = useState({});
    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.HOMEPAGE, {
                HOME_LABEL_BACK: propertyTextDefaultEmpty,
                HOME_LABEL_NEXT: propertyTextDefaultEmpty,
            })
        );
    }, [])

    const handleBackToFeature = () => {
        if (props.backAction) {
            Commons.acceptOptIn(props.thing)
            if (props.backAction === routingConstants.DASHBOARD) {
                history.push(buildURI(props.backAction))
            } 
            else if (props.backAction === routingConstants.POD_TRACKER){
                history.push({
                    pathname: buildURI(routingConstants.DASHBOARD),
                    hash: 'pod-tracker'
                }, {type: 'slide-left'});
            }
            else if (props.backAction === routingConstants.POD_TRACKER_OPTIN){
                history.push({
                    pathname: buildURI(routingConstants.DASHBOARD),
                    hash: 'pod-tracker-optin'
                }, {type: 'slide-left'});
            }
            else {
                history.push(buildURI(props.backAction), {type: 'slide-left'})
            }
        } else if (typeof props.backFn === "function") {
            props.backFn();
        }
    }

    console.log("props.stories", props.stories);

    return (
        <div className="feature-tutorial-wrapper w-100">
            <Header 
                rightButton={{
                    icon: <span className="bat-icon-close" />,
                    onClick:handleBackToFeature
            }}>
                {props.title}
            </Header>
            <div className="feature-tutorial-stories">
                {
                    !props.doneTutorialValue &&
                    <div className="feature-tutorial-stories-controls d-flex">
                        <div className="back">{dictionary.HOME_LABEL_BACK}</div>
                        <div className="next">{dictionary.HOME_LABEL_NEXT}</div>
                    </div>
                }

                {
                    props.stories && props.stories.length > 0 &&
                    <AppStories stories={props.stories}
                        currentIdx={props.currentIdx ?? 0}
                        doneTutorialMethod={props.doneTutorialMethod}
                        endStoriesCallback={props.endStoriesCallback}
                        startStoryCallback={props.startStoryCallback}
                        endStoryCallback={props.endStoryCallback}
                        loop={props.loop}
                        analyticsTutorial={true}
                        tutorialName={props?.tutorialName}
                    />
                }
            </div>
        </div>
    );
}