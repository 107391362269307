import React from "react";
import cx from "classnames";

export function UsageTrackerPuffsSlider({
  className,
  title,
  subtitle,
  hideNavigationLeft,
  hideNavigationRight,
  children,
  onPrev,
  onNext,
}) {
  return (
    <div className={cx("time-tabs-content-box", "usage-tracker-puffs-slider", className)}>
      <div className="usage-tracker-puffs-date">
        <button
          type="button"
          className={cx("btn", { hide: hideNavigationLeft })}
          onClick={onPrev}
        >
          <span className="bat-icon-chevron-left" />
        </button>
        <div>
          <div className="text-uppercase">{title}</div>
          <div className="text-uppercase text-transparent">{subtitle}</div>
        </div>
        <button
          type="button"
          className={cx("btn", { hide: hideNavigationRight })}
          onClick={onNext}
        >
          <span className="bat-icon-chevron-right" />
        </button>
      </div>
      {children}
    </div>
  );
}
