import React, { useEffect, useState } from 'react';
import { OnboardingHeader } from '../../_components/OnboardingHeader';
import { CustomModal } from '../../_components/CustomModal';
import { aemPages, imagesConstants, thingVuseProperties } from '../../_constants';
import { CustomLoadingButton } from '../../_components/CustomLoadingButton';
import { Commons } from '../../_helpers/commons';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';

export const OptInFavourites = ({ isOpen, onClose, onDismiss, onAcceptOptin }) => {

    const [loading, setLoading] = useState(false);
    const [areInstructionsShown, setAreInstructionsShown] = useState(false);
    const [dictionary, setDictionary] = useState({});
    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.FAVOURITES_OPTIN, {
                FLAV_FAV_MD_STEP1: propertyTextDefaultEmpty,
                FLAV_FAV_MD_HEADER: propertyHeadingDefaultEmpty,
                FLAV_FAV_MD_STEP2: propertyTextDefaultEmpty,
                FLAV_FAV_MD_STEP3: propertyTextDefaultEmpty,
                FLAV_FAV_CONTENT: propertyTextDefaultEmptyParsed,
                FLAV_FAV_MD_CONTENT: propertyTextDefaultEmpty,
                FLAV_FAV_LINK_MANAGE_DATA: propertyTextDefaultEmpty,
                FLAV_FAV_HEADER: propertyHeadingDefaultEmpty,
                FLAV_FAV_BODY: propertyTextDefaultEmpty,
                FLAV_FAV_BTN_ENABLE: propertyCtaItemsDefaultEmpty,
                FLAV_FAV_POWERED_BY: propertyTextDefaultEmpty,
            })
        );
    }, [])

    const goToInstructions = () => {
        setAreInstructionsShown(true);
    }

    const acceptOptin = () => {
        setLoading(true);
        if (typeof onAcceptOptin === "function") {
            onAcceptOptin();
            setLoading(false);
        } else {
            Commons.acceptOptIn(thingVuseProperties.OPT_IN_FAVOURITES).then(() => {
                onClose();
                setLoading(false);
            });
        }
    }

    return (
        <CustomModal
            isOpen={isOpen}
            className={"optin-modal"}>
            {
                areInstructionsShown ?

                    <div className="page w-100">
                        <OnboardingHeader title={dictionary.FLAV_FAV_MD_HEADER} action={() => setAreInstructionsShown(false)} />
                        <div className="page-wrapper optin-instructions-wrapper">
                            <div className="optin-instructions-descr">
                                {dictionary.FLAV_FAV_MD_CONTENT}
                            </div>

                            <div className="optin-instructions-content">
                                <ol>
                                    <li><span className="bat-icon-hamburger"></span>{dictionary.FLAV_FAV_MD_STEP1}</li>
                                    <li><span className="bat-icon-chevron-right"></span>{dictionary.FLAV_FAV_MD_STEP2}</li>
                                    <li><span className="bat-icon-toggle"></span>{dictionary.FLAV_FAV_MD_STEP3}</li>
                                </ol>
                            </div>
                        </div>

                        <div className="page-linear-gradient-bg"></div>
                    </div>

                    :

                    <div className="optin-wrapper">
                        <div className="optin-header">

                            <button className="optin-header-icon on-right"
                                onClick={onDismiss}><span className="bat-icon-close"></span></button>
                        </div>
                        <div className="optin-content">
                            <div className="optin-icon"> <span className='bat-icon-star-outline'></span></div>

                            <div className="optin-title">
                                <h1>{dictionary.FLAV_FAV_HEADER}</h1>
                            </div>

                            <div className="optin-description">
                                {dictionary.FLAV_FAV_BODY}
                            </div>

                            <div className="optin-disclaimer" onClick={goToInstructions}>
                                {dictionary.FLAV_FAV_CONTENT}
                            </div>
                        </div>

                        <div className="optin-selector-cta">
                            <div className="optin-branding">
                                {dictionary.FLAV_FAV_POWERED_BY}
                                <img src={imagesConstants.MYVUSE_lOGO} style={{ height: "9px" }} alt="MUSE logo" />
                            </div>
                            <div className="d-grid">
                                <CustomLoadingButton isLoading={loading} validationMethod={null}
                                    buttonLabel={dictionary.FLAV_FAV_BTN_ENABLE_0_ctaLabel} onClickMethod={acceptOptin} />
                            </div>
                            {/*<div className="optin-url-text" onClick={() => alert("TODO")}>
                                <span className="url-text">{dictionary.FLAV_FAV_LINK_MANAGE_DATA}</span>
                            </div>*/}
                        </div>

                    </div>
            }

        </CustomModal>
    )

}
