export function getElementHeight(element) {
  return element?.getBoundingClientRect()?.height || 0;
}

export function getElementHeightWMargin(element){
  const height = element?.getBoundingClientRect()?.height || 0;
  const computedStyle = window.getComputedStyle(element);
  const margin = parseFloat(
    computedStyle?.marginTop +
    computedStyle?.marginBottom
  ) || 0;

  return height + margin;
}

export function getElementWidth(element) {
  return element?.getBoundingClientRect()?.width || 0;
}

export function getElementWidthWMargin(element){
  const width = element?.getBoundingClientRect()?.width || 0;
  const computedStyle = window.getComputedStyle(element);
  const margin = parseFloat(
    computedStyle?.marginLeft +
    computedStyle?.marginRight
  ) || 0;

  return width + margin;
}
