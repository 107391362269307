import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import StepWizard from "react-step-wizard";
import { commonsActions, getRegistration, getZip, setRegistration, userActions } from "../../../_actions";
import { getCountryLabel, getProvinceShortForm, getSupportedServices, getUrl } from "../../../_actions/appConfig.actions";
import {
  routingConstants,
  servicesConstants,
  webviewUrl,
} from "../../../_constants";
import { history } from "../../../_helpers/history";
import { buildURI } from "../../../_helpers/navigation";
import { SIGNUP_FORM_STEPS_NAMES } from "../../../_helpers/signup";
import { ContactPanel } from "../../ContactPanel";
import { WebviewModal } from "../../WebviewModal/WebviewModal";
import { BirthdayStep } from "./BirthdayStep";
import { ConsentsStep } from "./ConsentsStep/ConsentsStep";
import { ContactsStep } from "./ContactsStep";
import { EmailStep } from "./EmailStep";
import { NameStep } from "./NameStep";
import { PasswordStep } from "./PasswordStep";
import { SignupFormProvider } from "./SignupFormContext";
import { Header } from "../../Header";
import { HardAgeVerificationStep } from "./HardAgeVerificationStep";
import { store } from "../../../_store";
import { Tenants } from "../../../_helpers/tenants";
import { PrivacyPolicyInternalModal } from "../../PrivacyPolicyModal";
import { ContactsStepZA } from "./ContactsStepZA";
import { PolicyStepCA } from "./PolicyStepCA";

export const INITIAL_STEP = 1;

function SignupForm({
  formData,
  isLoading,
  registrationActiveStep,
  updateForm,
  resetForm,
  setRegistrationActiveStep,
  resetReducers,
  checkEmail,
  onSubmit,
}) {
  const [stepWizard, setStepWizard] = useState();
  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const [isPrivacyPolicyPanelOpen, setIsPrivacyPolicyPanelOpen] = useState(false);
  const [isCosPanelOpen, setIsCosPanelOpen] = useState(false);
  const [isContactPanelOpen, setIsContactPanelOpen] = useState(false);
  const [isTacPanelOpen, setIsTacPanelOpen] = useState(false);
  const [isPPIPanelOpen, setIsPPIPanelOpen] = useState(false);
  const formController = useForm({ mode: "onChange" });
  const { getValues, handleSubmit } = formController;
  const [title, setTitle] = useState("");
  const [hideBack, setHideBack] = useState(false)
  const [hideHeader, setHideHeader] = useState(false)
  const [transparent, setTransparent] = useState(false)

  const isFirstStep = () => currentStep === INITIAL_STEP;

  const isValidStep = () =>
    currentStep >= INITIAL_STEP && currentStep <= stepWizard?.totalSteps;

  const goBack = () => {
    if (isFirstStep() || !isValidStep()) {
      setRegistrationActiveStep(INITIAL_STEP);
      history.push(buildURI(routingConstants.BENEFITS));
    } else {
      if (Tenants.isSouthAfrica() && currentStep === 8) {
        setRegistrationActiveStep(5)
      } else if (Tenants.isSouthAfrica() && currentStep === 6) {
        setRegistrationActiveStep(8)
      } else if ((Tenants.isCanadaLight() || Tenants.isCanadaDark()) && currentStep === 9) {
        setRegistrationActiveStep(6)
      } else {
        setRegistrationActiveStep(stepWizard.currentStep - 1);
        stepWizard.previousStep();
      }
    }
  };

  const handleStepChange = ({ activeStep }) => {
    setCurrentStep(activeStep);
  }

  const showPrivacyPolicyPanel = () => {
    if (Tenants.isSouthAfrica()) {
      window.open(getUrl(webviewUrl.WEBVIEW_MYVUSE_PRIVACY_POLICY))
    } else {
      setIsPrivacyPolicyPanelOpen(true)
    }
  }

  const showCosPanel = () => setIsCosPanelOpen(true);

  const showContactPanel = () => setIsContactPanelOpen(true);

  const showTacPanel = () => setIsTacPanelOpen(true);

  const showPPIPanel = () => setIsPPIPanelOpen(true);

  const setSubmitData = () => {
    const registration = getRegistration()

    const data = {
      dob: registration.birthday?.day + '/' + registration.birthday?.month + '/' + registration.birthday?.year,
      password: registration.password,
      firstName: registration.firstName,
      lastName: registration.lastName,
      email: registration.email,
      userisAgeVerified: true,
      verificationMethod: registration.verificationMethod,

      address: {
        countryName: getCountryLabel(),
        zip: getZip(),
        province: getProvinceShortForm()//getProvinceLabel()
      },
    }

    store.dispatch(userActions.setRegistrationProfileData(data))

    return data
  }

  const checkAlreadyVerified = () => {
    let registration = getRegistration()
    if (registration?.isValid) {
      if (Tenants.isCanadaDark()) {
        setRegistrationActiveStep(9)
      } else {
        setRegistrationActiveStep(6)
      }
      setHideBack(true)
      setSubmitData()
    } else if (registration?.done) {
      setRegistrationActiveStep(7)
      setHideBack(true)
    } else if (registration?.goToReview) {
      registration.goToReview = null
      setRegistration(registration)

      setRegistrationActiveStep(4)
      setHideBack(false)
    }
  }

  const onSubmitDark = () => {
    onSubmit({
      ...setSubmitData(),
      ...getValues(),
    })
  }

  useEffect(() => {
    //hide back button after age verification in canada
    if ((Tenants.isCanadaLight() && currentStep === 6) || (Tenants.isCanadaDark() && currentStep === 9) ) {
      setHideBack(true)
    } else {
      setHideBack(false)
    }

    const supportedServices = getSupportedServices()
    if (!Tenants.isGlobal() && supportedServices.includes(servicesConstants.ONBOARDING_AV_YOTI) && currentStep !== 9) {
      checkAlreadyVerified()
    }
  }, [stepWizard, currentStep])

  useEffect(() => {
    if (currentStep !== registrationActiveStep) {
      stepWizard && stepWizard.goToStep(registrationActiveStep);
    }
  }, [currentStep, registrationActiveStep, stepWizard]);

  return (
    <>
      {!hideHeader && 
      <Header
        subHeader={title}
        leftButton={!hideBack ? {
          icon: <span className="bat-icon-back" />,
          onClick: goBack,
        } : null}
        forceTransparent={transparent}
      />
      }
      <div className={`signup-wrapper signup-wrapper-registration h-100 ${(currentStep === 6 || currentStep === 7 || currentStep === 9) ? "pt-0" : ""}`}>
        <SignupFormProvider
          value={{
            formData,
            isLoading,
            updateForm,
            resetForm,
            setRegistrationActiveStep,
            showPrivacyPolicyPanel,
            showCosPanel,
            showContactPanel,
            resetReducers,
            checkEmail,
            setTitle,
            showTacPanel,
            showPPIPanel
          }}
        >
          <FormProvider {...formController}>
            <form
              className="signup-form h-100"
              onSubmit={handleSubmit(() =>
                onSubmit({
                  ...formData,
                  ...getValues(),
                })
              )}
            >
              <StepWizard
                isLazyMount
                instance={(wizard) => setStepWizard(wizard)}
                initialStep={INITIAL_STEP}
                className="h-100"
                onStepChange={handleStepChange}
              >
                <EmailStep stepName={SIGNUP_FORM_STEPS_NAMES.EMAIL} />
                <PasswordStep stepName={SIGNUP_FORM_STEPS_NAMES.PASSWORD} />
                <NameStep stepName={SIGNUP_FORM_STEPS_NAMES.NAME} />
                <BirthdayStep stepName={SIGNUP_FORM_STEPS_NAMES.BIRTHDAY} />
                <ContactsStep stepName={SIGNUP_FORM_STEPS_NAMES.CONTACTS} />
                <ConsentsStep stepName={SIGNUP_FORM_STEPS_NAMES.CONSENTS} />

                {/* ADD EVERY NEW PAGE AFTER THE LAST ONE TO NOT MESS UP TO STEP NUMBER */}
                <HardAgeVerificationStep stepName={SIGNUP_FORM_STEPS_NAMES.HARD_AGE} setHideHeader={setHideHeader} setTransparent={setTransparent} onSubmit={onSubmitDark} />
                <ContactsStepZA stepName={SIGNUP_FORM_STEPS_NAMES.CONTACTSZA} />
                <PolicyStepCA stepName={SIGNUP_FORM_STEPS_NAMES.POLICYCA} />
              </StepWizard>
            </form>
          </FormProvider>
        </SignupFormProvider>
      </div>
      <div className="page-linear-gradient-bg" />
      <WebviewModal
        webviewId="ppIframe"
        webviewClassName="ppIframe"
        webviewuUrl={getUrl(webviewUrl.WEBVIEW_MYVUSE_PRIVACY_POLICY)}
        handleBack={() => setIsPrivacyPolicyPanelOpen(false)}
        isModalOpen={isPrivacyPolicyPanelOpen}
        modalClassName="webview-modal-priority"
      />
      <WebviewModal
        webviewId="cosIframe"
        webviewClassName="cosIframe"
        webviewuUrl={getUrl(webviewUrl.WEBVIEW_CONDITIONS_OF_SALE)}
        handleBack={() => setIsCosPanelOpen(false)}
        isModalOpen={isCosPanelOpen}
        modalClassName="webview-modal-priority"
      />
      <ContactPanel
        isOpen={isContactPanelOpen}
        onClose={() => setIsContactPanelOpen(false)}
      />
      <WebviewModal
        webviewId="tacIframe"
        webviewClassName="tacIframe"
        webviewuUrl={getUrl(webviewUrl.EXT_VUSE_TAC)}
        handleBack={() => setIsTacPanelOpen(false)}
        isModalOpen={isTacPanelOpen}
        modalClassName="webview-modal-priority"
      />
      <PrivacyPolicyInternalModal
        modalClassName="webview-modal-priority"
        isOpen={isPPIPanelOpen}
        onClose={() => setIsPPIPanelOpen(false)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  formData: state?.registrationReducer?.registrationProfileData,
  isLoading: state?.registrationReducer?.loading,
  registrationActiveStep: state?.registrationReducer?.activeStep,
});

const mapDispatchToProps = (dispatch) => ({
  updateForm: (values) =>
    dispatch(userActions.setRegistrationProfileData(values)),
  resetForm: () => dispatch(userActions.resetRegistrationProfileData()),
  setRegistrationActiveStep: (step) =>
    dispatch(userActions.setRegistrationActiveStep(step)),
  resetReducers: () => dispatch(commonsActions.resetReducers()),
  checkEmail: (email, successCb, failureCb) =>
    dispatch(userActions.checkEmail(email, successCb, failureCb)),
});

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupForm);

export { connectedComponent as SignupForm };
