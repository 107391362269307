
export const routingConstants = {
    ROOT_VIEW:                  '/',
    ACCESS_DENIED:              '/access-denied',
    ONBOARDING_START:           '/onboarding',
    COUNTRY_SELECTOR:           '/country-selector',
    SOFT_AGE_VERIFICATION:      '/soft-age-verification',
    HARD_AGE_VERIFICATION:      '/hard-age-verification',
    UNDERAGE:                   '/underage',
    BENEFITS:                   '/benefits',
    LOGIN:                      '/login',
    SIGN_UP:                    '/signup',
    RESET_PASSWORD:             '/reset-password',
    TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY: '/terms-and-conditions-and-privacy-policy',
    TERMS_AND_CONDITIONS:       '/terms-and-conditions',
    TERMS_AND_CONDITIONS_LIST:  '/terms-and-conditions-list',
    NOTIFICATION_INTRO:         '/notification-intro',
    NOTIFICATION_PREFERENCES:   '/notification-preferences',
    SIGNUP_FAILED:              '/signup-failed',
    DASHBOARD:                  '/dashboard',
    MORE_MENU:                  '/more-menu',

    //dashes
    DEVICE:                     '/dashboard/device',
    VUSE_WORLD:                 '/dashboard/vuse-world',
    SHOP:                       '/dashboard/shop',
    
    VAULT:                      '/vault',
    VAULT_RESTORE:              '/vault-restore',
    PAIR_DEVICE:                '/pair-device',
    SELECT_DEVICE:              '/select-device',

    LOCK_SWITCH_TUTORIAL:       '/lock-switch-tutorial',

    CLOUD_CONTROL:              '/cloud-control',
    CLOUD_CONTROL_TUTORIAL:     '/cloud-control-tutorial',

    FIND_MY_VAPE:               '/find-my-vape',
    FIND_MY_VAPE_TUTORIAL:      '/find-my-vape-tutorial',
    FIND_MY_VAPE_OPTIN:         '/find-my-vape-optin',
    FIND_MY_VAPE_OPTIN_INSTR:   '/find-my-vape-optin-instructions',

    BATTERY_SETTINGS:           '/battery-settings',
    BATTERY_SETTINGS_TUTORIAL:  '/battery-settings-tutorial',
    DEVICE_SETTINGS:            '/device-settings',
    DEVICE_NAME:                '/device-name',
    ADVANCED_SETTINGS:          '/advanced-settings',

    LOYALTY_OPTIN:              '/loyalty-optin',
    LOYALTY_DASHBOARD:          '/loyalty-dashboard',

    MANAGE_NOTIFICATIONS:       '/manage-notifications',
    MANAGE_DEVICES:             '/manage-devices',
    YOUR_PREFERENCES:           '/your-preferences',
    MANAGE_ACCOUNT:             '/account',
    EDIT_ACCOUNT:               '/edit-account',
    PREVIOUS_ORDERS:            '/orders',
    TERMS_AND_POLICY:           '/privacy',
    CONTACT:                    '/contact-us',
    TUTORIALS:                  '/tutorials',
    UNLOCK_TUTORIAL:            '/unlock-tutorial',
    DEVICE_FAQ:                 '/device-faq',
    STORE_LOCATOR:              '/store-locator',
    FAQ:                        '/faq',                

    BLOG_ARTICLES:              '/blog_articles',

    EXT_PAGE:                   '/ext-page',
    MAINTENANCE_PAGE:           '/maintenance-page',
    ACCOUNT_DELETION:           '/account-deletion',
    GPS_PP:                     '/gps-pp',

    PRIVACY_POLICY:             '/privacy-policy',
    COOKIE_POLICY:              '/cookie-policy',
    CONDITIONS_OF_SALE:         '/conditions-of-sale',
    SUBSCRIPTION_TERMS_AND_CONDITIONS: '/subscription-terms-and-conditions',

    LOCATION_SERVICES:          '/location-services',

    PUSH_NOTIFICATION_LANDING_PAGE:          '/ar',

    USAGE_TRACKER:              '/usage-tracker',
    USAGE_TRACKER_TUTORIAL:     '/usage-tracker-tutorial',
    USAGE_TRACKER_OPTIN:        '/usage-tracker-optin',
    USAGE_TRACKER_OPTIN_INSTR:  '/usage-tracker-optin-instructions',

    POD_TRACKER: '/pod-tracker',
    POD_TRACKER_TUTORIAL:     '/pod-tracker-tutorial',
    POD_TRACKER_OPTIN: '/pod-tracker-optin',
    POD_TRACKER_OPTIN_INSTR:  '/pod-tracker-optin-instructions',
}