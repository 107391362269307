import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { App } from "./App";
import { responseInterceptor } from "./_helpers/api/responseInterceptor";
import { persistor, store } from "./_store";
import { initAppData } from "./_actions/appData.actions";
import { initAppConfig } from "./_actions/appConfig.actions";
import { PersistGate } from "redux-persist/integration/react";
import environmentConstants from "./_constants/environment/environment.constants";
import { qualtricsRunner } from "./_vendors/qualtrics";
import { initializeQueue } from "./_helpers/api/axiosQueue";
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";

responseInterceptor();
initAppData();
initAppConfig();

const firebaseConfig = {
  apiKey: "AIzaSyDzxU8uaetdok3__qpq3Ee4s1aNLe0eSVE",
  authDomain: "combatmyvuseapp.firebaseapp.com",
  projectId: "combatmyvuseapp",
  storageBucket: "combatmyvuseapp.firebasestorage.app",
  messagingSenderId: "144071254757",
  appId: "1:144071254757:web:4d8dedcf00e5a7407aaa0c",
  measurementId: "G-LEG03HCX8X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);
// Initialize Analytics
getAnalytics(app);


initializeQueue(perf);

/* Array di devices in cui salvo le istance dell'Epod quando mi connetto
    {
        serialNumber: null,
        istance: null
    }
*/
window.deviceList = [];

window.loadPromise = new Promise((resolve) => {
  window.addEventListener("DOMContentLoaded", resolve);
});

window.addEventListener("qsi_js_loaded", qualtricsRunner);

if (process.env.REACT_APP_ENV_NAME === environmentConstants.PRODUCTION) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const queryString = window.location.search
const searchParams = new URLSearchParams(queryString)


for (const [key, value] of searchParams) {
  // We will have just 1 utmData param in the url
  if(key.startsWith("utmData")){
    // Decode base64
    //console.log(value);
    let decodedObj = Buffer.from(String(value), 'base64').toString()

    //console.log(decodedObj.toString('utf8'));

    // Don't want to break the app because of some problem here, I'll catch any parse problem
    try{
      // Convert to obj to see if the match_guaranteed property exists
      const obj = JSON.parse(decodedObj.toString('utf8'));
      if(obj.hasOwnProperty("+match_guaranteed")){
        // We obtain a string, but not jet ready to be passed to crm enrich or registration, but good enought to be stored
        localStorage.setItem('utm_data', decodedObj.toString('utf8'));
      }
    }catch(e){
      console.error(e);
    }
  }
}

const root = createRoot(document.getElementById("root"))
root.render(<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);