import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  setAgeVerified,
  setDoneCountrySelection,
  setDoneOnboardingBenefits,
} from "../../../_actions";
import {
  getCountry,
  getCountryLabel,
  getMobilePrefix,
  getProvinceLabel,
  isSupportedService,
  resetAppConfig,
} from "../../../_actions/appConfig.actions";
import {
  AnalyticsEvents,
  AnalyticsFieldNames,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  aemPages,
  imagesConstants,
  routingConstants,
  servicesConstants,
} from "../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../_helpers/aem/aemhelper";
import { history } from "../../../_helpers/history";
import { buildURI } from "../../../_helpers/navigation";
import { Tenants } from "../../../_helpers/tenants";
import { AddressFinder } from "../../AddressFinder";
import { useSignupFormContext } from "./SignupFormContext";
import { SignupFormStep } from "./SignupFormStep";
import { logAnalyticsEvent } from "../../../_helpers/analytics/logAnalytics";
import { CustomInlineAlert } from "../../CustomInlineAlert";

const FIELD_NAME_PHONE = "phoneNumber";
const FIELD_NAME_ADDRESS = "address";
const FIELD_NAME_SUBURB = "suburb";
const PHONE_RE = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;

export function ContactsStep(props) {
  const [dictionary, setDictionary] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [addressPage, setAddressPage] = useState();
  const { formData, resetForm, setRegistrationActiveStep, resetReducers } =
    useSignupFormContext();
  const { register, setValue, formState, getValues } = useFormContext();
  const { dirtyFields, errors } = formState;
  const mobilePrefix = useMemo(() => getMobilePrefix(), []);
  const country = useMemo(() => getCountryLabel(), []);
  const province = useMemo(() => getProvinceLabel(), []);
  const [address, setAddress] = useState(null);
  const [showManualAddress, setShowManualAddress] = useState(false);
  const [errorSuburb, setErrorSuburb] = useState(false);

  const isValidPhoneNumber = (value) => {
    const phoneNumber = value?.trim();

    switch (getCountry()) {
      case "DE":
        return phoneNumber.length >= 8 && phoneNumber.length <= 13;
      default:
        return phoneNumber.length === 10;
    }
  };

  const isValidSuburb = (value) => {
    const suburb = value?.trim()

    const result = suburb.length > 0 && suburb.length < 100

    setErrorSuburb(!result)

    return result
  }

  const handleAddressSelection = (address) => {
    setAddress(address);
    setValue(FIELD_NAME_ADDRESS, address, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const goToCountrySelector = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.START_AGAIN})
    resetAppConfig();
    resetForm();
    setRegistrationActiveStep(1);
    setDoneOnboardingBenefits(false);
    setDoneCountrySelection(false);
    setAgeVerified(false);
    resetReducers();
    history.push(buildURI(routingConstants.COUNTRY_SELECTOR));
  };

  useEffect(() => {
    if (Tenants.isSouthAfrica()) {
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_ADDRESS});
    } else {
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.SIGN_UP_PHONENUMBER_ADDRESS});
    }
    const aem = new AEMHelper();

    setDictionary({
      ...aem.getDictionary(aemPages.REGISTRATION_DETAIL_ADDR, {
        REGISTRATION_HEADER: propertyHeadingDefaultEmpty,
        REGISTRATION_FIELD_REQUIRED: propertyTextDefaultEmpty,
        REGISTRATION_PHONE_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_PHONE_PLACEHOLDER: propertyTextDefaultEmpty,
        REGISTRATION_ADDRESS_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_ADDRESS_PLACEHOLDER: propertyTextDefaultEmpty,
        REGISTRATION_COUNTRY_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_PROVINCE_LABEL: propertyTextDefaultEmpty,
        REGISTRATION_CHANGE_COUNTRY: propertyTextDefaultEmpty,
        REGISTRATION_CHANGE_COUNTRY_LINK: propertyTextDefaultEmptyParsed,
        SIGNUP_PRIVACY_POLICY_CONTENT: propertyTextDefaultEmptyParsed,
        REGISTRATION_BTN_NEXT: propertyCtaItemsDefaultEmpty,
        REGISTRATION_SUBURB_LABEL: propertyTextDefaultEmptyParsed,
        REGISTRATION_ERROR_SUBURB: propertyTextDefaultEmptyParsed,
      }),
      ...aem.getDictionary(aemPages.REGISTRATION_DETAIL_TAC, {
        REGISTRATION_CONTENT_DISCLAIMER: propertyTextDefaultEmpty,
      }),
      ...aem.getDictionary(aemPages.REGISTRATION_DETAIL_ADDR, {
        REGISTRATION_DARK_DISCL: propertyTextDefaultEmptyParsed
      })
    });

    setAddressPage(aem.getPage(aemPages.REGISTRATION_DETAIL_ADDR));

    //Disable address for demo tenant
    const isManual = isSupportedService(
      servicesConstants.ONBOARDING_ADDRESS_MANUAL
    );
    setShowManualAddress(isManual);
  }, []);

  const handleDemoAddress = (event) => {
    if (event.target.value) {
      const address = {
        "street[0]": "Alsterufer 4",
        city: "Hamburg",
        zip: "20354",
        countryName: "DE",
        province: "Hamburg",
      };

      setValue(FIELD_NAME_ADDRESS, address, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue(FIELD_NAME_ADDRESS, "", {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const onManualChangeText = (event) => {
    setAddress(null);
    setValue(FIELD_NAME_ADDRESS, null, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    register(FIELD_NAME_ADDRESS, { required: true });
  }, [register]);

  useEffect(() => {
    const phone = formData[FIELD_NAME_PHONE];
    const address = formData[FIELD_NAME_ADDRESS];
    const suburb = formData[FIELD_NAME_SUBURB];

    if (phone) {
      setValue(FIELD_NAME_PHONE, phone, { shouldDirty: true });
    }

    if (suburb) {
      setValue(FIELD_NAME_SUBURB, suburb, { shouldDirty: true });
    }

    if (address) {
      setValue(FIELD_NAME_ADDRESS, address, { shouldDirty: true });
      setAddress(formData[FIELD_NAME_ADDRESS]);
    }
  }, [formData, setValue]);

  useEffect(() => {
    let phoneValid = true
    let suburbValid = true
    if (!Tenants.isSouthAfrica()) {
      phoneValid = dirtyFields[FIELD_NAME_PHONE] && !errors[FIELD_NAME_PHONE] && getValues(FIELD_NAME_PHONE).length > 0
    } else {
      suburbValid = dirtyFields[FIELD_NAME_SUBURB] && !errors[FIELD_NAME_SUBURB] && getValues(FIELD_NAME_SUBURB).length > 0
    }

    const addressValid = dirtyFields[FIELD_NAME_ADDRESS] && !errors[FIELD_NAME_ADDRESS]

    setIsValid(
      phoneValid &&
      suburbValid &&
      addressValid
    );
  }, [formState, dirtyFields, errors]);

  const handleBlurPhone = () => {
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.PHONENUMBER})
  }

  const handleBlurSuburb = () => {
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.SUBURB})
  }

  return (
    <SignupFormStep
      title={dictionary.REGISTRATION_HEADER}
      privacyPolicyLabel={dictionary.SIGNUP_PRIVACY_POLICY_CONTENT}
      contentDisclaimer={Tenants.isCanadaDark() ? dictionary.REGISTRATION_DARK_DISCL : dictionary.REGISTRATION_CONTENT_DISCLAIMER}
      ctaLabel={dictionary.REGISTRATION_BTN_NEXT_0_ctaLabel}
      isValid={isValid}
      {...props}
    >
      <div className="signup-form-wrapper">
        {!Tenants.isSouthAfrica() &&
        <div className="signup-form-row">
          <span className="form-mandatory">
            {dictionary.REGISTRATION_FIELD_REQUIRED}
          </span>
          <label className="page-label" htmlFor={FIELD_NAME_PHONE}>
            {dictionary.REGISTRATION_PHONE_LABEL}
          </label>
          <div className="d-flex">
            {mobilePrefix && (
              <input
                className="form-control phone-placeholder"
                autoComplete="off"
                value={mobilePrefix}
                disabled
              />
            )}
            <input
              name={FIELD_NAME_PHONE}
              id={FIELD_NAME_PHONE}
              className="form-control"
              type="number"
              autoComplete="off"
              step="1"
              inputMode="numeric"
              ref={register({
                required: true,
                validate: isValidPhoneNumber,
              })}
              onBlur={handleBlurPhone}
            />
          </div>
        </div>
        }
        {showManualAddress ? (
          <div className="signup-form-row">
            <span className="form-mandatory">
              {dictionary.REGISTRATION_FIELD_REQUIRED}
            </span>
            <label className="page-label" htmlFor="registrationAddress">
              {dictionary.REGISTRATION_ADDRESS_LABEL}
            </label>
            <div className="form-address-wrapper">
              <input
                id="registrationAddress"
                className="form-control"
                name={FIELD_NAME_ADDRESS}
                placeholder={dictionary.REGISTRATION_ADDRESS_PLACEHOLDER}
                onChange={handleDemoAddress}
              />
            </div>
          </div>
        ) : (
          <AddressFinder
            onAddressSelection={handleAddressSelection}
            registrationDetailAddrPage={addressPage}
            address={address}
            onChangeText={onManualChangeText}
          />
        )}
      
        {Tenants.isSouthAfrica() &&
        <>
          <div className="signup-form-row">
            <span className="form-mandatory">
              {dictionary.REGISTRATION_FIELD_REQUIRED}
            </span>
            <label className="page-label" htmlFor={FIELD_NAME_SUBURB}>
              {dictionary.REGISTRATION_SUBURB_LABEL}
            </label>
            <div className="form-address-wrapper">
              <input
                name={FIELD_NAME_SUBURB}
                id={FIELD_NAME_SUBURB}
                className="form-control"
                type="text"
                placeholder={dictionary.REGISTRATION_SUBURB_LABEL}
                onChange={(e) => isValidSuburb(e.target.value)}
                ref={register({
                  required: true,
                  validate: isValidSuburb,
                })}
                onBlur={handleBlurSuburb}
              />
            </div>
          </div>

          <CustomInlineAlert isShown={errorSuburb} alertType="error">
            {dictionary.REGISTRATION_ERROR_SUBURB}
          </CustomInlineAlert>
        </>
        }
      </div>
      <div className="country-selector-list mt-4 mb-2">
        <div className="page-label">
          {dictionary.REGISTRATION_COUNTRY_LABEL}
        </div>
        <div className="d-grid">
          <div className="btn btn-outline-secondary static text-start">
            <span className="flag">
              {(!Tenants.isDemo()) ? 
              <img
                src={
                  imagesConstants?.[`FLAG_${getCountry()}`] ||
                  imagesConstants.FLAG_CA
                }
                alt="Canada"
              />
              : null }
            </span>
            {country}
            <span className="icon">
              <span className="bat-icon-lock" />
            </span>
          </div>
        </div>
      </div>
      {province && (
        <div className="country-selector-list mb-2">
          <div className="page-label">
            {dictionary.REGISTRATION_PROVINCE_LABEL}
          </div>
          <div className="d-grid">
            <div className="btn btn-outline-secondary static text-start">
              {province}
              <span className="icon">
                <span className="bat-icon-lock" />
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="information-block ">
        <div className="d-flex">
          <div className="flex-shrink-0">
            <span className="bat-icon-alert-circle fs-16" />
          </div>
          <div className="flex-grow-1 ms-2">
            <span className="information-block-label">
              {dictionary.REGISTRATION_CHANGE_COUNTRY}
              <br />
              <span className="url-text text-bold" onClick={goToCountrySelector}>
                {dictionary.REGISTRATION_CHANGE_COUNTRY_LINK}
              </span>
            </span>
          </div>
        </div>
      </div>
    </SignupFormStep>
  );
}
