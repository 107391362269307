import { commonsConstants, crmUserSettings, flagStatus, thingVuseProperties } from '../_constants';
import { REQUEST, SUCCESS, FAILURE } from '../_reducers/action-type.util';
import { commonsServices } from '../_services';
import { setGroupId } from '../_actions/appData.actions';
import { permissionsActions } from '../_actions/permissions.actions';

import { Commons } from '../_helpers/commons';
import { userActions } from '.';
import { getLocale } from './appConfig.actions';
import BrowserHelper from '../_helpers/browser/browser.helper';
import { UserHelper } from '../_helpers/user/user.helper';
import { debug } from '../_helpers/debug';
import { removeSalesforce } from '../_helpers/salesforcce';
import { store } from "../_store";

export const commonsActions = {
    getToken,
    getThings,
    setThings,
    deleteThing,
    getVendors,
    getGroup,
    showErrorAlert,
    getCountries,
    getCountryConfig,
    addThing,
    resetReducers,
    logout,
    setDeviceUniqueId,
    setDevices,
    setIsUpdatingThingsToIoTPlatform,

    debugCommons,
    resetDebugCommons,
    debugAnalytics,
    resetDebugAnalytics

};

function debugCommons(time, log) {
    console.log(`%c[Device]`, "color:darkorange", log);
    return { type: commonsConstants.DEBUG_COMMONS, payload: {time: time, log: log} };
}

function resetDebugCommons() {
    return { type: commonsConstants.RESET_DEBUG_COMMONS};
}

function debugAnalytics(time, log) {
    console.log(`%c[Analytics]`, "color:darkorange", log);
    return { type: commonsConstants.DEBUG_ANALYTICS, payload: {time: time, log: log} };
}

function resetDebugAnalytics() {
    return { type: commonsConstants.RESET_DEBUG_ANALYTICS};
}

function setDeviceUniqueId(id) {
    return {
        type: "SET_DEVICE_ID",
        payload: id
    }
}

function resetReducers() {
    return { type: "RESET_REDUCER", payload: null };
}

function logoutAction() {
    return (dispatch) => {
        dispatch({ type: "LOGOUT", payload: null })
        debug('Successfully logout');
    }
}

function logout() {
    return async (dispatch, getState) => {
        debug('User is logging out...');
        if (UserHelper.isUserLoggedInByStoreState(getState())) {
            const tenantUserId = Commons.generateTenantUserId(
                store.getState().onboardingReducer.userPin
            );
            await dispatch(userActions.logout(tenantUserId, async () => {
                try {
                    await dispatch(BrowserHelper.removePWATokenFromIoTPlatformAction());
                    await dispatch(permissionsActions.resetNotificationPermissionStatus());
                    removeSalesforce()
    
                    return dispatch(logoutAction());
                } catch (error) {
                    debug('ERROR: logout ', error);
                    return Promise.reject('ERROR: logout ', error);
                }
            }, (error) => {
                debug('ERROR: logout ', error);
                return Promise.reject('ERROR: logout ', error);
            })) 
        } else {
            debug('User is already logged out');
            return Promise.resolve();
        }
    }
}

/**
 * Set device infos for push notifications purpose
 * @param {*} token 
 * @returns 
 */
function setDevices(push_token){
    return async (dispatch, getState) => {
        const {
            onboardingReducer: {
                userPin
            }
        } = getState();

        const tenantUserId = Commons.generateTenantUserId(userPin);

        const device_id = tenantUserId;
        await dispatch(setDeviceUniqueId(device_id));

        debug("setDevices: device_id ", device_id, " - push_token ", push_token)
        
        const payload = {
            push_token,
            push_service: 'FIREBASE',
            device_platform: 1,
            device_id,
            device_locale: getLocale().split("_")[0],
            device_model: 'iPhone',
        };

        return commonsServices.setDevices(
            payload, 
            tenantUserId
        );
    }
}

/**
 * Get authorization token for UK services authentication 
 * @param {*} scope 
 * @param {*} successCb 
 * @param {*} failureCb 
 * @returns 
 */
function getToken(scope, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        commonsServices.getToken(scope).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST('commons/GET_TOKEN') };
    }
    function success(payload) {
        return { type: SUCCESS('commons/GET_TOKEN'), payload };
    }
    function failure(error) {
        return { type: FAILURE('commons/GET_TOKEN'), error };
    }
}

function getThings(tenantUserId, uuid, vendor, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        commonsServices.getThings(tenantUserId, uuid, vendor).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(commonsConstants.GET_THINGS) };
    }
    function success(payload) {
        return { type: SUCCESS(commonsConstants.GET_THINGS), payload };
    }
    function failure(error) {
        return { type: FAILURE(commonsConstants.GET_THINGS), error };
    }
}

function deleteThing(tenantUserId, uuid, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        commonsServices.deleteThing(tenantUserId, uuid).then(payload => {
            dispatch(success(uuid));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(commonsConstants.DELETE_THING) };
    }
    function success(payload) {
        return { type: SUCCESS(commonsConstants.DELETE_THING), payload };
    }
    function failure(error) {
        return { type: FAILURE(commonsConstants.DELETE_THING), error };
    }
}


function setThings(things, tenantUserId, uuid, vendor, successCb, failureCb, isOnboarding = false ) {
    return (dispatch) => {
        debug("[setThings] start ", things, " tenantUserId ", tenantUserId, " uuid ", uuid, " vendor ", vendor);
        dispatch(request());

        if (Commons.isValidArray(things)) {
            //for each property set its
            Promise.allSettled(things.map((thing) => {

                // console.log("[commonsServices.setThing] thing", thing);
                return commonsServices.setThing(tenantUserId, uuid, vendor, thing.type, thing.data, isOnboarding)
                    .then((response) => {
                        console.log("[commonsServices.setThing] thing success", response.data);
                        //dispatch(success(response.data));
                        dispatch(success({
                            data: {
                                property_data: thing.data,
                                property_type: thing.type,
                                uuid: uuid,
                                vendor: vendor
                            }
                        }));

                        if (
                            crmUserSettings[thing.type] !== null &&
                            crmUserSettings[thing.type] !== undefined
                        ) {

                            let payload = (thing.data === flagStatus.ACCEPTED ||  thing.data === "true" || thing.data === true).toString();
                            if(thing.type === thingVuseProperties.FAVOURITES){
                                payload = thing.data;
                            } else if (thing.type === thingVuseProperties.OPT_IN_POD_TRACKER) {
                                payload = JSON.stringify({
                                    "optin": thing.data === "accepted" ? true : false,
                                    "timestamp": (new Date()).toISOString()
                                })
                            }
                            dispatch(
                                userActions.setUserSettings(
                                    crmUserSettings[thing.type],
                                    payload
                                )
                            )
                        }

                        return response;
                    }).catch((err) => {
                        debug("ERROR: [setThing] ", err, " thing ", thing , " tenantUserId ", tenantUserId, " uuid ", uuid, " vendor ", vendor);
                        return Promise.reject(err)
                    });
            })).then((results) => {
                const successResults = results.filter(result => result.status === "fulfilled");
                const errors = results.filter(result => result.status === "rejected");

                if (errors.length > 0) {
                    debug("ERROR: [setThings] ", errors);
                    dispatch(failure(errors[0].message));
                    if (typeof failureCb === 'function') {
                        failureCb(errors[0].message);
                    }
                } else {
                    //when the last property is set and no errors where catched
                    const lastData = successResults[successResults.length - 1].value.data;
                    debug("[setThings] success upload things, last: ", lastData);
                    if (typeof successCb === 'function') {
                        successCb(lastData);
                    }
                }
                
            })
        } else {
            debug("ERROR: [setThings] things is not a valid array ", things);
            if (typeof failureCb === 'function') {
                failureCb(null);
            }
        }

    };

    function request() {
        return { type: REQUEST(commonsConstants.SET_THINGS) };
    }
    function success(payload) {
        return { type: SUCCESS(commonsConstants.SET_THINGS), payload };
    }
    function failure(error) {
        return { type: FAILURE(commonsConstants.SET_THINGS), error };
    }
}

function addThing(tenantUserId, body, successCb, failureCb) {
    return (dispatch, getState) => {
        dispatch(request());

        commonsServices.addThing(tenantUserId, body).then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(commonsConstants.ADD_THING) };
    }
    function success(payload) {
        return { type: SUCCESS(commonsConstants.ADD_THING), payload };
    }
    function failure(error) {
        return { type: FAILURE(commonsConstants.ADD_THING), error };
    }
}

/**
 * Get uuids associated to user
 * @param {*} tenantUserId 
 * @param {*} successCb 
 * @param {*} failureCb 
 * @returns 
 */
function getVendors(tenantUserId, successCb, failureCb) {
    return (dispatch) => {
        dispatch(request());

        commonsServices.getGroups(tenantUserId).then((resp) => {

            const groupId = resp?.data?.data[0]?.id;
            if (groupId) {
                setGroupId(groupId);

                commonsServices.getGroupDetails(tenantUserId, groupId).then(payload => {

                    dispatch(success(payload.data));

                    if (typeof successCb === 'function') {
                        successCb(payload.data);
                    }
                }).catch(error => {
                    dispatch(failure(error));

                    if (typeof failureCb === 'function') {
                        failureCb(error);
                    }
                });
            } else {
                dispatch(failure());

                if (typeof failureCb === 'function') {
                    failureCb();
                }
            }
        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });

    };

    function request() {
        return { type: REQUEST(commonsConstants.GET_VENDORS) };
    }
    function success(payload) {
        return { type: SUCCESS(commonsConstants.GET_VENDORS), payload };
    }
    function failure(error) {
        return { type: FAILURE(commonsConstants.GET_VENDORS), error };
    }
}

function getGroup(tenantUserId, successCb, failureCb) {
    return (dispatch) => {

        dispatch(request());

        commonsServices.getGroups(tenantUserId).then(payload => {

            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }

        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(commonsConstants.GET_GROUP) };
    }
    function success(payload) {
        return { type: SUCCESS(commonsConstants.GET_GROUP), payload };
    }
    function failure(error) {
        return { type: FAILURE(commonsConstants.GET_GROUP), error };
    }
}

function showErrorAlert(payload) {
    return { type: commonsConstants.SHOW_ERROR, payload };
}

function getCountries(successCb, failureCb) {
    return (dispatch) => {
        dispatch(request());

        commonsServices.getCountries().then(payload => {
            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }

        }).catch(error => {
            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(commonsConstants.GET_COUNTRIES) };
    }
    function success(payload) {
        return { type: SUCCESS(commonsConstants.GET_COUNTRIES), payload };
    }
    function failure(error) {
        return { type: FAILURE(commonsConstants.GET_COUNTRIES), error };
    }
}

function getCountryConfig(tenantId, successCb, failureCb) {
    return (dispatch) => {
        dispatch(request());

        commonsServices.getCountryConfig(tenantId).then(payload => {

            dispatch(success(payload.data));

            if (typeof successCb === 'function') {
                successCb(payload.data);
            }

        }).catch(error => {

            dispatch(failure(error));

            if (typeof failureCb === 'function') {
                failureCb(error);
            }
        });
    };

    function request() {
        return { type: REQUEST(commonsConstants.GET_COUNTRIES) };
    }
    function success(payload) {
        return { type: SUCCESS(commonsConstants.GET_COUNTRIES), payload };
    }
    function failure(error) {
        return { type: FAILURE(commonsConstants.GET_COUNTRIES), error };
    }
}


function setIsUpdatingThingsToIoTPlatform(toggle) {
    return {
        type: commonsConstants.SET_IS_UPDATING_THINGS_TO_IOT_PLATFORM,
        payload: toggle
    };
}
