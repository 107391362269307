import React, { forwardRef, useEffect, useState } from "react";
import { getNavbar } from "../../_actions/appConfig.actions";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { aemPages, dashboardTabsConstants, flagStatus, thingVuseProperties } from "../../_constants";
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, AnalyticsTargetEventsNames, routingConstants, AnalyticsScreenEventNames} from '../../_constants';
import { Commons } from "../../_helpers/commons";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";

export const NavBar = forwardRef((props, ref) => {
  const [activeTab, setActiveTab] = useState("device");
  const [navbar, setNavbar] = useState([]);
  const [dictionary, setDictionary] = useState({});

  useEffect(() => {
    setNavbar(getNavbar());

    const aem = new AEMHelper();
    setDictionary(
      aem.getDictionary(aemPages.HOMEPAGE, {
        HOME_MENU_VUSEWORLD: propertyTextDefaultEmpty,
        HOME_MENU_STORE: propertyTextDefaultEmpty,
        HOME_MENU_ACCOUNT: propertyTextDefaultEmpty,
        HOME_MENU_MORE: propertyTextDefaultEmpty,
        HOME_MENU_ULTRA_POD_TRACKER: propertyTextDefaultEmpty
      })
    );

    const hash = window.location.hash.replace('#', '')
    if (hash) {
      setActiveTab(hash)
    }
  }, []);

  useEffect(() => {
    if (props.selected) {
      if (props.selected === 'store-locator') {
        window.history.replaceState({ selected: false }, '');
        goToBuyNow()
      }
    }
  }, [props.selected])

  const goToBuyNow = (e) => {
    setActiveTab("store-locator");
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.STORE_LOCATOR});
    props.onNavigate(dashboardTabsConstants.STORE_LOCATOR, 0);
  };

  const goToVuseWorld = (e) => {
    setActiveTab("vuseworld");
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.VUSE_WORLD});
    props.onNavigate(dashboardTabsConstants.VUSE_WORLD);
  };

  const goToDevice = (e) => {
    setActiveTab("device");
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.HOME});
    props.onNavigate(dashboardTabsConstants.DEVICE);
  };

  const goToAccount = (e) => {
    setActiveTab("account");
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ACCOUNT});
    props.onNavigate(dashboardTabsConstants.ACCOUNT);
  };

  const goToMore = (e) => {
    setActiveTab("more");
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.MORE});
    props.onNavigate(dashboardTabsConstants.MENU);
  };

  const goToPodTracker = (e) => {

    //logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.MORE});
    logAnalyticsEvent(
      AnalyticsEvents.SCREEN_VIEW,
      {screen_name: AnalyticsScreenEventNames.POD_TRACKER}
    );

    Commons.goToFeaturePage({
      thingProperty: thingVuseProperties.OPT_IN_POD_TRACKER,
      onOptinDone: () => {
        setActiveTab("pod-tracker");
        props.onNavigate(dashboardTabsConstants.POD_TRACKER)
      },
      onOptinUndone: () => {
        setActiveTab("pod-tracker-optin");
        Commons.goToFeaturePage({
          thingProperty: thingVuseProperties.POD_TRACKER,
          onOptinDone: () => props.onNavigate(dashboardTabsConstants.POD_TRACKER_OPTIN),
          onOptinUndone: () => history.push(buildURI(routingConstants.POD_TRACKER_TUTORIAL))
        })
      }
    });

   /* const optedIn = Commons.getCustomerProperty(
      thingVuseProperties.OPT_IN_POD_TRACKER
    );

    if (optedIn !== flagStatus.ACCEPTED) {
      setActiveTab("pod-tracker-optin")
      //props.onNavigate(dashboardTabsConstants.POD_TRACKER_OPTIN)
      history.push(buildURI(routingConstants.POD_TRACKER_TUTORIAL));
    } else {
      setActiveTab("pod-tracker")
      props.onNavigate(dashboardTabsConstants.POD_TRACKER)
    }*/
  };

  useEffect(() => {
    if (props.isMoreMenuOpen) {
      setActiveTab("more")
    }
  }, [props.isMoreMenuOpen]);

  const parseNewLine = ({ text }) => {
    const formattedText = text?.split('\\n').map((line, index, array) => 
      index === array.length - 1 ? line : (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      )
    );
  
    return <>{formattedText}</>;
  };

  return (
    <div ref={ref} className="dashboard-navbar">
      {/*$dashboard-navigator*/}
      <div className="dashboard-navbar-buttons">
        {(navbar.includes("POD_TRACKER")) && (
          <div
            className={`dashboard-navbar-button ${
              (activeTab === "pod-tracker" || activeTab === "pod-tracker-optin") ? "active" : ""
            }`}
            onClick={goToPodTracker}
          >
            <img
              src={require(`../../_assets/img/navbar/UltraPodHistory_${
                activeTab === "pod-tracker" ? "Active" : "Inactive"
              }.svg`)}
              alt={dictionary.HOME_MENU_ULTRA_POD_TRACKER}
            />
            <div className="label">{parseNewLine({text:dictionary.HOME_MENU_ULTRA_POD_TRACKER})}</div>
          </div>
        )}
        {(navbar.includes("VUSE_WORLD")) && (
          <div
            className={`dashboard-navbar-button ${
              activeTab === "vuseworld" ? "active" : ""
            }`}
            onClick={goToVuseWorld}
          >
            <img
              src={require(`../../_assets/img/navbar/VuseWorld_${
                activeTab === "vuseworld" ? "Active" : "Inactive"
              }.svg`)}
              alt={dictionary.HOME_MENU_VUSEWORLD}
            />
            <div className="label">{parseNewLine({text:dictionary.HOME_MENU_VUSEWORLD})}</div>
          </div>
        )}
        {(navbar.includes("STORE_LOCATOR_AND_SHOP") ||
          navbar.includes("STORE_LOCATOR") ||
          navbar.includes("ONLY_STORE")) && (
          <div
            className={`dashboard-navbar-button ${
              activeTab === "store-locator" ? "active" : ""
            }`}
            onClick={goToBuyNow}
          >
            <img
              src={require(`../../_assets/img/navbar/Store_${
                activeTab === "store-locator" ? "Active" : "Inactive"
              }.svg`)}
              alt={dictionary.HOME_MENU_STORE}
            />
            <div className="label">{parseNewLine({text:dictionary.HOME_MENU_STORE})}</div>
          </div>
        )}
        {(navbar.findIndex(e => e == "ACCOUNT") >-1 && navbar.findIndex(e => e == "ACCOUNT") < 2) && (
          <div
            className={`dashboard-navbar-button ${
              activeTab === "account" ? "active" : ""
            }`}
            onClick={goToAccount}
          >
            <img
              src={require(`../../_assets/img/navbar/Account_${
                activeTab === "account" ? "Active" : "Inactive"
              }.svg`)}
              alt={dictionary.HOME_MENU_ACCOUNT}
            />
            <div className="label">{parseNewLine({text:dictionary.HOME_MENU_ACCOUNT})}</div>
          </div>
        )}
        <div
          className={`dashboard-navbar-button btn-center ${
            activeTab === "device" ? "active" : ""
          }`}
          onClick={goToDevice}
        >
          <img
            src={require(`../../_assets/img/navbar/MYVUSE_Active_Inactive.svg`)}
            alt="Device"
            className="device"
          />
        </div>
        {(navbar.findIndex(e => e == "ACCOUNT") >-1 && navbar.findIndex(e => e == "ACCOUNT") > 1) && (
          <div
            className={`dashboard-navbar-button ${
              activeTab === "account" ? "active" : ""
            }`}
            onClick={goToAccount}
          >
            <img
              src={require(`../../_assets/img/navbar/Account_${
                activeTab === "account" ? "Active" : "Inactive"
              }.svg`)}
              alt={dictionary.HOME_MENU_ACCOUNT}
            />
            <div className="label">{parseNewLine({text:dictionary.HOME_MENU_ACCOUNT})}</div>
          </div>
        )}
        <div
          className={`dashboard-navbar-button ${
            activeTab === "more" ? "active" : ""
          }`}
          onClick={goToMore}
        >
          <img
            src={require(`../../_assets/img/navbar/MoreMenu_${
              activeTab === "more" ? "Active" : "Inactive"
            }.svg`)}
            alt={dictionary.HOME_MENU_MORE}
          />
          <div className="label">{parseNewLine({text:dictionary.HOME_MENU_MORE})}</div>
        </div>
      </div>
    </div>
  );
});
