import { routingConstants, webviewUrl } from '../../_constants';
import { getAccessToken } from '../../_actions/appData.actions';
import _ from 'lodash';
import { getUrl } from '../../_actions/appConfig.actions';
import { convertObjectToQueryString } from '../utils';
import { history } from '../history';
import { buildURI } from '../navigation';
import { shopServices } from '../../_services/shop.services'
import { Commons } from '../commons';

export class Webview {

    static getMyAccountUrl(token) {
        return `${getUrl(webviewUrl.WEBVIEW_MY_ACCOUNT)}?token=${token}`;
    }

    static getRecentOrdersUrl() {
        return `${getUrl(webviewUrl.WEBVIEW_PAST_ORDER)}?token=${getAccessToken()}`;
    }

    static getMySubscriptionsUrl() {
        return `${getUrl(webviewUrl.SUBSCRIPTION_EDIT)}?token=${getAccessToken()}`;
    }
    
    static getNewSubscriptionUrl() {
        return `${getUrl(webviewUrl.SUBSCRIPTION_START)}?token=${getAccessToken()}`;
    }

    static goToMyVusePrivacyPolicy(canHandleBack = true, backToSignup = false, yotiFlow = false) {
        if (canHandleBack) {
            history.push(buildURI(routingConstants.PRIVACY_POLICY) + convertObjectToQueryString({ canHandleBack: true, yotiFlow }))
        } else {
            Commons.goTo(routingConstants.PRIVACY_POLICY, {
                canClose: true,
                backToSignup
            })
        }
    }

    static async getPurchaseUrl(basket, analyticsToken) {
        let base64Basket = '';
        if (basket) {
            const variants = _.compact(basket.map(variant => {
                if (variant.basket > 0) {
                    return ({
                        "id": variant.variant_shopify_id.split('/').pop(),
                        "quantity": variant.basket
                    });
                }else {
                    return null;
                }
            }));
            base64Basket = Buffer.from(JSON.stringify({ variants: variants }), 'utf8').toString('base64');
        }     
        
        let tokenResponse = await shopServices.getOneTimeToken();
        let token = tokenResponse?.data?.oneTimeAuthToken ?? null;
        
        let url = `${getUrl(webviewUrl.WEBVIEW_CHECKOUT)}?`
            + `token=${token}` //getAccessToken()
            //+ `token=${getAccessToken()}`
            + `&utm_medium=myvuseapp`
            + `&utm_campaign=shop`
            //+ `&reqOrigin=myvuse`
            + `&analyticsToken=${analyticsToken}`;
        url += (null!==base64Basket && ''!==base64Basket)?`&addToCart=${base64Basket}`:'';

        return url;
    }

    static getDeleteAccountUrl(token = false) {
        if (token) {
            return `${getUrl(webviewUrl.WEBVIEW_ACCOUNT_DELETION)}?token=${token}`;
        } else {
            return `${getUrl(webviewUrl.WEBVIEW_ACCOUNT_DELETION)}`;
        }
    }

    static getDownloadDataUrl() {
        return `${getUrl(webviewUrl.WEBVIEW_DATA_DOWNLOAD)}`;
    }
}
