import { getAEMAssetsUrl } from "../../_actions/appConfig.actions";
import { PROFILE_EPOD3, PROFILE_SMARTBOX } from "../../_constants";
import { AEMHelper } from "../aem/aemhelper";

export function getTutorialComponentName(deviceType, componentName) {
  if (deviceType === PROFILE_EPOD3) {
    return componentName + "_PROSMART";
  } 
  
  if (deviceType === PROFILE_SMARTBOX) {
    return componentName + "_MIMOSA";
  }

  return componentName;
}

export function getTutorialsVideo(aemPage, deviceType, components) {
  const aem = new AEMHelper();
  const tutorialPage = aem.getPage(aemPage);

  return components.map((componentName) => {
    const name = getTutorialComponentName(deviceType, componentName);
    const aemComponent = tutorialPage.getComponent(aemPage, name);

    return `${getAEMAssetsUrl()}${
      aemComponent?.component?.imageSrc?.desktop?.src || ""
    }`;
  });
}

export function getAEMCarouselFor(deviceType, aemPage) {
   
}