import { isNil } from "lodash";
import moment from "moment";
import {
  dashboardActions
} from "../../_actions";
import { commonsServices } from "../../_services";
import { store } from "../../_store";
import { Commons } from "../commons";
import { addCollection, getDbInstance } from "../utils";
import {
  DailyCollectionName,
  StatsCollectionName,
  HourlyCollectionName,
  PodsCollectionName
} from "../utils/initializeDb";
import JSZip from "jszip";
import CryptoJS from "crypto-js";

export async function getExportedDb() {
  try {
    const db = await getDbInstance();

    const daily = await db[DailyCollectionName].exportJSON();
    console.log("[getExportedDb] daily", JSON.stringify(daily));

    const stats = await db[StatsCollectionName].exportJSON();
    console.log("[getExportedDb] stats", JSON.stringify(stats));

    const hourly = await db[HourlyCollectionName].exportJSON();
    console.log("[getExportedDb] hourly", JSON.stringify(hourly));

    const pods = await db[PodsCollectionName].exportJSON();
    console.log("[getExportedDb] pods", JSON.stringify(pods));

    return [JSON.stringify(daily), JSON.stringify(stats), JSON.stringify(hourly), JSON.stringify(pods)];
  } catch (e) {
    console.log("[getExportedDb] error", e.message);
  }
}

export function sendCalculatedData(forceSending = false, toRemove = false) {
  return new Promise(async (resolve, reject) => {
    const lastCalcDataPublishTs =
          store.getState().dashboardReducer?.usageTracker?.calcDataPublishTs;

    if (isNil(lastCalcDataPublishTs) && !forceSending) {
      store.dispatch(
        dashboardActions.setUsageTrackerCalcDataPublishTs(
          `${moment().valueOf()}`
        )
      );
      resolve()
    }

    const hours = moment().diff(
      moment(parseInt(lastCalcDataPublishTs)),
      "h"
    );

    console.debug("moment: ", moment().valueOf());
    console.debug("lastCalcDataPublishTs: ", lastCalcDataPublishTs);
    console.debug("hours: ", hours);

    if (forceSending || hours >= 24) {
      const userPin = store.getState().onboardingReducer.userPin;
      const tenantUserId = Commons.generateTenantUserId(userPin);

      console.debug("userPin", userPin);
      console.debug("tenantUserId", tenantUserId);

      const filename = `${tenantUserId}.zip`;
      const zip = new JSZip();

      const [jsonDailyDb, jsonStatsDb, jsonHourlyDb, jsonPodsDb] = await getExportedDb();

      if (toRemove !== 'usage') {
        console.debug("jsonDailyDb", jsonDailyDb);
        console.debug("jsonStatsDb", jsonStatsDb);
        console.debug("jsonHourlyDb", jsonHourlyDb);

        const jsonDailyDbEncrypted = CryptoJS.AES.encrypt(jsonDailyDb, `${userPin}`);
        const jsonStatsDbEncrypted = CryptoJS.AES.encrypt(jsonStatsDb, `${userPin}`);
        const jsonHourlyDbEncrypted = CryptoJS.AES.encrypt(jsonHourlyDb, `${userPin}`);

        console.debug("jsonDailyDbEncrypted", jsonDailyDbEncrypted);
        console.debug("jsonStatsDbEncrypted", jsonStatsDbEncrypted);
        console.debug("jsonHourlyDbEncrypted", jsonHourlyDbEncrypted);

        zip.file("daily.json", jsonDailyDbEncrypted.toString());
        zip.file("stats.json", jsonStatsDbEncrypted.toString());
        zip.file("hourly.json", jsonHourlyDbEncrypted.toString());
      }

      if (toRemove !== 'pod') {
        console.debug("jsonPodsDb", jsonPodsDb);

        const jsonPodsDbEncrypted = CryptoJS.AES.encrypt(jsonPodsDb, `${userPin}`);

        console.debug("jsonPodsDbEncrypted", jsonPodsDbEncrypted);

        zip.file("pods.json", jsonPodsDbEncrypted.toString());
      }

      zip
        .generateAsync({ type: "blob" })
        .then((content) => {

          console.debug("SEND_CALC_DATA", content);
          /* Send zip to BE */
          commonsServices.sendCalculatedData(filename, content, tenantUserId).then(() => {
            resolve()
          })
        })
        .catch((error) => {
          console.error("Errore durante la creazione del file zip:", error);
          reject()
        });
    } else {
      resolve()
    }
  })
}

export async function importDailyDataFromJson(db, data) {
  if (!data) {
    return;
  }

  if (db[DailyCollectionName]) {
    await db[DailyCollectionName].remove();
  }

  await addCollection(db, DailyCollectionName);
  const d = JSON.parse(data);
  console.debug("BE_DATA_DAILY", d);
  // We need to override the old hashSchema because when we remove a collection and then we recreate
  // a new one, we get an error during the import: "the imported json relies on a different schema".
  d.schemaHash = await db[DailyCollectionName].schema.hash;
  db[DailyCollectionName].importJSON(d);
}

export async function importStatsDataFromJson(db, data) {
  if (!data) {
    return;
  }

  if (db[StatsCollectionName]) {
    await db[StatsCollectionName].remove();
  }

  await addCollection(db, StatsCollectionName);
  const d = JSON.parse(data);
  console.debug("BE_DATA_STATS", d);
  // We need to override the old hashSchema because when we remove a collection and then we recreate
  // a new one, we get an error during the import: "the imported json relies on a different schema".
  d.schemaHash = await db[StatsCollectionName].schema.hash;
  db[StatsCollectionName].importJSON(d);
}

export async function importHourlyDataFromJson(db, data) {
  if (!data) {
    return;
  }

  if (db[HourlyCollectionName]) {
    await db[HourlyCollectionName].remove();
  }

  await addCollection(db, HourlyCollectionName);
  const d = JSON.parse(data);
  console.debug("BE_DATA_HOURLY", d);
  // We need to override the old hashSchema because when we remove a collection and then we recreate
  // a new one, we get an error during the import: "the imported json relies on a different schema".
  d.schemaHash = await db[HourlyCollectionName].schema.hash;
  db[HourlyCollectionName].importJSON(d);
}

export async function importPodsDataFromJson(db, data) {
  if (!data) {
    return;
  }

  if (db[PodsCollectionName]) {
    await db[PodsCollectionName].remove();
  }

  await addCollection(db, PodsCollectionName);
  const d = JSON.parse(data);
  console.debug("BE_DATA_PODS", d);
  // We need to override the old hashSchema because when we remove a collection and then we recreate
  // a new one, we get an error during the import: "the imported json relies on a different schema".
  d.schemaHash = await db[PodsCollectionName].schema.hash;
  db[PodsCollectionName].importJSON(d);
}

export async function getCalculatedData() {
  const userPin = store.getState().onboardingReducer.userPin;
  const tenantUserId = Commons.generateTenantUserId(userPin);

  const filename = `${tenantUserId}.zip`;
  const [zipResponse, zipError] = await commonsServices.getCalculatedData(
    filename,
    tenantUserId
  );

  if (!isNil(zipResponse)) {
    console.debug("[zip]zipResponse:", zipResponse);

    JSZip.loadAsync(zipResponse).then((zips) => {
      zips.forEach((relativePath, file) => {
        console.debug("[zip]relativePath:", relativePath);
        console.debug("[zip]file:", file);

        if (!file.dir) {
          file.async("string").then(async (content) => {
            const decryptedContent = CryptoJS.AES.decrypt(
              content,
              `${userPin}`
            );
            console.debug("***********");
            console.debug(
              "decryptedContent",
              decryptedContent.toString(CryptoJS.enc.Utf8)
            );

            const db = await getDbInstance();

            if (file.name.includes("daily")) {
              await importDailyDataFromJson(
                db,
                decryptedContent.toString(CryptoJS.enc.Utf8)
              );
            } else if (file.name.includes("stats")) {
              await importStatsDataFromJson(
                db,
                decryptedContent.toString(CryptoJS.enc.Utf8)
              );
            } else if (file.name.includes("hourly")) {
              await importHourlyDataFromJson(
                db,
                decryptedContent.toString(CryptoJS.enc.Utf8)
              );
            } else if (file.name.includes("pods")) {
              await importPodsDataFromJson(
                db,
                decryptedContent.toString(CryptoJS.enc.Utf8)
              );
            }
          });
        }
      });
    });
  }
}


export async function updateDb() {
  const db = await getDbInstance();

  console.debug("DB_INSTANCE_UPDATE", db);

  const query = db[DailyCollectionName].find({
    sort: [{ lastPuffTimestamp: "desc" }],
    limit: 1,
  });
  const results = await query.exec();

  console.debug("UPDATE_DB_RES", results);

  if (results.length <= 0) {
    getCalculatedData();
  }
}

export async function removeDb() {
  const db = await getDbInstance();
  await db.remove();
}

export async function updateCalculatedData() {
  const db = await getDbInstance();

  console.debug("UPDATE_CALCULATED_DATA");
  console.debug("UPDATE_CALCULATED_DATA_DB", db);
  console.debug(
    "UPDATE_CALCULATED_DATA_COLLECTIONS",
    db[DailyCollectionName],
    db[StatsCollectionName],
    db[HourlyCollectionName],
    db[PodsCollectionName]
  );

  if (db[DailyCollectionName] && db[StatsCollectionName] && db[HourlyCollectionName] && db[PodsCollectionName]) {
    await sendCalculatedData(true);
  }
}

export async function deleteCalculatedData() {
  const userPin = store.getState().onboardingReducer.userPin;
  const tenantUserId = Commons.generateTenantUserId(userPin);

  const filename = `${tenantUserId}.zip`;

  await commonsServices.deleteCalculatedData(filename, tenantUserId);
}