import { create, isFunction, isNil, omit } from "lodash";
import moment from "moment";
import { commonsActions, getRequestActions, createRequest } from "../_actions";
import {
  CUSTOMER,
  flagStatus,
  loginConstants,
  onboardingConstants,
  registrationConstants,
  thingVuseProperties,
} from "../_constants";
import * as Uri from "../_constants/api/uri.constants";
import { Commons } from "../_helpers/commons";
import { Tenants } from "../_helpers/tenants";
import { RegisterModel } from "../_models";
import { FAILURE, REQUEST, SUCCESS } from "../_reducers/action-type.util";
import { commonsServices, userServices } from "../_services";
import { store } from "../_store";
import { getMobilePrefix, isCurrentCountryCanada, isCurrentCountryGermany, isCurrentCountrySouthAfrica } from "./appConfig.actions";
import { getUserData, getVuseUuid, setUserData } from "./appData.actions";

export const userActions = {
  login,
  register,
  resetPasswordUk, //UK
  resetPasswordCa, //CA
  getConfirmationLink, //UK
  getCustomerData, //UK
  getAddresses, //CA
  getAddressDetails, //CA
  setRegistrationProfileData, //LOCAL STUFF
  getUserInfo, //CA
  updateUserInfo, //CA
  setUserSettings, //CA
  resetRegistrationProfileData,
  setRegistrationActiveStep,

  // NEW
  checkEmail,
  resetPassword,
  resendActivationLink,
  myVuseCreate,
  getSalesforceId,
  logout
};

function login2(formData, successCb, failureCb) {
  const loginAction = isCurrentCountryCanada() ? loginCa : loginUk;

  return loginAction(formData, successCb, failureCb);
}

function loginUk(formData, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    /** 1. get JWT token */
    commonsServices
      .getToken(Uri.tokenScope.LOGIN)
      .then((payload) => {
        var response = payload.data;
        if (response.access_token) {
          /** 2. get user token */
          userServices
            .loginUk(response.access_token, formData)
            .then((payload) => {
              const userToken = payload.data;

              /** 3. get JWT token */
              commonsServices
                .getToken(Uri.tokenScope.PROFILE)
                .then((payload) => {
                  var response = payload.data;
                  if (response.access_token) {
                    /** 4. get customer data */
                    userServices
                      .getCustomerData(response.access_token, userToken)
                      .then((payload) => {
                        dispatch(
                          success({ token: userToken, userData: payload.data })
                        );

                        if (typeof successCb === "function") {
                          successCb({
                            token: userToken,
                            userData: payload.data,
                          });
                        }
                      })
                      .catch((error) => {
                        dispatch(failure(error));

                        if (typeof failureCb === "function") {
                          failureCb(error);
                        }
                      });
                  }
                })
                .catch((error) => {
                  dispatch(failure(error));

                  if (typeof failureCb === "function") {
                    failureCb(error);
                  }
                });
            })
            .catch((error) => {
              dispatch(failure(error));

              if (typeof failureCb === "function") {
                failureCb(error);
              }
            });
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(loginConstants.LOGIN) };
  }
  function success(payload) {
    return { type: SUCCESS(loginConstants.LOGIN), payload };
  }
  function failure(error) {
    return { type: FAILURE(loginConstants.LOGIN), error };
  }
}

function loginCa(formData, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    userServices
      .loginCa(formData)
      .then((payload) => {
        dispatch(
          success({ token: payload.data.token, userData: payload.data.user })
        );

        if (typeof successCb === "function") {
          successCb({ token: payload.data.token, userData: payload.data.user });
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(loginConstants.LOGIN) };
  }
  function success(payload) {
    return { type: SUCCESS(loginConstants.LOGIN), payload };
  }
  function failure(error) {
    return { type: FAILURE(loginConstants.LOGIN), error };
  }
}

function resetPasswordCa(formData, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    userServices
      .forgotPasswordCA(formData)
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data.access);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(loginConstants.RESET_PASSWORD) };
  }
  function success(payload) {
    return { type: SUCCESS(loginConstants.RESET_PASSWORD), payload };
  }
  function failure(error) {
    return { type: FAILURE(loginConstants.RESET_PASSWORD), error };
  }
}

function resetPasswordUk(formData, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .getToken(Uri.tokenScope.FORGOT_PASSWORD)
      .then((payload) => {
        var response = payload.data;
        if (response.access_token) {
          userServices
            .forgotPassword(response.access_token, formData)
            .then((payload) => {
              dispatch(success(payload.data));

              if (typeof successCb === "function") {
                successCb("true");
              }
            })
            .catch((error) => {
              dispatch(failure(error));

              if (typeof failureCb === "function") {
                failureCb(error);
              }
            });
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(loginConstants.RESET_PASSWORD) };
  }
  function success(payload) {
    return { type: SUCCESS(loginConstants.RESET_PASSWORD), payload };
  }
  function failure(error) {
    return { type: FAILURE(loginConstants.RESET_PASSWORD), error };
  }
}

function getConfirmationLink(formData, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    commonsServices
      .getToken(Uri.tokenScope.FORGOT_PASSWORD)
      .then((payload) => {
        var response = payload.data;
        if (response.access_token) {
          userServices
            .getConfirmationLink(response.access_token, formData)
            .then((payload) => {
              dispatch(success(payload.data));

              if (typeof successCb === "function") {
                successCb(payload.data);
              }
            })
            .catch((error) => {
              dispatch(failure(error));

              if (typeof failureCb === "function") {
                failureCb(error);
              }
            });
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(loginConstants.GET_CONFIRMATION_LINK) };
  }
  function success(payload) {
    return { type: SUCCESS(loginConstants.GET_CONFIRMATION_LINK), payload };
  }
  function failure(error) {
    return { type: FAILURE(loginConstants.GET_CONFIRMATION_LINK), error };
  }
}

function getCustomerData(JWTtoken, token, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    userServices
      .getCustomerData(JWTtoken, token)
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(loginConstants.GET_CUSTOMER_DATA) };
  }
  function success(payload) {
    return { type: SUCCESS(loginConstants.GET_CUSTOMER_DATA), payload };
  }
  function failure(error) {
    return { type: FAILURE(loginConstants.GET_CUSTOMER_DATA), error };
  }
}

/**
 * Get signup method based on users country.
 * Get payload for registration method based on country.
 *
 * @param {*} formData
 * @returns
 */
function register(formData, successCb, failureCb) {
  const registerAction = isCurrentCountryCanada() ? registerCa : registerUk;

  return registerAction(formData, successCb, failureCb);
}

function registerUk(formData, successCb, failureCb) {
  const registrationModel = new RegisterModel();
  const registerData = registrationModel.getUKPayload({
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    gender: formData.gender,
    passwor: formData.password,
    dob: moment(formData.dob, "DD/MM/YYYY").format("YYYY-MM-DD"),
    //address
    city: formData.address.city,
    country: formData.address.country,
    company: formData.address.company,
    postalCode: formData.address.zip,
    street: [
      formData.address["street[0]"],
      formData.address["street[1]"],
      formData.address["street[2]"],
    ],
    phoneNumber: formData.phoneNumber,

    consent_email_marketing: formData.consent_email_marketing,
    consent_mobile: formData.consent_mobile,
    termsconditions: formData.termsconditions,
  });

  return (dispatch) => {
    dispatch(request());
    commonsServices.getToken(Uri.tokenScope.SIGNUP).then((payload) => {
      console.log("payload?.data?.access_token");
      console.log(payload?.data?.access_token);
      if (payload?.data?.access_token) {
        userServices
          .register(payload.data.access_token, registerData)
          .then((payload) => {
            dispatch(success(payload.data));

            if (typeof successCb === "function") {
              successCb(payload.data);
            }
          })
          .catch((error) => {
            dispatch(failure(error));

            if (typeof failureCb === "function") {
              failureCb(error);
            }
          });
      }
    });
  };

  function request() {
    return { type: REQUEST(registrationConstants.REGISTER_USER) };
  }
  function success(payload) {
    return { type: SUCCESS(registrationConstants.REGISTER_USER), payload };
  }
  function failure(error) {
    return { type: FAILURE(registrationConstants.REGISTER_USER), error };
  }
}

function registerCa(formData, successCb, failureCb) {
  const registrationModel = new RegisterModel();
  const registerData = registrationModel.getCAPayload({
    address: formData.address["street[0]"],
    city: formData.address.city,
    consent: true,
    country: isCurrentCountryGermany() ? formData.address.country : formData.address.countryName,
    dob: moment(formData.dob, "DD/MM/YYYY").format("YYYY/MM/DD"),
    email: formData.email,
    emailSubscription: formData.consent_email_marketing,
    smsSubscription: formData.consent_mobile_marketing,
    firstName: formData.firstName,
    geocodedAddress: true,
    lastName: formData.lastName,
    password: formData.password,
    postalCode: formData.address.zip,
    province: formData.address.province,
    myVuseEverLogged: false,
    phoneNumber: formData.phoneNumber,
  });

  return (dispatch) => {
    dispatch(request());

    userServices
      .CAregister(registerData)
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(registrationConstants.REGISTER_USER) };
  }
  function success(payload) {
    return { type: SUCCESS(registrationConstants.REGISTER_USER), payload };
  }
  function failure(error) {
    return { type: FAILURE(registrationConstants.REGISTER_USER), error };
  }
}

function checkEmail2(formData, successCb, failureCb) {
  const checkEmailAction = isCurrentCountryCanada()
    ? checkEmailCa
    : checkEmailUk;

  return checkEmailAction(formData, successCb, failureCb);
}

function checkEmailUk(formData, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());
    userServices
      .checkEmail(formData)
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(registrationConstants.CHECK_EMAIL) };
  }
  function success(payload) {
    return { type: SUCCESS(registrationConstants.CHECK_EMAIL), payload };
  }
  function failure(error) {
    return { type: FAILURE(registrationConstants.CHECK_EMAIL), error };
  }
}

function checkEmailCa(formData, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());
    userServices
      .CAcheckEmail(formData)
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(registrationConstants.CHECK_EMAIL) };
  }
  function success(payload) {
    return { type: SUCCESS(registrationConstants.CHECK_EMAIL), payload };
  }
  function failure(error) {
    return { type: FAILURE(registrationConstants.CHECK_EMAIL), error };
  }
}

function getAddresses(searchValue, lastId, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    userServices
      .getAddresses(searchValue, lastId)
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(registrationConstants.GET_ADDRESSES) };
  }
  function success(payload) {
    return { type: SUCCESS(registrationConstants.GET_ADDRESSES), payload };
  }
  function failure(error) {
    return { type: FAILURE(registrationConstants.GET_ADDRESSES), error };
  }
}

export function getAddressDetails(addressId, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    userServices
      .getAddressDetails(addressId)
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(registrationConstants.GET_ADDRESS_DETAILS) };
  }
  function success(payload) {
    return {
      type: SUCCESS(registrationConstants.GET_ADDRESS_DETAILS),
      payload,
    };
  }
  function failure(error) {
    return { type: FAILURE(registrationConstants.GET_ADDRESS_DETAILS), error };
  }
}

/* NO MORE USED */
export function getUserInfo2(token, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    userServices
      .getUserInfo(token)
      .then((payload) => {
        if (payload?.data?.user) {
          setUserData({ ...getUserData(), ...payload.data.user });

          const tenantUserId = Commons.generateTenantUserId(
            store.getState().onboardingReducer.userPin
          );
          dispatch(
            commonsActions.setThings(
              [
                {
                  type: thingVuseProperties.COMMUNICATION_EMAIL,
                  data:
                    payload?.data?.user?.vuseItcanEmailOptIn === true
                      ? flagStatus.ACCEPTED
                      : flagStatus.REFUSED,
                },
                {
                  type: thingVuseProperties.COMMUNICATION_NOTIFICATIONS,
                  data:
                    payload?.data?.user?.vuseItcanAppOptIn === true
                      ? flagStatus.ACCEPTED
                      : flagStatus.REFUSED,
                },
              ],
              tenantUserId,
              getVuseUuid(),
              CUSTOMER
            )
          );
        }

        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(onboardingConstants.GET_USER) };
  }
  function success(payload) {
    return { type: SUCCESS(onboardingConstants.GET_USER), payload };
  }
  function failure(error) {
    return { type: FAILURE(onboardingConstants.GET_USER), error };
  }
}

export function updateUserInfo2(token, data, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    userServices
      .updateUserInfo(token, data)
      .then((payload) => {
        dispatch(success(payload.data));

        if (payload?.data?.user && getVuseUuid()) {
          setUserData({ ...getUserData(), ...payload.data.user });

          const tenantUserId = Commons.generateTenantUserId(
            store.getState().onboardingReducer.userPin
          );
          dispatch(
            commonsActions.setThings(
              [
                {
                  type: thingVuseProperties.COMMUNICATION_EMAIL,
                  data:
                    payload?.data?.user?.vuseItcanEmailOptIn === true
                      ? flagStatus.ACCEPTED
                      : flagStatus.REFUSED,
                },
                {
                  type: thingVuseProperties.COMMUNICATION_NOTIFICATIONS,
                  data:
                    payload?.data?.user?.vuseItcanAppOptIn === true
                      ? flagStatus.ACCEPTED
                      : flagStatus.REFUSED,
                },
              ],
              tenantUserId,
              getVuseUuid(),
              CUSTOMER
            )
          );
        }

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(onboardingConstants.UPDATE_USER) };
  }
  function success(payload) {
    return { type: SUCCESS(onboardingConstants.UPDATE_USER), payload };
  }
  function failure(error) {
    return { type: FAILURE(onboardingConstants.UPDATE_USER), error };
  }
}

export function setUserSettings2(
  settingName,
  settingValue,
  successCb,
  failureCb
) {
  return (dispatch, getState) => {
    dispatch(request());

    userServices
      .CAsetUserSettings({
        settingName: settingName,
        value: settingValue,
      })
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(onboardingConstants.UPDATE_USER_SETTINGS) };
  }
  function success(payload) {
    return { type: SUCCESS(onboardingConstants.UPDATE_USER_SETTINGS), payload };
  }
  function failure(error) {
    return { type: FAILURE(onboardingConstants.UPDATE_USER_SETTINGS), error };
  }
}

export function setRegistrationProfileData(data) {
  return {
    type: registrationConstants.SET_REGISTRATION_PROFILE_DATA,
    data,
  };
}

export function resetRegistrationProfileData() {
  return {
    type: registrationConstants.RESET_REGISTRATION_PROFILE_DATA,
  };
}

export function setRegistrationActiveStep(data) {
  return {
    type: registrationConstants.SET_ACTIVE_STEP,
    data,
  };
}

// NEW ACTIONS

function checkEmail(email, onSuccess, onFailure) {
  return createRequest({
    actionName: registrationConstants.CHECK_EMAIL,
    serviceCb: userServices.checkEmail(email),
    onSuccess,
    onFailure,
  });
}

function resetPassword(email, onSuccess, onFailure) {
  return createRequest({
    actionName: loginConstants.RESET_PASSWORD,
    serviceCb: userServices.resetPassword(email),
    onSuccess,
    onFailure,
  });
}

function logout(tenantUserId, onSuccess, onFailure) {
  return createRequest({
    actionName: loginConstants.LOGOUT,
    serviceCb: userServices.logout(tenantUserId),
    onSuccess,
    onFailure,
  });
}

function resendActivationLink(email, onSuccess, onFailure) {
  return createRequest({
    actionName: loginConstants.RESEND_ACTIVATION_LINK,
    serviceCb: userServices.resendActivationLink(email),
    onSuccess,
    onFailure,
  });
}

function myVuseCreate(data, onSuccess, onFailure) {
  return createRequest({
    actionName: registrationConstants.REGISTER_USER,
    serviceCb: userServices.signup({
      address: `${data.address["street[0]"]} ${data.address["street[1]"]}`,
      city: data.address.city,
      consentVype: true,
      country: (isCurrentCountryGermany() || isCurrentCountrySouthAfrica()) ? data.address.country : data.address.countryName,
      darkMarket: Tenants.isCanadaDark(),
      dateOfBirth: moment(data.dob, "DD/MM/YYYY").format("YYYY/MM/DD"),
      email: data.email,
      emailSubscriptionVype: false,
      firstName: data.firstName,
      gender: data.gender,
      geocodedAddress: true,
      lastName: data.lastName,
      myVuseEverLogged: false,
      myvuseConnected: true,
      password: data.password,
      phoneNumber: Commons.getPhoneNumber(data.phoneNumber),
      postalCode: data.address.zip,
      province: data.address.province,
      source: "myvuse",
      storeName: "vype",
      userOptedInForEmails: data.userOptedInForEmails || false,
      userOptedInForPushNotifications: data.userOptedInForPushNotifications || false,
      userOptedInForSMS: data.userOptedInForSMS || false,
      creationSource: "prospect",
      idNumber: data?.idNumber,
      suburb: data?.suburb,

      userisAgeVerified: data.userisAgeVerified,
      verificationMethod: data.verificationMethod,
      vusePlusOptIn: data.vusePlusOptIn || false,
      //Just for CA branch.io
      utmData: data?.utmData
    }),
    onSuccess,
    onFailure,
  });
}

function login(data, onSuccess, onFailure) {
  return createRequest({
    actionName: loginConstants.LOGIN,
    serviceCb: userServices.login(data),
    onSuccess,
    onFailure,
  });
}

function getUserInfo(userId, onSuccess, onFailure) {
  return createRequest({
    actionName: loginConstants.GET_USER_INFO,
    serviceCb: userServices.getUserInfo(userId),
    onDataReceived(data) {
      console.debug("getUserInfo dataReceived", data);

      if (data?.user) {
        setUserData(data.user);
      }
    },
    onSuccess,
    onFailure,
  });
}

function updateUserInfo(data, onSuccess, onFailure) {
  const userId = getUserData().id;

  let newData = data;
  if(!isNil(data?.phoneNumber)){
    newData['phoneNumber'] = Commons.getPhoneNumber(
      data?.phoneNumber
    )
  }

  return createRequest({
    actionName: onboardingConstants.UPDATE_USER,
    serviceCb: userServices.updateUserInfo(
      userId, {
        //...userInfo,
        ...newData
      }
    ),
    onDataReceived(userData, dispatch) {
      console.debug("updateUserInfo dataReceived", userData);

      // if (userData && vuseUuid) {
      //   setUserData(userData);
      // }

      if (userData?.user) {
        setUserData(userData.user);
      }

      const vuseUuid = getVuseUuid();
      const tenantUserId = Commons.generateTenantUserId(
        store.getState().onboardingReducer.userPin
      );

      console.log("[userData] userData: ", userData);
      if (vuseUuid && tenantUserId) {
        dispatch(
          commonsActions.setThings(
            [
              {
                type: thingVuseProperties.COMMUNICATION_EMAIL,
                data: userData?.user?.userOptedInForEmails
                  ? flagStatus.ACCEPTED
                  : flagStatus.REFUSED
              },
              {
                type: thingVuseProperties.COMMUNICATION_NOTIFICATIONS,
                data: userData?.user?.userOptedInForPushNotifications
                  ? flagStatus.ACCEPTED
                  : flagStatus.REFUSED
              },
            ],
            tenantUserId,
            vuseUuid,
            CUSTOMER
          )
        );
      }
    },
    onSuccess,
    onFailure,
  });
}

function setUserSettings(settingName, value, onSuccess, onFailure) {
  const userData = getUserData();
  const userId = userData?.id || "";
  const emailAddress = userData?.email || "";

  return createRequest({
    actionName: onboardingConstants.UPDATE_USER_SETTINGS,
    serviceCb: userServices.setUserSettings(
      userId,
      {
        settingName,
        value,
        emailAddress,
      }
    ),
    onSuccess,
    onFailure,
  });
}

function getSalesforceId(successCb, failureCb) {
  const userData = getUserData();
  const emailAddress = userData?.email || "";

  const tenantUserId = Commons.generateTenantUserId(
    store.getState().onboardingReducer.userPin
  );

  userServices
    .getSalesforceId(
      tenantUserId,
      emailAddress,
    )
    .then((payload) => {
      if (typeof successCb === "function") {
        successCb(payload.data);
      }
    })
    .catch((error) => {
      if (typeof failureCb === "function") {
        failureCb(error);
      }
    });
}