import HMAC_SHA256 from "crypto-js/hmac-sha256";
import { commonsActions, deviceActions } from "../_actions";
import {
  getLocale,
  getMobilePrefix,
  getUrl,
  isSupportedService,
} from "../_actions/appConfig.actions";
import { getUserData, getVuseUuid } from "../_actions/appData.actions";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  CUSTOMER,
  VUSE_DEVICE,
  dashboardTabsConstants,
  flagStatus,
  routingConstants,
  servicesConstants,
  thingDeviceProperties,
  thingVuseProperties,
  tipPropertyData,
  webviewUrl,
} from "../_constants";
import { store } from "../_store";
import sdk from "../_vendors/nodes";
import {
  logAnalyticsEvent,
  logAnalyticsEventForDevice,
} from "./analytics/logAnalytics";
import { isDeviceConnected } from "./device";
import { history } from "./history";
import { buildURI } from "./navigation";
import { Notifications } from "./notifications";
import { isFunction } from "lodash";
import { commonsServices } from "../_services";

export class Commons {
  static showErrorAlert() {
    store.dispatch(commonsActions.showErrorAlert(true));
  }

  static goToSupport() {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.FAQ,
    });
    window.open(getUrl(webviewUrl.WEBVIEW_FAQ), "_blank");
  }

  /**
   * Given one or more thing properties, set them with some status on BE. If status is not defined
   * set their status as ACCEPTED.
   * After manage sugestions notifications.
   * @param {*} flags
   * @param {*} status
   * @returns
   */
  static acceptOptIn(flags, status) {
    return new Promise((resolve, reject) => {
      let currentFlags = [];
      if (!Array.isArray(flags)) {
        flags = [flags];
      }
      flags.forEach((flag) =>
        currentFlags.push({ type: flag, data: status ?? flagStatus.ACCEPTED })
      );

      const tenantUserId = this.generateTenantUserId(
        store.getState().onboardingReducer.userPin
      );

      store.dispatch(
        commonsActions.setThings(
          currentFlags,
          tenantUserId,
          getVuseUuid(),
          CUSTOMER,
          (response) => {
            if (response) {
              resolve();

              //Handle suggestion notifications delete
              flags.forEach((property) => {
                let notificationType = null;
                if (property === thingVuseProperties.OPT_IN_FINDMYVAPE) {
                  notificationType =
                    tipPropertyData.SUGGESTIONS_ENABLE_FIND_MY_VAPE;
                } 
                // else if (
                //   property === thingVuseProperties.OPT_IN_FLAVOUR_DISC
                // ) {
                //   notificationType =
                //     tipPropertyData.SUGGESTIONS_ENABLE_FLAVOUR_FOR_YOU;
                // } 
                else if (property === thingVuseProperties.BATTERYSAVE) {
                  notificationType = tipPropertyData.SUGGESTIONS_BATTERYSAVE;
                } else if (property === thingVuseProperties.CLOUDCONTROL) {
                  notificationType =
                    tipPropertyData.SUGGESTIONS_ENABLE_CLOUD_CONTROL;
                } else if (property === thingVuseProperties.DEVICEPAIR) {
                  notificationType = tipPropertyData.SUGGESTIONS_PAIR;
                }

                Notifications.removeSuggestionNotification(notificationType);
              });
            } else {
              reject();
            }
          },
          () => {
            reject();
          }
        )
      );
    });
  }

  static generateSessionId(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static isFeatureOptinDone(thingProperty) {
    return Commons.getCustomerProperty(thingProperty) === flagStatus.ACCEPTED;
  }

  static goTo(pathname, state) {
    history.push({ pathname: buildURI(pathname), state });
  }

  static goToPageWithSelectedDevice(device, where) {
    if (device) {
      if (isDeviceConnected(device)) {
        store.dispatch(
          deviceActions.setSelected(device, () => {
            console.log("selected callback", where);
            history.push(buildURI(where));
            return;
          })
        );
      }
    }
  }

  static goToFeaturePage({
    thingProperty,
    analytics,
    device,
    route,
    onOptinDone,
    onOptinUndone,
  }) {
    if (this.isFeatureOptinDone(thingProperty)) {
      if (analytics) {
        const { eventName, eventProperties, userProperties } = analytics;

        // if (device) {
        //   logAnalyticsEventForDevice(device, eventName, userProperties);
        // } else {
        //   logAnalyticsEvent(eventName, eventProperties, userProperties);
        // }
      }

      if (isFunction(onOptinDone)) {
        onOptinDone();
      } else if (device) {
        this.goToPageWithSelectedDevice(device, route.optinDone);
      } else {
        this.goTo(route.optinDone);
      }
    } else {
      if (isFunction(onOptinUndone)) {
        onOptinUndone();
      } else {
        this.goTo(route.optinUndone);
      }
    }
  }

  static goToDashboard({ isMoreMenuOpen = true, ...state } = {}) {
    history.push({
      pathname: buildURI(routingConstants.DASHBOARD),
      state: {
        ...(isMoreMenuOpen && {
          activeTab: dashboardTabsConstants.MENU
        }),
        ...state
      },
    });
  }

  static generateRandomNumericId(length) {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * Gets time as parameter (minutes and seconds) a returns seconds
   * @param {*} time
   * @returns
   */
  static getSecondsFromTime(time) {
    const formattedMinute = parseInt(time.minutes, 10);
    const formattedSeconds = parseInt(time.seconds, 10);

    return formattedMinute * 60 + formattedSeconds;
  }

  static getTimeFromSeconds(seconds) {
    return {
      minutes: Math.floor(seconds / 60)
        .toString()
        .padStart(2, "0"),
      seconds: (seconds % 60).toString().padStart(2, "0"),
    };
  }

  static generateTenantUserId(pin) {
    const customerId = getUserData().id;
    return HMAC_SHA256(`${customerId}`, `${customerId}${pin}`).toString();
  }

  static createHashFromString(str, secretKey) {
    const userPin = store.getState().onboardingReducer.userPin;
    const key = secretKey || userPin || "secret";

    return HMAC_SHA256(str, key).toString();
  }

  static sanitizeSecurityAnswer(answer) {
    let sanitizedAnswer = answer.trim().toLowerCase();
    sanitizedAnswer = sanitizedAnswer.replace(/[^\w]/gi, "");
    return sanitizedAnswer;
  }

  static getCustomerThing() {
    return store
      .getState()
      .commonsReducer.things.find((thing) => thing?.vendor === CUSTOMER);
  }

  static getDeviceThing(serialNumber) {
    return store
      .getState()
      .commonsReducer.things.find(
        (thing) =>
          thing?.vendor === VUSE_DEVICE &&
          thing?.properties &&
          (thing?.properties).find(
            (property) => property.type === thingDeviceProperties.SERIAL_NUMBER
          )?.data === serialNumber
      );
  }

  /**
   * Given some property name of customer thing, return its value.
   * @param {String} propertyType
   * @returns
   */
  static getCustomerProperty(propertyType) {
    const customerThing = this.getCustomerThing();
    if (customerThing !== null && customerThing !== undefined) {
      return customerThing.properties.find((prop) => prop.type === propertyType)
        ?.data;
    } else {
      return null;
    }
  }

  static getCustomerPropertyLastUpdate(propertyType) {
    const customerThing = this.getCustomerThing();
    if (customerThing !== null && customerThing !== undefined) {
      return customerThing.properties.find((prop) => prop.type === propertyType)
        ?.last_update;
    } else {
      return null;
    }
  }

  static isValidArray(thisArray) {
    return (
      thisArray !== null &&
      thisArray !== undefined &&
      Array.isArray(thisArray) &&
      thisArray.length > 0
    );
  }

  static isLocalEnvironment() {
    return (
      !!window.location.href.match(/^https:\/\/localhost/) ||
      !!window.location.href.match(/^https.*ngrok\.io/)
    );
  }

  static isLocalhost() {
    return !!window.location.href.match(/^https:\/\/localhost/);
  }

  static parseJSONSafely(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return {};
    }
  }

  static sortArrayAlphabetically(thisArray, field, descending) {
    return thisArray.sort((a, b) => {
      let elA = a;
      let elB = b;
      if (field !== null && field !== undefined) {
        elA = a[field].toUpperCase();
        elB = b[field].toUpperCase();
      } else {
        elA = a.toUpperCase();
        elB = b.toUpperCase();
      }
      const multiplier = descending ? -1 : 1;
      return elA < elB ? -1 * multiplier : elA > elB ? 1 * multiplier : 0;
    });
  }

  static sortArrayNumerically(thisArray, field, descending) {
    return thisArray.sort((a, b) => {
      let elA = a;
      let elB = b;
      if (field !== null && field !== undefined) {
        elA = parseFloat(a[field]);
        elB = parseFloat(b[field]);
      } else {
        elA = parseFloat(a);
        elB = parseFloat(b);
      }
      const multiplier = descending ? -1 : 1;
      return elA < elB ? -1 * multiplier : elA > elB ? 1 * multiplier : 0;
    });
  }

  static goToPageWithSelectedDevice(device, where) {
    if (device) {
      if (
        sdk[device.deviceType].ConnectionState.Synchronized ===
        device.connectionState
      ) {
        store.dispatch(
          deviceActions.setSelected(device, () => {
            history.push(buildURI(where));
            return;
          })
        );
      }
    }
  }

  /**
   * Get the phone number with prefix or not according to the supported service.
   *
   * @param {string} phoneNumber The phone number
   *
   * @return The phone number
   */
  static getPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return "";
    }

    if (
      !isSupportedService(servicesConstants.PHONE_PREFIX_INCLUDE) ||
      phoneNumber.startsWith("+")
    ) {
      return phoneNumber;
    }

    return getMobilePrefix() + phoneNumber;
  }

  static getPercentage(value, total) {
    if (total) {
      return Math.round((value / total) * 100);
    }
  }

  static getLang() {
    const locale = getLocale();
    const lang = locale.split("_");

    if (lang && lang[0]) {
      return lang[0];
    }

    return "en";
  }

  static getCustomerPropertyLastUpdate(propertyType) {
    const customerThing = this.getCustomerThing();
    if (customerThing !== null && customerThing !== undefined) {
      return customerThing.properties.find((prop) => prop.type === propertyType)
        ?.last_update;
    } else {
      return null;
    }
  }

  static getLocaleForMomentLocalization = (appConfig) => {
    let locale = appConfig.locale?.toLowerCase();
    locale = locale.split("_");

    if (locale[0] === locale[1]) {
      return locale[0];
    } else {
      return `${locale[0]}-${locale[1]}`;
    }
  };
}
