import React, { useContext, useEffect, useState } from "react";
import { CustomLoadingButton } from "../../_components/CustomLoadingButton";
import {
  aemPages,
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  flagStatus,
  imagesConstants,
  routingConstants,
  thingVuseProperties,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { convertObjectToQueryString } from "../../_helpers/utils";
import { Header, PageSelectorCTA } from "../../_components";
import { DashboardContext } from "../Dashboard";

export function PodTrackerOptin({
  header = true
}) {
  const [dictionary, setDictionary] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { navbarHeight } = useContext(DashboardContext) || {};

  const goToTutorial = () => {
    logAnalyticsEvent(
        AnalyticsEvents.CLICK,
        {target: AnalyticsTargetEventsNames.TUTORIAL}
    );
    history.push(buildURI(routingConstants.POD_TRACKER_TUTORIAL), {type: 'slide'});
  };

  const goToInstructions = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.LEARN_HOW})
    history.push(buildURI(routingConstants.POD_TRACKER_OPTIN_INSTR));
  };

  const goToMyVusePrivacyPolicy = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PRIVACY_NOTICE})
    history.push(
      buildURI(routingConstants.PRIVACY_POLICY) +
        convertObjectToQueryString({ canHandleBack: true })
    );
  };

  const enableFeature = () => {
    setIsLoading(true);

    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ENABLE})

    Commons.acceptOptIn(thingVuseProperties.OPT_IN_POD_TRACKER).then(() => {
      setIsLoading(false);
      history.push({
        pathname: buildURI(routingConstants.DASHBOARD),
        hash: 'pod-tracker'
      })
      
      logAnalyticsEvent(AnalyticsEvents.OPT_IN, {target: AnalyticsScreenEventNames.POD_TRACKER})
      //logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.POD_TRACKER})
    });
  };

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.POD_TRACKER_OPTIN, {
        PD_OPTIN_HEADER: propertyHeadingDefaultEmpty,
        PD_OPTIN_SHARING_DATA: propertyTextDefaultEmpty,
        PD_OPTIN_BTN_ENABLE: propertyCtaItemsDefaultEmpty,
        PD_OPTIN_DISABLE_FEATURE: propertyTextDefaultEmptyParsed,
        PD_OPTIN_LINK_PRIVACY_POLICY: propertyTextDefaultEmptyParsed,
        PD_OPTIN_NOT_REGULAR_PODS: propertyTextDefaultEmptyParsed
      })
    );
  }, []);

  useEffect(() => {
    const enabled = Commons.getCustomerProperty(thingVuseProperties.OPT_IN_POD_TRACKER) === flagStatus.ACCEPTED
    if (enabled) {
      history.push({
        pathname: buildURI(routingConstants.DASHBOARD),
        hash: 'pod-tracker'
      })
    }

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.POD_TRACKER_OPT_IN});
  }, []);

  return (
    <div className="optin-wrapper has-header" style={{
      display: "inline",
      paddingBottom: `${navbarHeight}px`
    }}>
      {header &&
      <Header
        leftButton={{
          className: "play-tutorial",
          icon: <img src={imagesConstants.CLOUD_CONTROL_PLAY_TUTORIAL} />,
          onClick: goToTutorial,
        }}
      />
      }
      <div className="optin-content">
        <div className="optin-icon white">
          <img src={require(`../../_assets/img/navbar/UltraPodHistory_Active.svg`)} />
        </div>
        <div className="optin-title">
          <h1 className="">
            {dictionary.PD_OPTIN_HEADER}
          </h1>
        </div>
        <div className="optin-description">
          <div className="mt-3">
            {dictionary.PD_OPTIN_SHARING_DATA}
          </div>
          <div className="mt-3">
            {dictionary.PD_OPTIN_NOT_REGULAR_PODS}
          </div>
        </div>
        <div className="optin-disclaimer" onClick={goToInstructions}>
          {dictionary.PD_OPTIN_DISABLE_FEATURE}
        </div>
      </div>
      <PageSelectorCTA ctaUrl={true} sticky={false} className="optin-selector-cta" style={{marginTop: '50px'}}>
        <div className="d-grid">
          <CustomLoadingButton
            isLoading={isLoading}
            validationMethod={null}
            buttonLabel={dictionary.PD_OPTIN_BTN_ENABLE_0_ctaLabel}
            onClickMethod={enableFeature}
          />
        </div>
        <div
          className="optin-privacy-note mt-3"
          onClick={goToMyVusePrivacyPolicy}
          style={{marginBottom: "97px"}}
        >
          {dictionary.PD_OPTIN_LINK_PRIVACY_POLICY}
        </div>
      </PageSelectorCTA>
    </div>
  );
}
