import React, { useEffect, useState } from "react";
import ReactSlider from 'react-slider';

export const DeviceLedBrightness = ({ setLedBrightness, ledBrightness, title, disabled }) => {

    const [tempLedBrightness, setTempLedBrightness] = useState(0);

    useEffect(() => {
        setTempLedBrightness(ledBrightness);
    }, [ledBrightness])

    return (
        <div className="device-settings-cta">
            <label className="page-label" htmlFor="horizontal-slider">{title}</label>
            <div className="d-flex align-items-center">
                <span className="bat-icon-brightness-low pe-2"></span>
                <ReactSlider
                    className="horizontal-slider"
                    markClassName="slider-mark"
                    min={5}
                    step={5}
                    max={100}
                    disabled={disabled}
                    thumbClassName="slider-thumb"
                    trackClassName="slider-track"
                    value={tempLedBrightness}
                    renderTrack={(props, state) => {
                        return (
                            <div {...props}>{state.valueNow}</div>
                        );
                    }}
                    renderThumb={(props, state) => {
                        return (<div data-value={""} {...props} />);
                    }}
                    onAfterChange={(position) => setLedBrightness(position)}
                    onChange={(position) => setTempLedBrightness(position)}
                />
                <span className="bat-icon-brightness-high ps-2"></span>
            </div>
        </div>
    );

};
