import { deleteCalculatedData, getDbInstance, sendCalculatedData } from "../utils";
import { getSmartPodFlavourInfo } from "../device"
import initialize, { addCollection, PodsCollectionName } from "../utils/initializeDb";
import moment from "moment";
import { store } from "../../_store";
import {
    MODE_BALANCED,
    MODE_BOLD,
    MODE_SMOOTH,
    MODE_LOWPOWER,
    PROFILE_EPOD2,
    PROFILE_WAWE2,
    thingVuseProperties,
    flagStatus,
} from "../../_constants";
import { getDeviceSpec } from "../../_actions/appConfig.actions";
import { isFunction, isNil } from "lodash";
import { Commons } from "../commons";

export const setPodRecords = async (deviceSN, puffRecords) => {

    console.log("debug puffRecords", puffRecords);

    if (
        !puffRecords[0].isSmartPod ||
        !getSmartPodFlavourInfo(puffRecords[0].skuNumber)
    ) return;

    const devices = store.getState().deviceReducer?.devices || [];
    const selectedDevice = devices.find(
        (device) => device.serialNumber === deviceSN
    );

    const deviceProfile =
    selectedDevice?.deviceType === PROFILE_EPOD2
        ? PROFILE_WAWE2
        : selectedDevice?.deviceType;
    const deviceSpec = getDeviceSpec(deviceProfile);
    const boldMode = deviceSpec?.cloudSize.find((cl) => cl.type === MODE_BOLD);
    const balancedMode = deviceSpec?.cloudSize.find(
        (cl) => cl.type === MODE_BALANCED
    );
    const smoothMode = deviceSpec?.cloudSize.find(
        (cl) => cl.type === MODE_SMOOTH
    );

    if (isNil(selectedDevice)) return;

    const db = await getDbInstance();

    for (const puffRecord of puffRecords) {
    
        const timestamp = puffRecord?.timestamp;

        // get primary key
        const uid = puffRecord.podUIDHex;
        const month = moment(parseInt(timestamp) * 1000).format("M");
        const year = moment(parseInt(timestamp) * 1000).format("Y");

        // get variable fields
        const updatedAt = timestamp;
        const remainingCapacity = puffRecord?.liquidRemaining;

        const lowPower = 0; // to fix
        const low = (puffRecord?.powerLevel <= smoothMode?.value) ? 1 : 0;
        const med = (
            puffRecord?.powerLevel > smoothMode?.value &&
            puffRecord?.powerLevel <= balancedMode?.value
        ) ? 1 : 0;
        const high = (
            puffRecord?.powerLevel > balancedMode?.value &&
            puffRecord?.powerLevel <= boldMode?.value
        )  ? 1 : 0;

        // check if record already exists
        const existingRecord = await db[PodsCollectionName].findOne({
            selector: {uid, month, year}
        }).exec();

        // update record if already exists
        if (existingRecord){
            await existingRecord.incrementalUpdate({
                $inc: {
                    low,
                    med,
                    high,
                    lowPower
                }
            });
            await existingRecord.incrementalUpdate({
                $set: {
                    remainingCapacity,
                    updatedAt
                }
            });
        }

        else{

            // need to create a new record

            // check if pod already has a record (needed for createdAt parameter)
            const existingPodRecord = await db[PodsCollectionName].findOne({
                selector: {uid}
            }).exec();

            // get fixed fields
            const sku = puffRecord.skuNumber;
            const nicotine = puffRecord.podLiquidInfo.nicotineStrength;
            const capacity = puffRecord.podLiquidInfo.fillCapacity;

            const flavourInfo = getSmartPodFlavourInfo(sku); // flavour info from aem
            const flavourId = flavourInfo?.id;

            const createdAt = existingPodRecord
                            ? existingPodRecord.toJSON().createdAt
                            : timestamp;

            await db[PodsCollectionName].insert({
                month,
                year,
                uid,
                flavourId,
                sku,
                nicotine,
                createdAt,
                low,
                med,
                high,
                lowPower,
                capacity,
                remainingCapacity,
                updatedAt
            });
                            
        }
        
    }
    
}

// returns the time in hours between two timestamps
const getTimeBetweenTimestamps = (createdAt, updatedAt, monthRange, isUsageTotal = false) => {

    createdAt = parseInt(createdAt);
    const firstOfMonth = parseInt(
        moment().subtract(
            monthRange - 1, 'months'
        ).utc().startOf('month').unix()
    );

    const startingTimestamp =   ((createdAt > firstOfMonth) || isUsageTotal)
                                ? createdAt
                                : firstOfMonth;

    const diff = parseInt(updatedAt) - startingTimestamp;
    const hours = diff / 3600;

    return hours;
}

export const getPodData = async (monthRange, instant = false, callback) => {
    const db = await getDbInstance();

    // calculate each month-year couples needed
    let prevDate, timeSelector = [];

    for (let i = 0; i < monthRange; i++){
        prevDate = moment().subtract(i, 'months');

        timeSelector.push({
            month: prevDate.format('M'),
            year: prevDate.format('Y')
        });
    }

    // fetch the result
    const result = await db[PodsCollectionName].find({
        selector: {
            $or: timeSelector
        }
    });

    if (!isNil(window.dailyQuery)) {
        window.dailyQuery.unsubscribe();
    }

    if (instant) {
        result.exec().then(res => {
            isFunction(callback) && callback(res)
        })
    } else {
        //console.log("debug fetched records", result.map(record => record.toJSON()));
        window.dailyQuery = result.$.subscribe((res) => {
            if (!isNil(res)) {
                isFunction(callback) && callback(res.map(res => res.toJSON()));
            }
        });
    }
    //return result.map(record => record.toJSON());
}

const getUsedPods = async (queryResult, monthRange) => {

    // group records by uid
    const result = queryResult.reduce((accRecords, newRecord) => {

        let podRecord;
        let timeDiff;
        const podRecordIndex = accRecords.findIndex(
            o => o.uid === newRecord.uid
        )

        if (podRecordIndex === -1){ // the pod has not been grouped yet  

            timeDiff = getTimeBetweenTimestamps(
                newRecord.createdAt,
                newRecord.updatedAt,
                monthRange
            );
            
            accRecords.push({
                // temporary field
                updatedAt: parseInt(newRecord.updatedAt),

                // useful fields
                uid: newRecord.uid,
                timeDiff
            });
        }

        else { // the pod has already been grouped

            podRecord = accRecords[podRecordIndex]; // existing record

            if (podRecord.updatedAt < parseInt(newRecord.updatedAt)){ // the pod has been used again

                timeDiff = getTimeBetweenTimestamps(
                    newRecord.createdAt,
                    newRecord.updatedAt,
                    monthRange
                );

                podRecord.updatedAt = parseInt(newRecord.updatedAt);
                podRecord.timeDiff = timeDiff;
            }
        }

        return accRecords;
    }, []);

    // remove unuseful properties
    result.map((record) => { 
        delete record.updatedAt;
        return record; 
    });

    return result;
}

export const getUsedFlavourGroups = async (queryResult) => {

    // group records by flavour group
    const result = queryResult.reduce((accRecords, newRecord) => {

        let flavourRecord;
        let flavourInfo;
        const flavourRecordIndex = accRecords.findIndex( // index of the record containing the same flavour id
            o => {return o.flavourId === newRecord.flavourId}
        )

        if (flavourRecordIndex === -1){ // the flavour group has not been grouped yet

            flavourInfo = getSmartPodFlavourInfo(newRecord.sku);

            accRecords.push({

                // temporary fields
                flavourId: newRecord.flavourId,
                ultraPodsUid: [newRecord.uid],
                updatedAt: parseInt(newRecord.updatedAt),
                puffCount:  newRecord.lowPower
                            + newRecord.low
                            + newRecord.med
                            + newRecord.high,

                // useful fields
                ultraPodsCount: 1,
                name: flavourInfo?.name,
                color: flavourInfo?.color,
                icon: flavourInfo?.icon,
            });

        }
        
        else { // the flavour group has already been grouped

            flavourRecord = accRecords[flavourRecordIndex]; // existing record

            flavourRecord.puffCount +=  newRecord.lowPower // updates total puff count
                                        + newRecord.low
                                        + newRecord.med
                                        + newRecord.high;

            if (parseInt(newRecord.updatedAt) > flavourRecord.updatedAt){ // the new record has the latest timestamp
                flavourRecord.updatedAt = parseInt(newRecord.updatedAt);
            }

            if (!flavourRecord.ultraPodsUid.includes(newRecord.uid)){ // the pod has not been yet included
                flavourRecord.ultraPodsCount += 1;
                flavourRecord.ultraPodsUid.push(newRecord.uid)
            }
        }

        return accRecords;
    }, []);

    // order records by most used flavour
    result.sort((a,b) => {
        if (a.ultraPodsCount != b.ultraPodsCount)
            return b.ultraPodsCount - a.ultraPodsCount;

        if (a.puffCount != b.puffCount)
            return b.puffCount - a.puffCount;

        return b.updatedAt - a.updatedAt;
    });

    // remove unuseful properties
    result.map((record) => { 
        delete record.flavourId;
        delete record.ultraPodsUid;
        delete record.puffCount;
        return record; 
    });

    return result;

}

export const getPodUsageInfo = async (queryResult, monthRange) => {

    const usedPods = await getUsedPods(queryResult, monthRange);

    // sum of usage days
    const daysSum = usedPods.reduce(
        (n, {timeDiff}) => n + timeDiff, 0
    );

    // number of pods used
    const podsCount = usedPods.length;

    // average
    const avg = daysSum / podsCount;

    return {
        avg,
        count: podsCount
    };

}

export const getGraphData = async (usedFlavourGroups) => {

    const totPods = usedFlavourGroups.reduce(
        (n, {ultraPodsCount}) => n + ultraPodsCount, 0
    );

    // populate data
    let data = []
    let backgroundColor = [];

    for (let flavourGroup of usedFlavourGroups){

        data.push(
            parseInt((flavourGroup.ultraPodsCount / totPods) * 100)
        );
        backgroundColor.push(flavourGroup.color);

    }

    // order data and backgroundColor in descending order of data
    let zip = (...a) => a[0].map((_, n) => a.map(b => b[n]));
    [backgroundColor, data] = zip(...
        zip(backgroundColor, data)
            .sort((x, y) => y[1] - x[1])
    );

    return {data, backgroundColor};

}

export const getFlavoursHistory = async (queryResult, monthRange) => {

    // group records by flavour group
    const result = queryResult.reduce((accRecords, newRecord) => {
    
        let flavourRecord;
        let flavourInfo;
        const flavourRecordIndex = accRecords.findIndex(o => {
            return  o.flavourId === newRecord.flavourId &&
                    o.nicotine === newRecord.nicotine
        });

        if (flavourRecordIndex === -1){ // the flavour has not been grouped yet

            flavourInfo = getSmartPodFlavourInfo(newRecord.sku);

            accRecords.push({

                // temporary fields
                flavourId: newRecord.flavourId,

                // useful fields
                name: flavourInfo?.name,
                color: flavourInfo?.color,
                icon: flavourInfo?.icon,
                nicotine: newRecord.nicotine,
                createdAt: parseInt(newRecord.createdAt),
                updatedAt: parseInt(newRecord.updatedAt),

            });

        }

        else{ // the flavour has already been grouped

            flavourRecord = accRecords[flavourRecordIndex]; // existing record

            if (parseInt(newRecord.updatedAt) > flavourRecord.updatedAt) // the new record has the latest timestamp
                flavourRecord.updatedAt = parseInt(newRecord.updatedAt);

            if (parseInt(newRecord.createdAt) < flavourRecord.createdAt) // the new record has been used earlier
                flavourRecord.createdAt = parseInt(newRecord.createdAt);

        }

        return accRecords;
    }, []);

    // sort by last used flavour
    result.sort((a,b) => {
        return b.updatedAt - a.updatedAt;
    });

    result.map(record => {

        // calculate usage time
        record.usageTime = getTimeBetweenTimestamps(
            record.createdAt,
            record.updatedAt,
            monthRange,
            true
        );

        // format createdAt
        record.createdAt = moment
            .unix(record.createdAt)
            .format('MMMM Do YYYY');
            
        // create month and year
        let updateYear = moment
            .unix(record.updatedAt)
            .format('YYYY');

        let currentYear = moment()
            .format('YYYY');

        record.month = moment
            .unix(record.updatedAt)
            .format('MMMM');

        record.year =   updateYear === currentYear
                        ? ""
                        : updateYear;

        // delete unuseful fields
        delete record.flavourId;
        delete record.updatedAt;

    });

    return result;

}

export const clearPodData = async() => {
    const db = await getDbInstance()

    await db[PodsCollectionName].remove()

    await addCollection(db, PodsCollectionName)

    const optinUsage = Commons.getCustomerProperty(thingVuseProperties.OPT_IN_USAGE_TRACKER) === flagStatus.ACCEPTED

    if (optinUsage) {
        await sendCalculatedData(true, 'pod')
    } else {
        await deleteCalculatedData()
    }
}