import React, { useState, useEffect } from 'react';
import { imagesConstants, routingConstants } from '../../_constants';
import BrowserHelper from '../../_helpers/browser/browser.helper';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';

export const AccessDenied = () => {

    const [isAndroid, setIsAndroid] = useState(false);

    useEffect(() => {
        setIsAndroid(/Android/i.test(navigator.userAgent));

        if (!BrowserHelper.isAccessDenied()) {
            history.push(buildURI(routingConstants.DASHBOARD));
        }
    }, [])

    return (
        <div className="access-denied h-100 d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column align-items-center" style={{ zIndex: 2 }}>
                <img className="myvuse-logo" src={imagesConstants.MYVUSE_lOGO} alt="MYVUSE logo" />

                <div className="alert-icon"> <span className='bat-icon-alert-triangle-outline'></span></div>
                <h3>BROWSER NOT SUPPORTED</h3>
                <span><strong>MYVUSE</strong> is not optimised for this browser. 
                    {
                        isAndroid 
                        ? <> In order to use <strong>MYVUSE</strong> on this device you’ll need to download the app from the Google Play store.</>
                        : <> In order to use <strong>MYVUSE</strong> on this device you’ll need to download the <strong>Nuviu</strong> browser.</>
                    }
                </span>
                {
                    isAndroid 
                    ?  (<img
                        className="play-store-logo" src={imagesConstants.GOOGLE_PLAY_LOGO}
                        alt="Download from Google Play store"
                        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.bat.myvuse.app')} />) 
                    :  (<img
                        className="app-store-logo" src={imagesConstants.APP_STORE_LOGO}
                        alt="Download on the App store"
                        onClick={() => window.open('https://apps.apple.com/app/nuviu/id1616197482')} />)
                }
            </div>
            <div className="page-linear-gradient-bg"></div>
        </div>
    );
}
