import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  getHourlyStats
} from "../../_helpers/usage-tracker";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale
} from "chart.js/auto";
import "chartjs-adapter-moment";
import { isEmpty } from "lodash";
import Annotation from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.defaults.font.family = "Montserrat";

ChartJS.register(
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Filler,
  Annotation,
  ChartDataLabels
);

const chartOptions = (data, puffLabel) => {
  const upperLimit = Math.max(...data)
  const labelValue = upperLimit === 0 ? 10 : upperLimit
  const tickDistance = upperLimit === 0 ? 10 / 3 : upperLimit / 3;
  const yAxisMax = upperLimit === 0 ? 10 : upperLimit;

  return {
    responsive: true,
    maintainAspectRatio: false,
    animations: false,
    interaction: {
      intersect: false,
      mode: "index",
    },
    layout: {
      padding: {
        left: 16,
        right: 16,
        top: 50,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false
      },
      annotation: {
        clip: false,
        annotations: {
          puff: {
            type: 'label',
            drawTime: 'beforeDatasetsDraw',
            yValue: labelValue,
            content: `${upperLimit} ${puffLabel}`,
            color: 'rgba(170,170,170,1)',
            backgroundColor: 'rgba(33, 33, 33, 1)',
            font: {
              size: 10,
              weight: 700
            },
          }
        }
      }
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "hour",
          round: "hour",
          displayFormats: {
            hour: "HH:mm",
          },
          tooltipFormat: "HH:mm",
        },
        grid: {
          color: false,
          tickColor: false,
        },
        ticks: {
          color: "rgba(255, 255, 255, 1)",
          font: {
            size: "10",
            weight: "bold",
            lineHeight: "14px",
          },
          callback: function(label, index, labels) {
            if ((moment(label).hour() % 6) === 0) {
              return moment(label).format("HH:mm")
            }
          }
        }
      },
      y: {
        min: 0,
        max: yAxisMax,
        position: "right",
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          drawBorder: false,
        },
        ticks: {
          display: false,
          stepSize: tickDistance,
          crossAlign: "far",
          color: "rgba(114, 114, 114, 1)",
          callback: function (value) {
            return value === 0 ? "" : value;
          },
        },
        border: {
          display: false
        }
      },
    },
  }
}

export function UsageTrackerStatsByDayChart({
  device,
  date,
  puffLabel
}) {
  const chartRef = useRef(null);
  const [xAxesValues, setXAxesValues] = useState([]);
  const [yAxesValues, setYAxesValues] = useState([]);
  const [yAxesValuesFull, setYAxesValuesFull] = useState([]);

  const chartData = {
    datasets: [
      {
        type: "line",
        fill: true,
        borderWidth: 2,
        borderColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
  
          if (!chartArea) {
            return null;
          }
  
          const { left, right } = chartArea;
          const gradient = ctx.createLinearGradient(left, 0, right, 0);
  
          const colorLow = 'rgba(0, 199, 177, 1)'//standard
          const colorMedium = 'rgba(0, 199, 177, 1)'
          const colorHigh = 'rgba(0, 199, 177, 1)'//'rgba(255, 0, 0, 1)'

          const array = []
          yAxesValuesFull.forEach((fullData, index) => {
            const stepG = parseFloat(index / 24).toFixed(2)

            if (fullData && fullData.total && isFinite(stepG)) {
              if (fullData.high > fullData.med && fullData.high > fullData.low) {
                gradient.addColorStop(stepG, colorHigh)
                array.push({stepG, colorHigh})
              } else if (fullData.med > fullData.low) {
                gradient.addColorStop(stepG, colorMedium)
                array.push({stepG, colorMedium})
              } else {
                gradient.addColorStop(stepG, colorLow)
                array.push({stepG, colorLow})
              }
            } else {
              gradient.addColorStop(stepG, colorLow)
              array.push({stepG, colorLow})
            }
          })
          gradient.addColorStop(1, colorLow)
          array.push({stepG: 1, colorLow})

          return gradient;
        },
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
  
          if (!chartArea) {
            return null;
          }
  
          const { top, bottom } = chartArea;
          const gradient = ctx.createLinearGradient(0, bottom, 0, top);
  
          gradient.addColorStop(0, "rgba(0, 95, 125, 0.15)");
          gradient.addColorStop(1, "rgba(0, 199, 177, 0.15)");
  
          return gradient;
        },
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  useEffect(() => {
    const xValues = [];
    for (let i = 0, l = 23; i <= l; i++) {
      const curr = i
      const xDate = moment(date)
      xDate.set({hour:curr,minute:0,second:0,millisecond:0})
      xValues.push(xDate)
    }
    //add midnight
    xValues.push(moment(date).add(1, 'd').set({hour:0,minute:0,second:0,millisecond:0}))
    setXAxesValues(xValues);
  }, []);

  useEffect(() => {
    if (xAxesValues.length) {
      
      getHourlyStats(device?.serialNumber, date, (res) => {
        let selector = {
          day: parseInt(moment(date).format("D")),
          month: parseInt(moment(date).format("M")),
          year: parseInt(moment(date).format("YY")),
        };

        if (device) {
          selector.serial = device.serialNumber
        }

        if (res[0]?.collection) {
          const query = res[0].collection.find({
            selector: selector,
          });

          query.exec().then(result => {
            const yValues = []
            const yValuesFull = []
            const checkDate = moment(date).set({hour: moment().hour(), minute: moment().minute(), second: moment().second(), millisecond: moment().millisecond()})

            xAxesValues.forEach((value, index) => {
              const hour = value.hour()
              const now = moment(value).set({hour: hour-1, minute:0, second: 0, millisecond: 0})

              if (!moment().isSame(date, 'date') || now.diff(checkDate) <= 0) {
                const found = result.filter(r => r.hour === hour)

                if (found.length) {
                  let total = 0
                  let full = {
                    total: 0,
                    low: 0,
                    mid: 0,
                    high: 0
                  }
                  found.forEach((f) => {
                    total += f.total
                    full.total += f.total
                    full.low += f.low
                    full.mid += f.mid
                    full.high += f.high
                  })
                  yValues.push(total)
                  yValuesFull.push(full)
                } else {
                  yValues.push(0)
                  yValuesFull.push({})
                }
              }
            })
            setYAxesValues(yValues)
            setYAxesValuesFull(yValuesFull)
          })
        } else {
          
          const yValues = []
          const yValuesFull = []
          xAxesValues.forEach((value) => {
            //if (moment().diff(date, 'days') !== 0) {
              yValues.push(0)
              yValuesFull.push({})
            //}
          })
          setYAxesValues(yValues)
          setYAxesValuesFull(yValuesFull)
        }
        
      })
    }

    if (isEmpty(yAxesValues)) {
      const emptyData = new Array(xAxesValues.length).fill(null);
      setYAxesValues(emptyData);
    }
  }, [date, device, xAxesValues]);

  return (
    <div className="chart">
      {!isEmpty(xAxesValues) && (
        <Line
          ref={chartRef}
          options={chartOptions(
            yAxesValues,
            puffLabel
          )}
          data={{ 
            ...chartData,
            labels: xAxesValues,
            datasets: [
              {
                ...chartData.datasets[0],
                data: yAxesValues
              },
            ],
          }}
        />
        )}
    </div>
  );
}