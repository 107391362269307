import React, { useEffect, useState } from "react";
import { getLegalAge, getProvinceLabel } from "../../_actions/appConfig.actions";
import consentAnimation from "../../_assets/lotties/hard-age-verification/consent.json";
import {
  AnalyticsErrorNames,
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  HARD_AGE_VERIFICATION_STEPS,
  aemPages,
  imagesConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Utils } from "../../_helpers/graphic/utils";
import { CustomAlert } from "../CustomAlert";
import { PageSelectorCTA } from "../PageSelectorCTA";
import { Webview } from "../../_helpers/webview";
import { ContactPanel } from "../ContactPanel";
import { getRegistration } from "../../_actions";
import { Tenants } from '../../_helpers/tenants';
import parse from 'html-react-parser';
import CustomLottie from "../CustomLottie/CustomLottie";

export function HardAgeVerificationConsentCA({ nextStep, goToStep }) {
  const [dictionary, setDictionary] = useState({
    YOTI_AV_THIRD_INFO: ''
  });
  const [scanTipsIsOpen, setScanTipsIsOpen] = useState(false);
  const [isContactPanelOpen, setIsContactPanelOpen] = useState(false);

  const isDarkMarket = Tenants.isCanadaDark();

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.YOTI_FACE_SCAN_INTRO})
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.HARD_AGE_VERIFICATION, {
        YOTI_AV_SUBTITLE: propertyTextDefaultEmpty,
        YOTI_AV_FIRST_INFO: propertyTextDefaultEmpty,
        YOTI_AV_THIRD_INFO: propertyTextDefaultEmpty,
        YOTI_AV_FOURTH_INFO: propertyTextDefaultEmptyParsed,
        YOTI_AV_SCAN_TIPS_LABEL: propertyTextDefaultEmpty,
        YOTI_POP_UP_TITLE: propertyTextDefaultEmpty,
        YOTI_POP_UP_LIGHT_CONTENT: propertyTextDefaultEmpty,
        YOTI_POP_UP_HAT_CONTENT: propertyTextDefaultEmpty,
        YOTI_POP_UP_GLASSES_CONTENT: propertyTextDefaultEmpty,
        YOTI_AV_BTN_CONSENT: propertyCtaItemsDefaultEmpty,
        YOTI_AV_BTN_ID_VERIFICATION: propertyCtaItemsDefaultEmpty,
        YOTI_CONFIRM_AGE: propertyTextDefaultEmpty,
        YOTI_HOW_WORKS: propertyTextDefaultEmpty,
        YOTI_QUICK_FACE_SCAN: propertyTextDefaultEmpty,
        YOTI_NO_SAVED : propertyTextDefaultEmpty,
        YOUTI_UPLOAD_VALID_ID: propertyTextDefaultEmpty,
        YOTI_ONLY_FOR_ADULT_AGE: propertyTextDefaultEmpty,
        YOTI_ONTARIO_PP: propertyTextDefaultEmptyParsed,
        YOTI_CONSUMER_CARE_TEAM: propertyTextDefaultEmptyParsed
        //YOTI_AV_SKIP_LINK: propertyTextDefaultEmpty,
      })
    );
  }, []);

  return (
    <div className="page w-100 overflow-hidden hard-age-verification-consent-container" style={{background: 'none'}}>
      <div className="page-wrapper d-flex flex-column overflow-auto h-100">
          <div className="hard-age-verification-title-ca text-center">
            <h1>
              {Utils.stringReplacePlaceholders(
                dictionary.YOTI_CONFIRM_AGE,
                `${getLegalAge()}+`,
                true
              )}
            </h1>
          </div>
          <div className="hard-age-verification-lottie-wrapper-ca m-0">
            <CustomLottie
              options={{
                loop: true,
                autoplay: true,
                animationData: consentAnimation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={125}
              width={125}
            />
            <div className="lottie-container">
              <div className="lottie-content">
                <img
                  src={imagesConstants.HARD_AGE_VERIFICATION_CAMERA}
                  alt="Camera Icon"
                />
              </div>
              <div className="lottie-content tips-label">
                <span
                  className="url-text"
                  onClick={() => {
                    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.SCAN_TIPS});
                    setScanTipsIsOpen(true);
                  }}
                >
                  {dictionary.YOTI_AV_SCAN_TIPS_LABEL}
                </span>
              </div>
              </div>
          </div>
          {/*<div className="hard-age-verification-no-bg">
            <div className="large">
              {dictionary.YOTI_AV_FIRST_INFO}
            </div>
          </div>*/}
          <div className="hard-age-verification-info-ca w-100">
            <ul>
              <li className="m-0">
                <span className="bat-icon-alert-circle alert-icon" />
              </li>
              <li>
                {dictionary.YOTI_HOW_WORKS}
              </li>
            </ul>
            <ol>
              <li>
                {dictionary.YOTI_QUICK_FACE_SCAN}
              </li>
              <li>
                {dictionary.YOTI_NO_SAVED}
              </li>
              <li>
              {Utils.stringReplacePlaceholders(
                dictionary.YOUTI_UPLOAD_VALID_ID,
                "25",
                true
              )}
              </li>
            </ol>
            <span>{dictionary.YOTI_ONLY_FOR_ADULT_AGE}</span>
          </div>
        <div className="hard-age-verification-info-bottom-ca" style={{marginTop: "auto"}}>
          <div className="large" onClick={() => Webview.goToMyVusePrivacyPolicy(true, false, true)}>
            {dictionary.YOTI_ONTARIO_PP}
          </div>
        </div>
        <div className="hard-age-verification-info-bottom-ca">
          <div className="large pt-0" onClick={() => {setIsContactPanelOpen(true); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CONTACT_US})}}>
            {dictionary.YOTI_CONSUMER_CARE_TEAM}
          </div>
        </div>
        <PageSelectorCTA>
          <div className="d-grid gap-2">
            <button
              className="btn btn-primary text-uppercase"
              onClick={() => {
                const registration = getRegistration()

                if (registration.triedFace) {
                  if(registration.fake){
                    logAnalyticsEvent(AnalyticsEvents.YOTI_FACE_SCAN_PROBLEM, {reason: AnalyticsErrorNames.FAKE_PICTURE});
                    goToStep(HARD_AGE_VERIFICATION_STEPS.FACE_FAKE);
                    return;
                  }
                  goToStep(HARD_AGE_VERIFICATION_STEPS.TOO_CLOSE);
                  return;
                }

                logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.USE_FACE_SCAN});
                goToStep(HARD_AGE_VERIFICATION_STEPS.CAPTURE);
              }}
            >
              {dictionary.YOTI_AV_BTN_CONSENT_0_ctaLabel}
            </button>
            <button
              className="btn btn-outline-secondary text-uppercase"
              onClick={() => {
                logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.USE_ID});
                goToStep(HARD_AGE_VERIFICATION_STEPS.DOC_SCAN);
              }}
            >
              {dictionary.YOTI_AV_BTN_ID_VERIFICATION_0_ctaLabel}
            </button>
          </div>
        </PageSelectorCTA>
      </div>

      <CustomAlert
        isOpen={scanTipsIsOpen}
        hideOkButton
        customClasses="hard-age-verification-scan-tips"
      >
        <div className="hard-age-verification-scan-tips-header">
          <div>
            {dictionary.YOTI_POP_UP_TITLE}
          </div>
          <span
            className="bat-icon-close close-icon"
            onClick={() => setScanTipsIsOpen(false)}
          />
        </div>
        <div className="hard-age-verification-scan-tips-body">
          <ul>
            <li>
              <img
                src={imagesConstants.HARD_AGE_VERIFICATION_SUN_ICON}
                alt="sun icon"
              />
              <div>{dictionary.YOTI_POP_UP_LIGHT_CONTENT}</div>
            </li>
            <li>
              <img
                src={imagesConstants.HARD_AGE_VERIFICATION_HAT_ICON}
                alt="hat icon"
              />
              <div>{dictionary.YOTI_POP_UP_HAT_CONTENT}</div>
            </li>
            <li>
              <img
                src={imagesConstants.HARD_AGE_VERIFICATION_SUNGLASSES_ICON}
                alt="sunglasses icon"
              />
              <div>{dictionary.YOTI_POP_UP_GLASSES_CONTENT}</div>
            </li>
          </ul>
        </div>
      </CustomAlert>

      <ContactPanel isOpen={isContactPanelOpen} onClose={() => setIsContactPanelOpen(!isContactPanelOpen)} />
    </div>
  );
}
