import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import StepWizard from "react-step-wizard";
import {
    commonsActions,
    setThings,
    onboardingActions,
    setDoneVault,
    setVuseUuid,
    setAcceptedTermsAndConditions,
    setUserEverLogged,
    userActions
} from '../../_actions';
import {
    VaultSetupPin,
    VaultSetupIntro,
    VaultSetupSecureQuestion,
    VaultSetupEnd
} from '../../_components/Vault';
import {
    imagesConstants,
    routingConstants,
    aemPages,
    flagStatus,
    CUSTOMER,
    AnalyticsEvents,
    AnalyticsScreenEventNames,
    thingVuseProperties,
    crmUserSettings,
    os
} from '../../_constants';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import AES from 'crypto-js/aes';
import { Commons } from '../../_helpers/commons';
import "animate.css/animate.min.css";
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmptyParsed, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { logAnalyticsEvent, updateThingsInUserPropertiesAnalyticsEvents } from '../../_helpers/analytics/logAnalytics';
import { store } from '../../_store';
import moment from 'moment';
import { renderAllSteps, getActiveStep, triggerLandingOn } from '../../_helpers/multipleStepWiew';
import { getLocale } from '../../_actions/appConfig.actions';
import { getTruncateAppVersion } from '../../_helpers/utils';
import { Header } from '../../_components';

export const VaultSetup = (props) => {

    const [vaultPin, setVaultPin] = useState(null);
    const [unblock, setUnblock] = useState(null);
    const [imgSrc, setImgSrc] = useState("");
    const [videoLoop, setVideoLoop] = useState(true);
    const [wizardInstance, setWizardInstance] = useState(null);
    const [loading, setLoading] = useState(false);

    const oldUserTenantId = useSelector(state => state.onboardingReducer.oldUserTenantId);
    const isResetPinFlow = useSelector(state => state.onboardingReducer.isResetPinFlow);
    const isCreatingNewPin = useSelector(state => state.onboardingReducer.isCreatingNewPin);
    const loadingOnBoarding = useSelector(state => state.onboardingReducer.loading);

    const dispatch = useDispatch();

    const aem = new AEMHelper();
    const dictionary = aem.getDictionary(aemPages.VAULT_FIRST, {
        VAULT_HEADER: propertyHeadingDefaultEmptyParsed,
        VAULT_CONTENT: propertyTextDefaultEmptyParsed,
        VAULT_BTN_CREATE: propertyCtaItemsDefaultEmpty,
        VAULT_PIN_LINK: propertyTextDefaultEmpty,
        VAULT_INFO_CONTENT: propertyTextDefaultEmptyParsed,
        VAULT_INFO_HEADER: propertyHeadingDefaultEmptyParsed,
        VAULT_STEP_PIN_TITLE: propertyTextDefaultEmpty,
        VAULT_RESET_PIN_TITLE: propertyTextDefaultEmpty,
        VAULT_FIRST_TIME_USER_CREATE_PIN: propertyTextDefaultEmpty,
        VAULT_RESET_PIN_AFTER_FORGETTING: propertyTextDefaultEmpty,
        VAULT_STEP_PIN_CONTENT: propertyTextDefaultEmptyParsed,
        VAULT_BTN_CONFIRM_PIN: propertyCtaItemsDefaultEmpty,
        VAULT_STEP_QUESTION_TITLE: propertyTextDefaultEmptyParsed,
        VAULT_STEP_QUESTION_1: propertyTextDefaultEmpty,
        VAULT_STEP_QUESTION_2:propertyTextDefaultEmpty,
        VAULT_STEP_QUESTION_3: propertyTextDefaultEmpty,
        VAULT_STEP_QUESTION_4: propertyTextDefaultEmpty,
        VAULT_BTN_CONFIRM_QUESTION: propertyCtaItemsDefaultEmpty,
        VAULT_SECURED_HEADER: propertyHeadingDefaultEmptyParsed,
        VAULT_BTN_CONTINUE: propertyCtaItemsDefaultEmpty
    });

    useEffect(() => {
        triggerLandingOn(vaultSteps(), getActiveStep());

        document.body.classList.add("vault-setup");
        setUnblock(history.block);
        setImgSrc(imagesConstants.SPLASH_Loop);

        return () => {
            document.body.classList.remove("vault-setup");
        }
    }, []);

    const onStepChange = (stepConf) => {
        triggerLandingOn(vaultSteps(), stepConf.activeStep);

        if (stepConf.activeStep === 2) {
            setImgSrc(imagesConstants.PIN_ScaleDown);
            setVideoLoop(false);
        } else if (stepConf.activeStep === 4) {
            setImgSrc(imagesConstants.PIN_ScaleUp);
            setVideoLoop(false);
        }
    }

    const goToNextStep = () => {
        unblock();
        history.push(buildURI(routingConstants.NOTIFICATION_INTRO));
    }

    const setPin = (vaultPin) => {
        setVaultPin(vaultPin);
    }

    /**
     * Secure vault pin and answer in IoT Platform
     * and forward user to next page
     * @param {*} data 
     */
    const secureData = (data) => {

        setLoading(true);

        //sanitize security answer
        const sanitizedAnswer = Commons.sanitizeSecurityAnswer(data.answer);

        //encript PIN using answer
        let aesPIN = AES.encrypt(`${vaultPin}`, `${sanitizedAnswer}${data?.question?.key}`);
        console.log(`[VAULT CREATE] vaultPin: ${vaultPin}`);
        console.log(`[VAULT CREATE] aesPIN: ${aesPIN}`);


        //Generate tenant-user-id
        const tenantUserId = Commons.generateTenantUserId(vaultPin);
        console.log(`[VAULT CREATE] tenantUserId: ${tenantUserId}`);

        //set pin in reducer (for auth in IoT platform)
        dispatch(onboardingActions.setUserPin(vaultPin, () => {

            dispatch(onboardingActions.secureVaultData(tenantUserId,
                (response) => {
                    console.log("[VAULT CREATE] generateUserEntry response", response);

                    if (response?.result?.code === 0) {
                        const payload = {
                            encryptedPin: aesPIN.toString()
                        }

                        console.log(`[VAULT CREATE] storePin payload: ${payload}`);

                        //BE secure data
                        dispatch(onboardingActions.storeVaultPin(payload, tenantUserId,
                            (response) => {
                                console.log("[VAULT CREATE] storePin response", response);

                                if (response?.result?.code === 0) {
                                    dispatch(onboardingActions.setIsResetPinFlow(false));
                                    setDoneVault(null);

                                    saveEverLogged(tenantUserId)

                                } else {
                                    handleError();
                                }

                            }, () => {
                                handleError();
                            }));
                    } else {
                        handleError();
                    }

                }, () => {
                    handleError();
                }));
        }));

    }

    const handleError = () => {
        store.dispatch(onboardingActions.setUserPin(null));
        store.dispatch(commonsActions.showErrorAlert(true));
        logAnalyticsEvent(AnalyticsEvents.APP_ERROR);
        setLoading(false);
    }

    const saveEverLogged = (tenantUserId) => {
        //Set the everLogged flag to 1!
        dispatch(userActions.updateUserInfo({ userExtra: [
            {
                key: "MYVUSE_EVER_LOGGED",
                value: true
            }
        ] }, (response) => {

            console.log("[setUserEverLogged] response", response);
            if (response?.user) {
                const userEverLoggedTimestamp = (response?.user?.userExtra).find(prop => prop.key === "MYVUSE_EVER_LOGGED")?.createdAt;
                setUserEverLogged(userEverLoggedTimestamp);
            }

            //continue execution
            store.dispatch(
                userActions.setUserSettings(
                    crmUserSettings.LAST_SESSION,
                    (new Date()).toISOString()
                )
            )
    
            // Get uuids associated to user
            dispatch(commonsActions.getVendors(tenantUserId,
                (response) => {
                    console.log("[VAULT CREATE] getVendors response", response);
    
                    if (response?.data) {
                        //set localstorage things
                        setThings(response.data);
    
                        const vendorVuse = response.data.find(el => el.vendor === CUSTOMER);
                        console.log("[VAULT CREATE] getVendors vendorVuse", vendorVuse);
                        if (vendorVuse?.uuids?.length > 0) {
                            setVuseUuid(vendorVuse.uuids[0]);
                        }
    
                        /* SET CUSTOMER PROPERTIES */
                        dispatch(commonsActions.setThings(
                            [
                                { type: thingVuseProperties.MKT_LASTACTIVE, data: moment().valueOf() },
                                { type: thingVuseProperties.APPVERSION, data: (getTruncateAppVersion()).toString() },
                                { type: thingVuseProperties.TC, data: flagStatus.ACCEPTED },
                                { type: thingVuseProperties.LOCALE, data: getLocale() },
                                { type: thingVuseProperties.TIMEZONE, data: moment().utcOffset() / 60 },
                                { type: thingVuseProperties.OS, data: os },
                            ],
                            tenantUserId,
                            vendorVuse.uuids[0],
                            CUSTOMER,
                            (response) => {
    
                                /* LOAD CUSTOMER THING */
                                dispatch(commonsActions.getThings(tenantUserId, vendorVuse.uuids[0], CUSTOMER, (response) => {
                                    if (response?.result?.code === 0 && response?.data[0]?.properties) {
                                        updateThingsInUserPropertiesAnalyticsEvents(response.data[0].properties)
                                    }
                                }));
    
                                console.log("[VAULT CREATE] setThings response", response);
                                if (response === true) {
                                    setAcceptedTermsAndConditions(true);
                                    //go to success step
                                    if (wizardInstance) {
                                        wizardInstance.nextStep();
                                    }
                                } else {
                                    handleError();
                                }
                            }, () => {
                                /* LOAD CUSTOMER THING */
                                dispatch(commonsActions.getThings(tenantUserId, vendorVuse.uuids[0], CUSTOMER, (response) => {
                                    if (response?.result?.code === 0 && response?.data[0]?.properties) {
                                        updateThingsInUserPropertiesAnalyticsEvents(response.data[0].properties)
                                    }
                                }));
    
                                //handleError();
    
                                setLoading(false);
                        
                            }
                        ));
    
                    } else {
                        handleError();
                    }
                }, () => {
                    handleError();
                })
            );
        }, (error) => {
            console.log("[setUserEverLogged] error", error);
            //try again
            saveEverLogged(tenantUserId)
        }));
    }

    const updateData = (data) => {

        const sanitizedAnswer = Commons.sanitizeSecurityAnswer(data.answer);
        console.log(`[VAULT UPDATE] sanitizedAnswer: ${sanitizedAnswer}`);

        const tenantUserId = Commons.generateTenantUserId(vaultPin);
        console.log(`[VAULT UPDATE] tenantUserId: ${tenantUserId}`);

        dispatch(onboardingActions.setUserPin(vaultPin, () => {

            dispatch(onboardingActions.updateVaultData(
                { newTenantUserID: tenantUserId },
                oldUserTenantId,
                (response) => {
                    if (response?.result?.code === 0) {

                        let aesPIN = AES.encrypt(`${vaultPin}`, `${sanitizedAnswer}${data?.question?.key}`);

                        //BE secure data
                        dispatch(onboardingActions.updateVaultPin(
                            { newEncryptedPin: aesPIN.toString() },
                            tenantUserId,
                            (response) => {
                                if (response?.result?.code === 0) {
                                    setDoneVault(null);

                                    store.dispatch(
                                        userActions.setUserSettings(
                                            crmUserSettings.LAST_SESSION,
                                            (new Date()).toISOString()
                                        )
                                    )

                                    // Get uuids associated to user
                                    dispatch(commonsActions.getVendors(tenantUserId,
                                        (response) => {
                                            console.log("[VAULT CREATE] getVendors response", response);

                                            if (response?.data) {
                                                //set localstorage things
                                                setThings(response.data);

                                                const vendorVuse = response.data.find(el => el.vendor === CUSTOMER);
                                                console.log("[VAULT CREATE] getVendors vendorVuse", vendorVuse);
                                                if (vendorVuse?.uuids?.length > 0) {
                                                    setVuseUuid(vendorVuse.uuids[0]);
                                                }

                                                /* SET CUSTOMER PROPERTIES */
                                                dispatch(commonsActions.setThings(
                                                    [
                                                        { type: thingVuseProperties.MKT_LASTACTIVE, data: moment().valueOf() },
                                                        { type: thingVuseProperties.APPVERSION, data: (getTruncateAppVersion()).toString() },
                                                        { type: thingVuseProperties.TC, data: flagStatus.ACCEPTED },
                                                        { type: thingVuseProperties.LOCALE, data: getLocale() },
                                                        { type: thingVuseProperties.TIMEZONE, data: moment().utcOffset() / 60 },
                                                        { type: thingVuseProperties.OS, data: os },
                                                    ],
                                                    tenantUserId,
                                                    vendorVuse.uuids[0],
                                                    CUSTOMER,
                                                    (response) => {

                                                        /* LOAD CUSTOMER THING */
                                                        dispatch(commonsActions.getThings(tenantUserId, vendorVuse.uuids[0], CUSTOMER));

                                                        console.log("[VAULT CREATE] setThings response", response);
                                                        if (response === true) {
                                                            setAcceptedTermsAndConditions(true);
                                                            
                                                            //reset old user tenant as is not useful anymore
                                                            dispatch(onboardingActions.setOldUserTenantId(null));
                                                            
                                                            //go to success step
                                                            if (wizardInstance) {
                                                                wizardInstance.nextStep();
                                                            }
                                                        } else {
                                                            handleError();
                                                        }
                                                    }, () => {
                                                        /* LOAD CUSTOMER THING */
                                                        dispatch(commonsActions.getThings(tenantUserId, vendorVuse.uuids[0], CUSTOMER));

                                                        //handleError();
                                                    }
                                                ));

                                            } else {
                                                handleError();
                                            }
                                        }, () => {
                                            handleError();
                                        }));
                                    
                                    
                                    // if (wizardInstance) {
                                    //     wizardInstance.nextStep();
                                    // }
                                } else {
                                    handleError();
                                }
                            }, () => {
                                handleError();
                            }));
                    } else {
                        handleError();
                    }
                }, () => {
                    handleError();
                }))
        }))

    }

    const handleVideoEnd = (e) => {
        if (imgSrc === imagesConstants.PIN_ScaleDown) {
            setImgSrc(imagesConstants.PIN_LOOP);
            setVideoLoop(true);
        } else if (imgSrc === imagesConstants.PIN_ScaleUp) {
            setImgSrc(imagesConstants.SECURED_Final);
            setVideoLoop(false);
        }
    }

    const vaultSteps = () => {
        const isUpdate = oldUserTenantId !== null && oldUserTenantId !== undefined && oldUserTenantId !== "";

        return [
            {
                onLanding: () => {
                    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                        screen_name: AnalyticsScreenEventNames.VAULT_CREATE_PIN_INTRO,
                    });
                },
                render: () => <VaultSetupIntro key="vaultStep-1" dictionary={dictionary} />
            },
            {
                onLanding: () => {
                    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                        screen_name: AnalyticsScreenEventNames.VAULT_CONFIRM_PIN,
                    });
                },
                render: () => <VaultSetupPin key="vaultStep-2" dictionary={dictionary} persistPin={setPin} isResetPinFlow={isResetPinFlow} />
            },
            {
                onLanding: () => {
                    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                        screen_name: AnalyticsScreenEventNames.SECURITY_QUESTION,
                    });
                },
                render: () => <VaultSetupSecureQuestion key="vaultStep-3" dictionary={dictionary} secureData={isUpdate ? updateData : secureData} isLoading={loading || loadingOnBoarding} />
            },
            {
                onLanding: () => {
                    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
                        screen_name: AnalyticsScreenEventNames.VAULT_SUCCESS,
                    });
                },
                render: () => <VaultSetupEnd key="vaultStep-4" dictionary={dictionary} isResetPinFlow={isResetPinFlow} isCreatingNewPin={isCreatingNewPin} setIsCreatingNewPin={(value) => dispatch(onboardingActions.setIsCreatingNewPin(value))} goToNextStep={goToNextStep} />
            },
        ]
    }

    return (
        <React.Fragment>
            <div className="page w-100">
                <Header transparent={true} />
                <StepWizard
                    onStepChange={onStepChange}
                    transitions={{
                        enterRight: `animate__animated animate__fadeIn`,
                        enterLeft: `animate__animated animate__fadeIn`,
                        exitRight: `animate__animated animate__fadeOut`,
                        exitLeft: `animate__animated animate__fadeOut`,
                    }}
                    className="h-100"
                    isLazyMount={true}
                    instance={(wizardInstance) => setWizardInstance(wizardInstance)}
                >
                    {renderAllSteps(vaultSteps())}
                </StepWizard>
            </div>

            <video className="vault-bg-video" src={imagesConstants.SPLASH_Loop}
                autoPlay muted playsInline loop={videoLoop}
                style={{ display: imgSrc === imagesConstants.SPLASH_Loop ? "block" : "none" }}
                onEnded={handleVideoEnd}></video>
            <video className="vault-bg-video" src={imagesConstants.PIN_ScaleUp}
                autoPlay muted playsInline loop={videoLoop}
                preload={"auto"}
                style={{ display: imgSrc === imagesConstants.PIN_ScaleUp ? "block" : "none" }}
                onEnded={handleVideoEnd}></video>
            <video className="vault-bg-video" src={imagesConstants.PIN_LOOP}
                autoPlay muted playsInline loop={videoLoop}
                preload={"auto"}
                style={{ display: imgSrc === imagesConstants.PIN_LOOP ? "block" : "none" }}
                onEnded={handleVideoEnd}> </video>
            <video className="vault-bg-video" src={imagesConstants.PIN_ScaleDown}
                autoPlay muted playsInline loop={videoLoop}
                preload={"auto"}
                style={{ display: imgSrc === imagesConstants.PIN_ScaleDown ? "block" : "none" }}
                onEnded={handleVideoEnd}></video>
            <video className="vault-bg-video" src={imagesConstants.SECURED_Final}
                autoPlay muted playsInline loop={videoLoop}
                preload={"auto"}
                style={{ display: imgSrc === imagesConstants.SECURED_Final ? "block" : "none" }}
                onEnded={handleVideoEnd}></video>

        </React.Fragment>
    );


};