import React from "react";
import { imagesConstants } from '../../_constants';
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";

/**
 * Render header of onboarding steps
 */
 export const OnboardingHeader = (props) => {
    return (
        <div className="page-header w-100 sticky-top">
            <header className="d-flex align-items-center justify-content-center">
                {props.link && <button className="page-header-icon" onClick={() => history.push(buildURI(props.link))}><span className="bat-icon-back"></span></button>}
                {props.action && <button className="page-header-icon" onClick={props.action}><span className="bat-icon-back"></span></button>}
                <div className="page-header-logo"><img src={imagesConstants.MYVUSE_lOGO} alt="MUSE logo" /></div>
            </header>
            {
                props.title && 
                
                <div className="page-header-title-onboarding d-flex align-items-center justify-content-center">
                    <h1>{props.title}</h1>
                </div>
            }
        </div>
    )

};
