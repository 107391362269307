import React, { useEffect, useState } from "react";
import ReactSlider from 'react-slider';

export const DeviceVolume = ({ setVolume, volume, title, disabled }) => {

    const [tempVolume, setTempVolume] = useState(0);

    useEffect(() => {
        setTempVolume(volume);
    }, [volume])

    return (
        <div className="device-settings-cta">
            <label className="page-label" htmlFor="horizontal-slider">{title}</label>
            <div className="d-flex align-items-center">
                <span className="bat-icon-volume-low pe-2"></span>
                
                <ReactSlider
                    className="horizontal-slider"
                    markClassName="slider-mark"
                    min={5}
                    step={5}
                    max={100}
                    disabled={disabled}
                    thumbClassName="slider-thumb"
                    trackClassName="slider-track"
                    value={tempVolume}
                    renderTrack={(props, state) => {
                        return (
                            <div {...props}>{state.valueNow}</div>
                        );
                    }}
                    renderThumb={(props, state) => {
                        return (<div data-value={""} {...props} />);
                    }}
                    onAfterChange={(position) => setVolume(position)}
                    onChange={(position) => setTempVolume(position)}
                />

                <span className="bat-icon-volume-high ps-2"></span>
            </div>
        </div>
    );

};
