import React, { useEffect, useState } from "react"
import { ReconnectCTA } from "../ReconnectCTA"
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyTextDefaultEmpty } from "../../_helpers/aem/aemhelper"
import { aemPages } from "../../_constants"
import { isDeviceConnected } from "../../_helpers/device"
import { getLastUpdate } from "../../_helpers/usage-tracker"
import moment from "moment"

export function UsageTrackerReconnect({
  device
}) {
  const [dictionary, setDictionary] = useState([])
  const [isConnected, setIsConnected] = useState(false)
  const [lastRefreshDate, setLastRefreshDate] = useState(null)
  const [showOverlay, setShowOverlay] = useState(false)

  useEffect(() => {
    const aem = new AEMHelper()

    setDictionary({
      ...aem.getDictionary(aemPages.HOMEPAGE, {
        HOME_BTN_RECONNECT: propertyCtaItemsDefaultEmpty
      }),

      ...aem.getDictionary(aemPages.USAGE_TRACKER, {
        UT_LAST_REFRESH_LABEL: propertyTextDefaultEmpty
      })
    })
  }, [])

  useEffect(() => {
    getLastUpdate(device, (date) => {
      if (date) {
        setLastRefreshDate(date)
      } else {
        setLastRefreshDate('')
      }
    })

    setIsConnected(isDeviceConnected(device))
  }, [device])

  return (
    <>
    {(!isConnected && device) && (
      <div className="usage-tracker-reconnect-container">
        {showOverlay && (
          <div className="connecting-overlay">
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="usage-tracker-reconnect-sub">
          <div className="usage-tracker-last-refresh">
            <div>{dictionary.UT_LAST_REFRESH_LABEL || "LAST REFRESH"}</div>
            {lastRefreshDate && (
              <div>{moment(lastRefreshDate).format("DD MMM YYYY — hh:mm A")}</div>
            )}
          </div>
          <ReconnectCTA device={device} isAbsolute={false} onStart={() => setShowOverlay(true)} onSuccess={() => setShowOverlay(false)}>
            {dictionary.HOME_BTN_RECONNECT_0_ctaLabel}
          </ReconnectCTA>
        </div>
      </div>
    )}
    </>
  )
}