import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DashboardContext } from "../../Views";
import { getMoreMenu, getUrl, isSupportedService } from "../../_actions/appConfig.actions";
import {
  FeatureWidget,
  FeatureWidgetGroup,
  Header,
  NetworkConnectionLost,
  UserAvatar,
  LoyaltyLogo,
} from "../../_components";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  aemPages,
  routingConstants,
  servicesConstants,
  webviewUrl,
} from "../../_constants";
import {
  AEMHelper,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import { debug } from "../../_helpers/debug";
import { Tenants } from "../../_helpers/tenants";
import { getTruncateAppVersion } from "../../_helpers/utils";
import { loyaltyServices } from "../../_services";

const MoreMenu = () => {
  const [dictionary, setDictionary] = useState({});
  const [menu, setMenu] = useState([]);
  const [featuresConfig, setFeaturesConfig] = useState({});
  const { navbarHeight } = useContext(DashboardContext) || {};
  const [hasOptedInLoyalty, setHasOptedInLoyalty] = useState(null);

  const devices = useSelector(state => state?.deviceReducer?.devices);

  const checkLoyaltyProgram = async () => {
    try {
      const res = await loyaltyServices.getLoyaltyStatus();
      console.log('res.data?.data', res.data?.data.status)
      setHasOptedInLoyalty(res.data?.data?.status)

      console.debug("getLoyaltyStatus success: ", res);
      debug("getLoyaltyStatus success: " + JSON.stringify(res));
    } catch (err) {
      console.debug("getLoyaltyStatus failure: ", err);
      debug("getLoyaltyStatus failure: " + JSON.stringify(err));
    }
  }

  const goToLoyaltyProgram = () => {
    if (hasOptedInLoyalty !== null) {
      let screen_name;
      hasOptedInLoyalty ? screen_name = AnalyticsScreenEventNames.LOYALTY : screen_name = AnalyticsScreenEventNames.LOYALTY_NOT_JOINED;
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, { screen_name: screen_name });

      //logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      //  screen_name: AnalyticsScreenEventNames.LOYALTY,
      //  optin: hasOptedInLoyalty,
      //}); --> old

      if (hasOptedInLoyalty) {
        Commons.goTo(routingConstants.LOYALTY_DASHBOARD, {
          loyaltyStatus: hasOptedInLoyalty,
        });
      } else {
        console.debug("redirect to LoyaltyOptin");
        debug("redirect to LoyaltyOptin");
        Commons.goTo(routingConstants.LOYALTY_OPTIN, {
          loyaltyStatus: hasOptedInLoyalty,
        });
      }
    }
  };

  useEffect(() => {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.MORE_MENU})
    if (isSupportedService(servicesConstants.MENU_LOYALTY)) {
      checkLoyaltyProgram()
    }

    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.BURGER_MENU, {
        MENU_SUB_MYACCOUNT: propertyTextDefaultEmpty,
        MENU_ACCOUNT: propertyTextDefaultEmpty,
        MENU_LOYALTY: propertyTextDefaultEmpty,
        MENU_SUB_MANAGE: propertyTextDefaultEmpty,
        MENU_MANAGE_DEVICES: propertyTextDefaultEmpty,
        MENU_MANAGE_NOTIFICATIONS: propertyTextDefaultEmpty,
        MENU_YOUR_PREFERENCES: propertyTextDefaultEmpty,
        MENU_SUB_SUPPORT: propertyTextDefaultEmpty,
        MENU_DEVICE_APP_FAQ: propertyTextDefaultEmptyParsed,
        MENU_FAQS_ANDROID: propertyTextDefaultEmpty,
        MENU_CONTACT_US: propertyTextDefaultEmpty,
        MENU_SUB_LEGAL: propertyTextDefaultEmpty,
        MENU_TAC: propertyTextDefaultEmptyParsed,
        MENU_PP: propertyTextDefaultEmpty,
      })
    );

    setMenu(getMoreMenu());
  }, [])

  useEffect(() => {
    setFeaturesConfig({
      MENU_MYACCOUNT: {
        icon: <UserAvatar />,
        label: "MENU_ACCOUNT",
        onClick: () => {Commons.goTo(routingConstants.MANAGE_ACCOUNT); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ACCOUNT})},
      },
      MENU_LOYALTY: {
        icon: <LoyaltyLogo />,
        onClick: () => {goToLoyaltyProgram(); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.LOYALTY})},
      },
      MENU_MANAGE_DEVICES: {
        icon: "MORE_MENU_MANAGE_DEVICES",
        label: "MENU_MANAGE_DEVICES",
        onClick: () => {Commons.goTo(routingConstants.MANAGE_DEVICES); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DEVICES})},
      },
      MENU_MANAGE_NOTIFICATIONS: {
        icon: "MORE_MENU_NOTIFICATION_PREFERENCES",
        label: "MENU_MANAGE_NOTIFICATIONS",
        onClick: () => {Commons.goTo(routingConstants.MANAGE_NOTIFICATIONS); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NOTIFICATIONS})},
      },
      MENU_YOUR_PREFERENCES: {
        icon: "MORE_MENU_APP_PREFERENCES",
        label: "MENU_YOUR_PREFERENCES",
        onClick: () => {Commons.goTo(routingConstants.YOUR_PREFERENCES); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PREFERENCES})},
      },
      MENU_DEVICE_FAQ: {
        icon: "MORE_MENU_DEVICE_HELP",
        label: "MENU_DEVICE_APP_FAQ",
        onClick: () => {Commons.goTo(routingConstants.FAQ); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DEVICE_FAQ})},
      },
      MENU_FAQS: {
        icon: "MORE_MENU_ACCOUNT_HELP",
        label: "MENU_FAQS_ANDROID",
        onClick: () => {Commons.goToSupport(); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ACCOUNT_FAQ})},
      },
      MENU_CONTACT_US: {
        icon: "MORE_MENU_CONTACT_US",
        label: "MENU_CONTACT_US",
        onClick: () =>
          {window.open(
            `${getUrl(webviewUrl.WEBVIEW_CONTACT_US)}`,
            "_blank"
          ); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CONTACT_US})},
      },
      MENU_TAC: {
        icon: "MORE_MENU_TERMS_CONDITIONS",
        label: "MENU_TAC",
        onClick: () =>
          {Commons.goTo(routingConstants.TERMS_AND_CONDITIONS_LIST, {
            canClose: true,
            logo: false,
          }); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.TERMS_AND_CONDITIONS})},
      },
      MENU_PP: {
        icon: "MORE_MENU_PRIVACY_POLICY",
        label: "MENU_PP",
        onClick: () =>
          {Commons.goTo(routingConstants.PRIVACY_POLICY, {
            canClose: true,
          }); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.PRIVACY_POLICY})},
      },
    });
  }, [hasOptedInLoyalty]);

  return (
    <div
      className="page w-100 more-menu"
      style={{ paddingBottom: `${navbarHeight}px` }}
    >
      <div className="page-wrapper h-100 p-3">
        {menu.map(({ label, features }, i) => (
          <FeatureWidgetGroup key={i} title={dictionary?.[`MENU_SUB_${label}`]}>
            {features.map((featureName, j) => (
              (featureName !== "MENU_MANAGE_DEVICES" || devices.length > 0) &&
              <FeatureWidget
                key={j}
                className="flat"
                icon={featuresConfig?.[featureName]?.icon}
                label={dictionary?.[featuresConfig?.[featureName]?.label]}
                onClick={featuresConfig?.[featureName]?.onClick}
              />
            ))}
          </FeatureWidgetGroup>
        ))}
        <div className="app-version">
          MYVUSE {getTruncateAppVersion()}{" "}
          {process.env.REACT_APP_BUILD_NUMBER
            ? `(${process.env.REACT_APP_BUILD_NUMBER})`
            : ``}
        </div>
      </div>
      <div className="page-linear-gradient-bg" />
      {/* <NetworkConnectionLost
        translucent={true}
        header={
          <Header
            rightButton={{
              icon: <span className="bat-icon-close" />,
              onClick: () => Commons.goToDashboard({ isMoreMenuOpen: false }),
            }}
          />
        }
      /> */}
    </div>
  );
};

export { MoreMenu };
