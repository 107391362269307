import moment from "moment";
import { flagStatus, thingVuseProperties } from "../../_constants";
import { Commons } from "../commons";

export * from "./db";

export function isPodTrackerEnabled() {
    return (
        Commons.getCustomerProperty(thingVuseProperties.OPT_IN_POD_TRACKER) ===
        flagStatus.ACCEPTED
    );
}

export function getPodTrackerOptInDate() {
    const podTrackerOptinDate = Commons.getCustomerPropertyLastUpdate(
        thingVuseProperties.OPT_IN_POD_TRACKER
    );
   
    return moment.utc(podTrackerOptinDate).local().toString();
}