export const DEFAULT_PROVINCE = "UK";
export const COUNTRY_ISO_CODE = "UK";
export const VUSE_DEVICE = "VUSE_DEVICE";
export const VUSE = "VUSE";
export const CUSTOMER = "CUSTOMER";
export const os = "ios";
export const ANIMATION_DURATION = 300;

export const commonsConstants = {
  GET_TOKEN: "commons/GET_TOKEN",
  SET_THINGS: "commons/SET_THINGS",
  ADD_THING: "commons/ADD_THING",
  GET_THINGS: "commons/GET_THINGS",
  DELETE_THING: "commons/DELETE_THING",
  GET_VENDORS: "commons/GET_VENDORS",
  GET_GROUP: "commons/GET_GROUP",
  SHOW_ERROR: "commons/SHOW_ERROR",
  GET_COUNTRIES: "commons/GET_COUNTRIES",
  SET_IS_UPDATING_THINGS_TO_IOT_PLATFORM:
    "commons/SET_IS_UPDATING_THINGS_TO_IOT_PLATFORM",

  DEBUG_COMMONS: "commons/DEBUG_COMMONS",
  RESET_DEBUG_COMMONS: "commons/RESET_DEBUG_COMMONS",
  DEBUG_ANALYTICS: "commons/DEBUG_ANALYTICS",
  RESET_DEBUG_ANALYTICS: "commons/RESET_DEBUG_ANALYTICS",
};

export const servicesConstants = {
  ONBOARDING_SOFT_AGE_VERIFICATION: "ONBOARDING_SOFT_AGE_VERIFICATION",
  ONBOARDING_VAULT: "ONBOARDING_VAULT",
  ONBOARDING_NOTIFICATION_PREFERENCES: "ONBOARDING_NOTIFICATION_PREFERENCES",
  ONBOARDING_MARKET_ENABLE: "ONBOARDING_MARKET_ENABLE",
  ONBOARDING_AV_MODAL: "ONBOARDING_AV_MODAL",
  PHONE_PREFIX_INCLUDE: "PHONE_PREFIX_INCLUDE",
  ONBOARDING_AV_YOTI: "ONBOARDING_AV_YOTI",
  HOME_DEVICE: "HOME_DEVICE",
  HOME_SHOP: "HOME_SHOP",
  HOME_VUSE_WORLD: "HOME_VUSE_WORLD",
  HOME_NOTIFICATION_HISTORY: "HOME_NOTIFICATION_HISTORY",
  HOME_BATTERY_PREFERENCES: "HOME_BATTERY_PREFERENCES",
  HOME_CLOUD_CONTROL: "HOME_CLOUD_CONTROL",
  HOME_FIND_MY_VAPE: "HOME_FIND_MY_VAPE",
  HOME_DEVICE_SETTINGS: "HOME_DEVICE_SETTINGS",

  MENU_LOYALTY: "MENU_LOYALTY",
  MENU_MANAGE_NOTIFICATIONS: "MENU_MANAGE_NOTIFICATIONS",
  MENU_YOUR_PREFERENCES: "MENU_YOUR_PREFERENCES",
  MENU_PAST_ORDERS: "MENU_PAST_ORDERS",
  MENU_TAC_PP: "MENU_TAC_PP",
  MENU_FAQS: "MENU_FAQS",
  MENU_FEATURE_TUTORIALS: "MENU_FEATURE_TUTORIALS",
  MENU_CONTACT_US: "MENU_CONTACT_US",
  MENU_DEVICE_FAQ: "MENU_DEVICE_FAQ",
  MENU_STORE_LOCATOR: "MENU_STORE_LOCATOR",

  TAC_ONLINE_TAC: "TAC_ONLINE_TAC",
  TAC_MYVUSEPP: "TAC_MYVUSEPP",
  TAC_ONLINE_COS: "TAC_ONLINE_COS",
  TAC_SUB_TAC: "TAC_SUB_TAC",

  FAVOURITES: "FAVOURITES",

  ONE_TRUST_COOKIE: "ONE_TRUST_COOKIE",

  ANALYTICS_GOOGLE_DISABLED: "ANALYTICS_GOOGLE_DISABLED",
  DEMO_DISCLAIMER: "DEMO_DISCLAIMER",
  ACCOUNT_PAGE_DISABLED: "ACCOUNT_PAGE_DISABLED",
  ONBOARDING_ADDRESS_MANUAL: "ONBOARDING_ADDRESS_MANUAL",

  USAGE_TRACKER: "USAGE_TRACKER",
  USAGE_TRACKER_RAW: "USAGE_TRACKER_RAW",

  BATTERY_SAVER: "BATTERY_SAVER",
  EPOD_ADVERTISING_NAME: "EPOD_ADVERTISING_NAME",

  EM_DATA_DOWNLOAD: "EM_DATA_DOWNLOAD",
  EM_ACCOUNT_DELETION: "EM_ACCOUNT_DELETION",
  SHOP_OTP: "SHOP_OTP",

  ONBOARDING_AGE_WARNING: "ONBOARDING_AGE_WARNING",
  ONBOARDING_EMAIL_REG_VERIFICATION: "ONBOARDING_EMAIL_REG_VERIFICATION",
  QUALTRICS: "QUALTRICS",
  SFMC_PUSH_NOTIFICATION: "SFMC_PUSH_NOTIFICATION",

  USAGE_TRACKER_HOURLY_GRAPH: "USAGE_TRACKER_HOURLY_GRAPH",

  POD_TRACKER: "POD_TRACKER",

  LOYALTY_BADGE_TITLE: "LOYALTY_BADGE_TITLE",
  LOYALTY_BADGE_DESCRIPTION: "LOYALTY_BADGE_DESCRIPTION",
  MARKETING_OPTIN_SMS: "MARKETING_OPTIN_SMS",

  WEBVIEW_SIGNUP: "WEBVIEW_SIGNUP",
  WEBVIEW_LOGIN: "WEBVIEW_LOGIN",
};
