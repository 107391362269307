import React from 'react';
import { OnboardingHeader } from '../../_components';
import { aemPages, routingConstants, imagesConstants } from '../../_constants';
import { AEMHelper, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { buildURI } from '../../_helpers/navigation';
import { Commons } from '../../_helpers/commons';

/**
 * Currently OOS for Canada
 */
class FeaturesTutorial extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.handleBack = this._handleBack.bind(this);
        this.goToTutorial = this._goToTutorial.bind(this);

        this.aem = new AEMHelper();
        this.dictionary = {
            ...this.aem.getDictionary(aemPages.CLOUD_CONTROL_TUTORIAL, {
                CLOUD_TUTORIAL_HEADER: propertyHeadingDefaultEmpty
            }),
            ...this.aem.getDictionary(aemPages.BURGER_MENU, {
                MENU_FEATURE_TUTORIALS: propertyTextDefaultEmpty
            }),
        }
    }

    componentDidMount() {

    }

    _handleBack() {
        Commons.goToDashboard();
    }

    _goToTutorial(url) {
        this.props.history.push({
            pathname: buildURI(url),
            state: {
                activeTab: 4 //not sure
            },
        });
    }


    render() {

        return (
            <div className="page w-100">
                <OnboardingHeader title={this.dictionary.MENU_FEATURE_TUTORIALS} action={this.handleBack} />

                <div className='features-tutorial-wrapper'>

                    <div className='features-tutorial-box'>
                        <div className='row'>
                            <div className='col-12'><h2>{this.dictionary.CLOUD_TUTORIAL_HEADER}</h2></div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-6'><img src={require("../../_assets/img/Cloud_control_tutorial_icon.webp")} alt="failure" /></div>
                            <div className='col-6'>
                                <button className="play-tutorial" onClick={() => this.goToTutorial(routingConstants.CLOUD_CONTROL_TUTORIAL)}>
                                    <img src={imagesConstants.CLOUD_CONTROL_PLAY_TUTORIAL} />
                                </button>
                                <span className='mt-2'>Play tutorial</span>
                            </div>
                        </div>
                    </div>

                    <div className='features-tutorial-box'>
                        <div className='row'>
                            <div className='col-12'><h2>UNLOCK MY DEVICE</h2></div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-6'><div className="feature-icon feature-unlock"> <span className='bat-icon-lock-outline-check'></span></div></div>
                            <div className='col-6'>
                                <button className="play-tutorial" onClick={() => this.goToTutorial(routingConstants.UNLOCK_TUTORIAL)}>
                                    <img src={imagesConstants.CLOUD_CONTROL_PLAY_TUTORIAL} />
                                </button>
                                <span className='mt-2'>Play tutorial</span>
                            </div>
                        </div>
                    </div>

                    <div className='features-tutorial-box'>
                        <div className='row'>
                            <div className='col-12'><h2>FIND MY VAPE</h2></div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-6'><div className="feature-icon feature-find"> <span className='bat-icon-find-my-vape'></span></div></div>
                            <div className='col-6'>
                                <button className="play-tutorial" onClick={() => this.goToTutorial(routingConstants.FIND_MY_VAPE_TUTORIAL)}>
                                    <img src={imagesConstants.CLOUD_CONTROL_PLAY_TUTORIAL} />
                                </button>
                                <span className='mt-2'>Play tutorial</span>
                            </div>
                        </div>
                    </div>

                    <div className='features-tutorial-box'>
                        <div className='row'>
                            <div className='col-12'><h2>BATTERY</h2></div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-6'><div className="feature-icon feature-battery"> <span className='bat-icon-battery-in-charge'></span></div></div>
                            <div className='col-6'>
                                <button className="play-tutorial" onClick={() => this.goToTutorial(routingConstants.BATTERY_SETTINGS_TUTORIAL)}>
                                    <img src={imagesConstants.CLOUD_CONTROL_PLAY_TUTORIAL} />
                                </button>
                                <span className='mt-2'>Play tutorial</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export { FeaturesTutorial };