import React, { useEffect, useState } from "react";
import {
  aemPages,
} from "../../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../../_helpers/aem/aemhelper";
import { SignupFormStep } from "./SignupFormStep";
import { useSignupFormContext } from "./SignupFormContext";
import { Tenants } from "../../../_helpers/tenants";

export function PolicyStepCA(props) {
  const [dictionary, setDictionary] = useState({});
  const { showPrivacyPolicyPanel } = useSignupFormContext();

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.REGISTRATION_DETAIL_TAC, {
        REGISTRATION_HEADER: propertyHeadingDefaultEmpty,
        REGISTRATION_CONTENT_DISCLAIMER: propertyTextDefaultEmpty,
        REGISTRATION_LINK_OCOS: propertyTextDefaultEmptyParsed,
        REGISTRATION_SUBTITLE_PRIVACY: propertyTextDefaultEmpty,
        REGISTRATION_LINK_PP: propertyTextDefaultEmptyParsed,
        REGISTRATION_PROVIDED_ACCOUNT: propertyTextDefaultEmpty,
        REGISTRATION_BTN_CREATE_ACCOUNT: propertyCtaItemsDefaultEmpty
      })
    );
  }, []);

  return (
    <SignupFormStep
      title={dictionary.REGISTRATION_HEADER}
      createAccountLabel={Tenants.isCanadaLight() ? dictionary.REGISTRATION_LINK_OCOS : null}
      ctaLabel={dictionary.REGISTRATION_BTN_CREATE_ACCOUNT_0_ctaLabel}
      isLast
      isValid
      className="signup-info-consents-step"
      {...props}
    >
      <div className="signup-form-wrapper add-bg">
        <div className="signup-consents-marketing-step px-0">
          <div className="marketing-content">
            {dictionary.REGISTRATION_PROVIDED_ACCOUNT}
          </div>
        </div>
      </div>

      <div className="signup-consents-marketing-step">
        <div className="page-label">
          {dictionary.REGISTRATION_SUBTITLE_PRIVACY}
        </div>
        <div className="marketing-content hyperlinked" onClick={showPrivacyPolicyPanel}>
          {dictionary.REGISTRATION_LINK_PP}
        </div>
      </div>
    </SignupFormStep>
  );
}
