/**
 * Constants for local notifications type
 */
export const tipsConstants = {
    BATTERY_FULL: "BA-02",
    BATTERY_LOWLEVEL: "BA-01",
    BATTERY_LIFESPAN: "BA-04",
    BATTERY_UNKNOWNLIFESPAN: "BA-05",
    BATTERY_SAVER_ON: "BA_07",
    BATTERY_SAVER_OFF: "BA_08",
    FIRMWARE_UPDATE: "FW-01",
    ORDER_SHIPPED: "OT-01",
    DEVICE_STATUS: "CO-01",
    ERROR_VOLTAGE_ON_LOW: 'DE-01',
    ERROR_VOLTAGE_OFF_LOW: 'DE-02',
    ERROR_VOLTAGE_HIGH: 'DE-03',
    ERROR_CARTOMISER_HIGH: 'DE-04',
    ERROR_TEMPERATURE_LOW: 'DE-05',
    ERROR_TEMPERATURE_HIGH: 'DE-06',
    ERROR_CHARGE_FAIL: 'DE-07',
    ERROR_DEVICE_LOCKED: 'DE-08',
    ERROR_VAPING_ABUSE: 'DE-09',
    ERROR_VAPING_TOO_LONG: 'DE-10',
    ERROR_FAILED_FLASH: 'DE-11',
    ERROR_FAILED_CHARGING: 'DE-12',
    ERROR_FAILED_BATTERY: 'DE-13',
    ERROR_FAILED_SENSOR: 'DE-14',
    ERROR_LARGE_RESISTANCE: 'DE-15',
    ERROR_FAILED_POD_INFO: 'DE-16',
    ERROR_FAILED_UI: 'DE-17',
    ERROR_VOLTAGE_LOW: 'DE-18',
};

/**
 * Constants for remote notifications type
 */
export const tipPropertyData = {
    SUGGESTIONS_ENABLE_FIND_MY_VAPE: "SUGGESTIONS_ENABLE_FIND_MY_VAPE",
    SUGGESTIONS_ENABLE_FLAVOUR_FOR_YOU: "SUGGESTIONS_ENABLE_FLAVOUR_FOR_YOU",
    SUGGESTIONS_ENABLE_CLOUD_CONTROL: "SUGGESTIONS_ENABLE_CLOUD_CONTROL",
    SUGGESTIONS_PAIR: "SUGGESTIONS_PAIR",
    SUGGESTIONS_BATTERYSAVE: "SUGGESTIONS_BATTERYSAVE",
    SUGGESTIONS_USAGE_TRACKER: "SUGGESTIONS_USAGE_TRACKER",
    SUGGESTIONS_APP: "SUGGESTIONS_APP", //handled from be
    ORDER_REMINDER_TIME_TO_RESTOCK_DATETIME: "ORDER_REMINDER_TIME_TO_RESTOCK_DATETIME",
}

/**
 * Constants for notifications categories
 */
export const notificationsCategoriesConstants = {
    DEVICE_ERROR: 'DEVICE_ERROR', //local
    DEVICE_STATUS: 'DEVICE_STATUS',//local
    BATTERY: 'BATTERY',//local
    ORDER_TRACKING: 'ORDER_TRACKING',//remote
    ORDER_REMINDER: 'ORDER_REMINDER',//remote
    FIRMWARE_UPDATE: 'FIRMWARE_UPDATE',//local/remote
    SUGGESTIONS: 'SUGGESTIONS',//remote
    SUBSCRIPTIONS: 'SUBSCRIPTIONS',//remote
    FLAVOUR_FOR_YOU: 'FLAVOUR_FOR_YOU',//remote
    MARKETING: 'MARKETING'//remote
}

/**
 * Constants for notifications actions handling
 */
export const tipsActionConstants = {
    FIRMWARE_NEW_FW_AVAILABLE: 'FIRMWARE:NEW_FW_AVAILABLE',
    SUGGESTIONS_ENABLE_FIND_MY_VAPE: 'SUGGESTIONS:ENABLE_FIND_MY_VAPE',
    SUGGESTIONS_GO_TO_FLAVORS: 'SUGGESTIONS:GO_TO_FLAVORS',
    SUGGESTIONS_GO_TO_CLOUD_CONTROL: 'SUGGESTIONS:GO_TO_CLOUD_CONTROL',
    SUGGESTIONS_GO_TO_PAIR_DEVICE: 'SUGGESTIONS:GO_TO_PAIR_DEVICE',
    SUGGESTIONS_GO_TO_RECHARGE_REMINDER: 'SUGGESTIONS:GO_TO_RECHARGE_REMINDER',
    SUGGESTIONS_GO_TO_WISHLIST: 'SUGGESTIONS:GO_TO_FAVOURITES',
    ORDER_REMINDER_GO_TO_FAVOURITES: 'ORDER_REMINDER:GO_TO_FAVOURITES',

    /* marketing notification actions */
    GO_TO_FIND_MY_VAPE: 'CTA:GO_TO_FIND_MY_VAPE',
    GO_TO_CLOUD_CONTROL: 'CTA:GO_TO_CLOUD_CONTROL',
    GO_TO_PAIR_DEVICE: 'CTA:GO_TO_PAIR_DEVICE',
    GO_TO_BATTERY_CONTROL: 'CTA:GO_TO_BATTERY_CONTROL',
    GO_TO_REORDER: 'CTA:GO_TO_REORDER',
    GO_TO_FLAVOUR_FOR_YOU: 'CTA:GO_TO_FLAVOUR_FOR_YOU',
    GO_TO_FAVOURITES: 'CTA:GO_TO_FAVOURITES',
    GO_TO_DEVICE_SETTINGS: 'CTA:GO_TO_DEVICE_SETTINGS',
    GO_TO_WEBVIEW: 'CTA:GO_TO_WEBVIEW',
    GO_TO_USAGE_TRACKER: 'CTA:GO_TO_USAGE_TRACKER',
    GO_TO_SUBSCRIPTION: 'CTA:GO_TO_SUBSCRIPTION',
    GO_TO_VUSE_WORLD: 'CTA:GO_TO_VUSE_WORLD',
    GO_TO_MY_ACCOUNT: 'CTA:GO_TO_MY_ACCOUNT',
    GO_TO_MANAGE_NOTIFICATIONS: 'CTA:GO_TO_MANAGE_NOTIFICATIONS',
    GO_TO_MY_PREFERENCES: 'CTA:GO_TO_MY_PREFERENCES',
    GO_TO_FAQ_MYVUSE: 'CTA:GO_TO_FAQ_MYVUSE',
    GO_TO_DEVICE_HELP: 'CTA:GO_TO_DEVICE_HELP',
    GO_TO_CONTACT_US: 'CTA:GO_TO_CONTACT_US',
    GO_TO_HOME: 'CTA:GO_TO_HOME',
    GO_TO_BUY_NOW: 'CTA:GO_TO_BUY_NOW',
    GO_TO_STORE_LOCATOR: 'CTA:GO_TO_STORE_LOCATOR',
};

export const notificationsConstants = {
    CREATE_RULE: 'notifications/CREATE_RULE',
}

export const tipsEvents = {
    GO_TO_FAVOURITES: 'goToFavourites',
    GO_TO_FLAVOUR_FOR_YOU: 'goToFlavoursForYou',
    GO_TO_BUY_NOW: 'goToBuyNow',
    GO_TO_VUSE_WORLD: 'goToWuseWorld',
    GO_TO_SUBSCRIPTION: 'goToSubscription',
    GO_TO_WEBVIEW: 'goToWebview',
    GO_TO_HOME: 'goToDevices',
    GO_TO_MY_ACCOUNT: 'goToMyAccount',
}

export const DevicePropertyPlaceholder = { 
    DEVICE_NAME: '[DEVICE_NAME]', 
    DEVICE_CONNECTION_STATUS: '[DEVICE_CONNECTION_STATUS]', 
    DEVICE_BATTERY_LEVEL: '[DEVICE_BATTERY_LEVEL]',
    DEVICE_BATTERYSAVER_LEVEL: '[DEVICE_BATTERYSAVER_LEVEL]', 
    DEVICE_CLOUD_LEVEL: '[DEVICE_CLOUD_LEVEL]', 
    DEVICE_LOCK_STATUS: '[DEVICE_LOCK_STATUS]', 
    DEVICE_LED_LEVEL: '[DEVICE_LED_LEVEL]'
}

