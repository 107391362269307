import React, { forwardRef, useState, useEffect } from "react";
import { DeviceIconGroup, CustomSlidingPanel } from "../../_components";
import { AnalyticsEvents, AnalyticsTargetEventsNames, aemPages, imagesConstants, routingConstants } from "../../_constants";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { Tenants } from "../../_helpers/tenants";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";

const DeviceSelector = forwardRef(
  ({ devices, selectedDeviceIndex, top, onAdd, onSelect }, ref) => {
    const [dictionary, setDictionary] = useState({});
    const [maxLimitPopupIsOpen, setMaxLimitPopupIsOpen] = useState(false);

    const addDevice = () => {
      if (devices.length >= Tenants.getMaxDevicesLimit()) {
        setMaxLimitPopupIsOpen(true);
      } else {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ADD_DEVICE});
        onAdd();
      }
    };

    const handleManageDevices = () => {
      setMaxLimitPopupIsOpen(false);
      history.push({
        pathname: buildURI(routingConstants.MANAGE_DEVICES),
        state: {
          isMoreMenuOpen: false,
        },
      });
    };

    useEffect(() => {
      const aem = new AEMHelper();

      setDictionary(
        aem.getDictionary(aemPages.MAX_DEVICES_PAIRED, {
          MAX_DEVICES_PAIRED_HEADER: propertyHeadingDefaultEmpty,
          MAX_DEVICES_PAIRED_CONTENT: propertyTextDefaultEmpty,
          MAX_DEVICES_PAIRED_BODY: propertyTextDefaultEmpty,
          MAX_DEVICES_BTN_MANAGE_DEVICES: propertyCtaItemsDefaultEmpty,
          MAX_DEVICES_BTN_GO_BACK: propertyCtaItemsDefaultEmpty,
        })
      );
    }, []);

    return (
      <div ref={ref} className="device-selector" style={{ top: top }}>
        <div className="d-flex justify-content-center">
          <button className="btn" type="button" onClick={() => addDevice()}>
            <img src={imagesConstants.HOMESCREEN_ADD_DEVICE} />
          </button>
          {devices?.length > 1 && (
            <DeviceIconGroup
              devices={devices}
              selectedDeviceIndex={selectedDeviceIndex}
              compact={false}
              showDeviceCount={false}
              onSelect={onSelect}
            />
          )}
        </div>
        <CustomSlidingPanel
          className="onboarding-panel mid-height-panel"
          overlayClassName="sliding-common-wrapper device-switcher-max-limit-popup"
          title={dictionary.MAX_DEVICES_PAIRED_HEADER}
          from="bottom"
          isOpen={maxLimitPopupIsOpen}
        >
          <div className="d-grid">
            <p className="mt-4 mb-4">{dictionary.MAX_DEVICES_PAIRED_CONTENT}</p>
            <p className="mb-4">{dictionary.MAX_DEVICES_PAIRED_BODY}</p>
            <button className="btn btn-primary" onClick={handleManageDevices}>
              {dictionary.MAX_DEVICES_BTN_MANAGE_DEVICES_0_ctaLabel}
            </button>
            <button
              className="btn btn btn-outline-secondary mt-3"
              onClick={() => setMaxLimitPopupIsOpen(false)}
            >
              {dictionary.MAX_DEVICES_BTN_GO_BACK_0_ctaLabel}
            </button>
          </div>
        </CustomSlidingPanel>
      </div>
    );
  }
);

export { DeviceSelector };
