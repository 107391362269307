import { REQUEST, SUCCESS, FAILURE } from "../_reducers/action-type.util";
import { dashboardConstants } from "../_constants";
import { dashboardServices } from "../_services";

export const dashboardActions = {
  setDoneTutorialFindMyVape,
  setDoneTutorialBattery,
  setDoneTutorialControls,
  setDoneTutorialUnlock,
  setDoneTutorialUsageTracker,
  setDoneTutorialPodTracker,
  setDoneTutorialLockSwitch,
  getNotificationHistory,
  setNotificationRead,
  setStoriesSeen,
  setUsageTrackerSelectedDevice,
  setUsageTrackerRawDataPublishTs,
  setUsageTrackerCalcDataPublishTs,
  deleteRemoveNotification
};

function setStoriesSeen(key) {
  return (dispatch, getState) => {
    dispatch({
      type: dashboardConstants.SET_VUSE_WORLD_STORIES_SEEN,
      data: key,
    });
  };
}

function setDoneTutorialFindMyVape() {
  return {
    type: dashboardConstants.SET_TUTORIAL_FMV,
    data: true,
  };
}

function setDoneTutorialBattery() {
  return {
    type: dashboardConstants.SET_TUTORIAL_BATTERY,
    data: true,
  };
}

function setDoneTutorialControls() {
  return {
    type: dashboardConstants.SET_TUTORIAL_CONTROLS,
    data: true,
  };
}

function setDoneTutorialUnlock() {
  return {
    type: dashboardConstants.SET_TUTORIAL_UNLOCK,
    data: true,
  };
}

function setDoneTutorialUsageTracker() {
  return {
    type: dashboardConstants.SET_TUTORIAL_USAGE_TRACKER,
    data: true,
  };
}

function setDoneTutorialPodTracker() {
  return {
    type: dashboardConstants.SET_TUTORIAL_POD_TRACKER,
    data: true,
  };
}

function setDoneTutorialLockSwitch() {
  return {
    type: dashboardConstants.SET_TUTORIAL_LOCK_SWITCH,
    data: true,
  };
}

function setUsageTrackerSelectedDevice(selectedDevice) {
  return {
    type: dashboardConstants.SET_USAGE_TRACKER_SELECTED_DEVICE,
    data: selectedDevice,
  };
}

function setUsageTrackerRawDataPublishTs(timestamp) {
  return {
    type: dashboardConstants.SET_USAGE_TRACKER_RAW_DATA_PUBLISH_TS,
    data: timestamp,
  };
}

function setUsageTrackerCalcDataPublishTs(timestamp) {
  return {
    type: dashboardConstants.SET_USAGE_TRACKER_CALC_DATA_PUBLISH_TS,
    data: timestamp,
  };
}

function getNotificationHistory(
  tenantUserId,
  limit,
  nextPage,
  successCb,
  failureCb
) {
  return (dispatch, getState) => {
    dispatch(request());

    dashboardServices
      .getNotificationHistory(tenantUserId, limit, nextPage)
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(dashboardConstants.GET_NOTIFICATION_HISTORY) };
  }
  function success(payload) {
    return {
      type: SUCCESS(dashboardConstants.GET_NOTIFICATION_HISTORY),
      payload,
    };
  }
  function failure(error) {
    return {
      type: FAILURE(dashboardConstants.GET_NOTIFICATION_HISTORY),
      error,
    };
  }
}

function setNotificationRead(tenantUserId, ids, successCb, failureCb) {
  return (dispatch, getState) => {
    dispatch(request());

    dashboardServices
      .setNotificationRead(tenantUserId, ids)
      .then((payload) => {
        dispatch(success(ids));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(dashboardConstants.SET_NOTIFICATION_READ) };
  }
  function success(payload) {
    return { type: SUCCESS(dashboardConstants.SET_NOTIFICATION_READ), payload };
  }
  function failure(error) {
    return { type: FAILURE(dashboardConstants.SET_NOTIFICATION_READ), error };
  }
}

function deleteRemoveNotification(tenantUserId, id, successCb, failureCb) {
  return (dispatch) => {
    dispatch(request());

    dashboardServices
      .deleteNotification(tenantUserId, id)
      .then((payload) => {
        dispatch(success(payload.data));

        if (typeof successCb === "function") {
          successCb(payload.data);
        }
      })
      .catch((error) => {
        dispatch(failure(error));

        if (typeof failureCb === "function") {
          failureCb(error);
        }
      });
  };

  function request() {
    return { type: REQUEST(dashboardConstants.REMOVE_REMOTE_TIP) };
  }
  function success(payload) {
    return { type: SUCCESS(dashboardConstants.REMOVE_REMOTE_TIP), payload };
  }
  function failure(error) {
    return { type: FAILURE(dashboardConstants.REMOVE_REMOTE_TIP), error };
  }
}