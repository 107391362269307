import React, { useEffect, useState } from "react";
import { aemPages, imagesConstants } from '../../_constants';
import { FirmwareUpdate } from "../FirmwareUpdate/FirmwareUpdate";
import { Header } from "../Header";
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty } from "../../_helpers/aem/aemhelper";

export const FirmwareUpdateVRR = ({
    handleBackToFeature = () => {}
}) => {
    const [dictionary, setDictionary] = useState({});

    const [isFirmwareUpdateOpen, setIsFirmwareUpdateOpen] = useState(false);

    useEffect(() => {
        const aem = new AEMHelper();
        setDictionary(
            aem.getDictionary(aemPages.BATTERY_SETTINGS, {
                BATT_PREF_LOW_BATTERY_ALERT_HEADER: propertyHeadingDefaultEmpty,
                BATT_PREF_TOGGLE_ALERT_UPDATE: propertyTextDefaultEmpty,
                BATT_PREF_BTN_UPDATE: propertyCtaItemsDefaultEmpty
            })
        );
    }, [])

    return(
        <>
            <div className="fw-update-vrr">
                <Header
                    rightButton={{
                        icon: <span className="bat-icon-close" />,
                        onClick: () => handleBackToFeature()
                    }}
                    high={true}
                >
                    {dictionary.BATT_PREF_LOW_BATTERY_ALERT_HEADER}
                </Header>

                <div className="fw-update-vrr-body">
                    <div >
                        <img className="fw-update-vrr-icon" src={imagesConstants.RECHARGE_REMINDERS_FW_UPDATE_VRR} />
                    </div>

                    <div className="fw-update-vrr-title">
                        {dictionary.BATT_PREF_LOW_BATTERY_ALERT_HEADER}
                    </div>

                    <div className="fw-update-vrr-descr">
                        {dictionary.BATT_PREF_TOGGLE_ALERT_UPDATE}
                    </div>
                </div>

                <button
                    className="fw-update-vrr-cta text-uppercase"
                    onClick={() => setIsFirmwareUpdateOpen(true)}
                >
                    <span className="fw-update-vrr-cta-text">{dictionary.BATT_PREF_BTN_UPDATE_0_ctaLabel}</span>
                    <span className="bat-icon-download"></span>
                </button>
            </div>

            {isFirmwareUpdateOpen &&
                <FirmwareUpdate
                    show={isFirmwareUpdateOpen}
                    onClose={(isComplete) => {
                        setIsFirmwareUpdateOpen(false);
                        handleBackToFeature(isComplete);
                    }}
                    fromCTA={true}
                />
            }
        </>
    )
};