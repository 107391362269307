import React, { useContext, useEffect, useState } from 'react';
import { aemPages, imagesConstants, routingConstants } from '../../_constants';
import { AEMHelper, propertyHeadingDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { Header } from '../../_components';
import { buildURI } from '../../_helpers/navigation';
import { history } from '../../_helpers/history';
import { DashboardContext } from '../Dashboard';

export function PodTrackerOptinInstructions({
  header = true
}) {
  const [dictionary, setDictionary] = useState({});
  const { navbarHeight } = useContext(DashboardContext) || {};

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.POD_TRACKER_OPTIN, {
        PD_OPTIN_HEADER: propertyHeadingDefaultEmpty,
        PD_OPTIN_PREFERENCE_SETTINGS: propertyTextDefaultEmptyParsed,
        PD_OPTIN_TAP_MENU: propertyTextDefaultEmptyParsed,
        PD_OPTIN_TAP_PREFERENCES: propertyTextDefaultEmptyParsed,
        PD_OPTIN_TURN_ON_OFF: propertyTextDefaultEmptyParsed
      })
    );
  }, []);

  return (
    <div className="page w-100" style={{ paddingBottom: `${navbarHeight}px` }}>
      {header &&
        <Header
            leftButton={{
              icon: <span className="bat-icon-back" />,
              onClick: () =>  history.push({
                  pathname: buildURI(routingConstants.DASHBOARD),
                  hash: 'pod-tracker-optin'
                })
            }}
        >
            {dictionary.PD_OPTIN_HEADER}
        </Header>
        }
        <div className="page-wrapper optin-instructions-wrapper">
            <div className="optin-instructions-descr">
                {dictionary.PD_OPTIN_PREFERENCE_SETTINGS}
            </div>

            <div className="optin-instructions-content">
                <ol>
                    <li>
                      <img className="px-2" src={imagesConstants.POD_TRACKER_OPTIN_MENU} />
                      {dictionary.PD_OPTIN_TAP_MENU}
                    </li>
                    <li>
                      <img className="px-2" src={imagesConstants.POD_TRACKER_OPTIN_PREFS} />
                      {dictionary.PD_OPTIN_TAP_PREFERENCES}
                    </li>
                    <li>
                      <img className="px-2" src={imagesConstants.POD_TRACKER_OPTIN_TOGGLE} />
                      {dictionary.PD_OPTIN_TURN_ON_OFF}
                    </li>
                </ol>
            </div>
        </div>

        <div className="page-linear-gradient-bg"></div>
    </div>
  )
}