import { isFunction } from "lodash";
import React, { useEffect } from "react";
import { AnalyticsEvents } from "../../_constants";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { CustomInlineAlert } from "../CustomInlineAlert";
import { CustomSlidingPanel } from "../CustomSlidingPanel";

export function TrackerDisablePanel({
  labels,
  isOpen,
  onConfirm,
  onCancel,
  analyticEvent,
  analyticEventName
}) {
  const disableFeature = async () => {
    if (isFunction(onConfirm)) {
      onConfirm();
    }
  };

  const handleConfirm = () => {
    if (analyticEvent) {
        logAnalyticsEvent(analyticEvent);
    }
    disableFeature();
  };

  const handleCancel = () => {
    if (isFunction(onCancel)) {
      onCancel();
    }
  };

  useEffect(() => {
    if (isOpen && analyticEventName) {
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
        screen_name: analyticEventName,
      });
    }
  }, [isOpen]);

  return (
    <CustomSlidingPanel
      from="bottom"
      isOpen={isOpen}
      overlayClassName="sliding-common-wrapper priority tracker-disable-panel"
      className="onboarding-panel mid-height-panel"
      title={labels.header}
    >
      <div className="process-data-container">
        <div className="process-data-title">
          <span>
            {labels.subtitle}
          </span>
        </div>
        {labels.alert_title && labels.alert_body &&
        <CustomInlineAlert isShown={true} alertType="error">
          <div>
            <div className="mb-2">
              <strong>
                {labels.alert_title}
              </strong>
            </div>
            <div>
              {labels.alert_body}
            </div>
          </div>
        </CustomInlineAlert>
        }
        <div className="dark">
          {labels.note}
        </div>
        <div className="process-data-cta d-grid gap-2">
          <button
            className="btn btn-primary text-uppercase"
            onClick={handleConfirm}
          >
            {labels.btn_confirm}
          </button>
          <button
            className="btn btn btn-outline-secondary text-uppercase"
            onClick={handleCancel}
          >
            {labels.btn_cancel}
          </button>
        </div>
      </div>
    </CustomSlidingPanel>
  );
}